import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  appIds: [],
  charts: {}
});


function chartDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_APPS: 
      return state.merge({ loading: true });
    case ActionNames.RECEIVE_APPS:
      return state.merge({ loading: false, appIds: action.ids, charts: action.data });
    case ActionNames.FAILURE_APPS: 
      return state.merge({ loading: false, appIds:[] });

    case ActionNames.REQUEST_CHART: {
      const appData =  Object.assign({}, state.charts[action.appId], { loading: true, data: [] });
      const app = Object.assign({}, {[action.appId] : appData})
      return state.merge({ charts: Object.assign({}, state.charts, app)} );
    }
    case ActionNames.RECEIVE_CHART: {
      const appData =  Object.assign({}, state.charts[action.appId], { loading: false, data: action.data.items }, action.data);
      const app = Object.assign({}, {[action.appId] : appData})
      return state.merge({ charts: Object.assign({}, state.charts, app)} );
    }
    case ActionNames.FAILURE_CHART:{
      const appData =  Object.assign({}, state.charts[action.appId], { loading: false, data: [], error: action.error });
      const app = Object.assign({}, {[action.appId] : appData})
      return state.merge({ charts: Object.assign({}, state.charts, app)} );
    }
    default: return state;
  }
}

export default chartDataReducer;

