import { combineReducers } from 'redux';
import keyPagesData from './keyPagesData';
import keyPagesMonthData from './keyPagesMonthData';
import rawDataForUrl from './rawDataForUrl';
import SummaryDataForBrandAndLocale from './SummaryDataForBrandAndLocale'
import summaryData from './summaryData'
import onDemand from './onDemand'
import onDemandReport from './OnDemandReport'
import OnDemandRecentReport from './OnDemandRecent'

export default combineReducers({
  keyPagesData,
  summaryData,
  keyPagesMonthData,
  rawDataForUrl,
  SummaryDataForBrandAndLocale,
  onDemand,
  onDemandReport,
  OnDemandRecentReport
});
