import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: [],
  error: null
});

function selfServiceSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_SYNTHETIC_SELF_SERVICE_SUMMARY:
      return state.merge({ data: [], loading: true });
    case ActionNames.RECEIVE_SYNTHETIC_SELF_SERVICE_SUMMARY:
      return state.merge({ data: action.data, loading: false });
    case ActionNames.FAILURE_SYNTHETIC_SELF_SERVICE_SUMMARY:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default selfServiceSummaryReducer;
