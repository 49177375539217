const RED = '#FF0000';
const AMBER = '#f2711c';
const GREEN = '#21ba45';
const NOT_AVAILABLE_COLOUR = '#D3D3D3';

function getAverageColour(average) {
  let averageColour = "black";
  if (isRed(average)) {
    averageColour = RED;
  }
  if (isAmber(average)) {
    averageColour = AMBER;
  }
  if (isGreen(average)) {
    averageColour = GREEN;
  }
  return averageColour;
}

function getMainMetricsColor(average){
  let averageColour = NOT_AVAILABLE_COLOUR;
  if (isRed(average)) {
    averageColour = RED;
  }
  if (isAmber(average)) {
    averageColour = AMBER;
  }
  if (isGreen(average)) {
    averageColour = GREEN;
  }
  return averageColour;
}

function getLcpColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 2.5){
    color = GREEN
  }else if(value > 2.5 && value <=4){
    color  = AMBER
  }else if(value > 4){
    color = RED
  }
  return color
}

function getClsColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 0.1){
    color = GREEN
  }else if(value > 0.1 && value <=0.25){
    color  = AMBER
  }else if(value > 0.25){
    color = RED
  }
  return color
}

function getTbtColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 0.3){
    color = GREEN
  }else if(value > 0.3 && value <=0.6){
    color  = AMBER
  }else if(value > 0.6){
    color = RED
  }
  return color
}
function getTtiColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 3.8){
    color = GREEN
  }else if(value > 3.8 && value <=7.3){
    color  = AMBER
  }else if(value > 7.3){
    color = RED
  }
  return color
}
function getFcpColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 2){
    color = GREEN
  }else if(value > 2 && value <=4){
    color  = AMBER
  }else if(value > 4){
    color = RED
  }
  return color
}
function getSiColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 4.3){
    color = GREEN
  }else if(value > 4.3 && value <=5.8){
    color  = AMBER
  }else if(value > 5.8){
    color = RED
  }
  return color
}
function getFmpColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value < 5){
    color = GREEN
  }else if(value >= 5 && value < 10){
    color  = AMBER
  }else if(value >= 10){
    color = RED
  }
  return color
}

function getPageWeightColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 1.5){
    color = GREEN
  }else if(value > 1.5 && value <=2.5){
    color  = AMBER
  }else if(value > 2.5){
    color = RED
  }
  return color
}

function getJSETColor(value){
  let color = NOT_AVAILABLE_COLOUR
  if(value > 0 && value <= 2.0){
    color = GREEN
  }else if(value > 2.0 && value <=3.5){
    color  = AMBER
  }else if(value > 3.5){
    color = RED
  }
  return color
}

const isGreen = average => {
  return average <= 100 && average >= 90;
};

const isAmber = average => {
  return average >= 50 && average < 90;
};

const isRed = average => {
  return average < 50 && average > 0 ;
};

export { isGreen, isRed, isAmber, getAverageColour, getMainMetricsColor, getLcpColor, getClsColor ,getTbtColor ,getTtiColor , getFcpColor ,getSiColor ,getFmpColor, getJSETColor, getPageWeightColor, RED, AMBER, GREEN };
