import React, { PureComponent } from "react";
import {Message} from 'semantic-ui-react';
import PropTypes from 'prop-types'
import TableTemplate from './TableTemplate'
import RecentReportTabletemplate from './RecentReportTableTemplate'
import EmailReportTableTemplate from './EmailReportTableTemplate'
import './ondemand.css'

class OnDemandReportTable extends PureComponent {

  render(){
    return (
      <div className="formDiv">
        {this.props.action === 'execute'?(Object.keys(this.props.executionResponse).length !== 0?<div className="topMargin">
          <Message
            header="Execution Started"
            content={this.props.executionResponse.status+"\n\r |  ReportId - "+this.props.executionResponse.userId}
          />
        </div>: null):this.props.action === 'fetch'?(this.props.onDemandReport.length > 0?<div className="fullWidth">
          <TableTemplate data={this.props.onDemandReport}/>
        </div>: null):
          this.props.action === 'fetchEmail'?(this.props.emailReport.length > 0?<EmailReportTableTemplate  data={this.props.emailReport} fetchData = {this.props.fetchData}  changeAction={this.props.changeAction}/>: null):
            <RecentReportTabletemplate data={this.props.recentReportData} fetchData = {this.props.fetchData} changeAction={this.props.changeAction}/>}
      </div>
    )
  }
}

OnDemandReportTable.propTypes = {
  executionResponse: PropTypes.object,
  onDemandReport: PropTypes.array,
  action: PropTypes.string,
  recentReportData: PropTypes.array,
  fetchData: PropTypes.func,
  emailReport: PropTypes.array,
  changeAction: PropTypes.func
}
export default OnDemandReportTable;