import ActionNames from './names';

function ActionCreator(actionKey, actionData = {}) {
  return Object.assign({}, actionData, { type: actionKey });
}

export default {
  requestDailyAnomalyReport() {
    return ActionCreator(ActionNames.REQUEST_DAILY_ANOMALY_REPORT);
  },
  receiveDailyAnomalyReport(data) {
    return ActionCreator(ActionNames.RECEIVE_DAILY_ANOMALY_REPORT, {
      data
    });
  },
  failureDailyAnomalyReport(error) {
    return ActionCreator(ActionNames.FAILURE_DAILY_ANOMALY_REPORT, {
      error
    });
  }
};
