import { combineReducers } from 'redux';
import rumData from './rumData';
import rumLeaderboard from './rumLeaderboard';
import rumKeyPagesData from './rumKeyPagesData';
import brandMetaData from './brandMetaData';
import metrics from './metrics';

export default combineReducers({
  rumData,
  rumLeaderboard,
  rumKeyPagesData,
  brandMetaData,
  metrics
});
