const items = [
  {
    id: 0,
    name: 'rum charts',
    value: 'rumCharts',
    link: 'rum'
  },
  {
    id: 1,
    name: 'rum all pages',
    value: 'rumAll',
    link: 'rum-table'
  },
  // {
  //   id: 2,
  //   name: 'rum key pages',
  //   value: 'rumKey',
  //   link: 'rum-table-key-pages'
  // }
];

export default items;
