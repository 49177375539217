import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: [],
  error: null,
});

function countriesReducer(state=initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_RUM_COUNTRIES: 
      return state.merge({data: [], loading: true, charts: {}});
    case ActionNames.RECEIVE_RUM_COUNTRIES: 
      return state.merge({data: action.data, loading: false, charts: action.charts});
    case ActionNames.FAILURE_RUM_COUNTRIES: 
      return state.merge({error: action.error, loading: false, charts: {}})

    case ActionNames.REQUEST_CHARTS_COUNTRIES: {
      const charts = Object.assign({}, state.charts, { [action.name]: { loading: true, data: [] }})
      return state.merge({charts});
    }
    case ActionNames.RECEIVE_CHARTS_COUNTRIES: {
      const chart = Object.assign({}, {loading: false}, action.data);
      const charts = Object.assign({}, state.charts, { [action.name]: chart });
      return state.merge({charts});
    }
    case ActionNames.FAILURE_CHARTS_COUNTRIES: {
      const charts = Object.assign({}, state.charts, { [action.name]: { loading: false, data: [] }})
      return state.merge({charts});
    }    
    default: 
      return state;
  }
}

export default countriesReducer;