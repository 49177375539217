
const mode = [{
  text: 'Mobile',
  key: '3g',
  value: '3g'
},{
  text: 'Desktop',
  key: 'Cable', 
  value: 'cable'
}]

const region = [
  // {
  //   text: 'Ireland',
  //   key: 'eu-west-1', 
  //   value: 'eu-west-1'
  // },
  // {
  //   text: 'Africa(Cape Town)',
  //   key: 'af-south-1', 
  //   value: 'af-south-1'
  // },
  // {
  //   text: 'Hong Kong',
  //   key: 'ap-east-1', 
  //   value: 'ap-east-1'
  // },
      
  // {
  //   text: 'Mumbai',
  //   key: 'ap-south-1', 
  //   value: 'ap-south-1'
  // },
  // {
  //   text: 'Singapore',
  //   key: 'ap-southeast-1', 
  //   value: 'ap-southeast-1'
  // },
  // {
  //   text: 'Sydney',
  //   key: 'ap-southeast-2', 
  //   value: 'ap-southeast-2'
  // },
  // {
  //   text: 'Tokyo',
  //   key: 'ap-northeast-1', 
  //   value: 'ap-northeast-1'
  // },
  // {
  //   text: 'Canada',
  //   key: 'ca-central-1', 
  //   value: 'ca-central-1'
  // },
  // {
  //   text: 'Frankfurt',
  //   key: 'eu-central-1', 
  //   value: 'eu-central-1'
  // },
  // {
  //   text: 'Milan',
  //   key: 'eu-south-1', 
  //   value: 'eu-south-1'
  // },
  
  // {
  //   text: 'Sao Paulo',
  //   key: 'sa-east-1', 
  //   value: 'sa-east-1'
  // },
  // {
  //   text: 'Stockholm',
  //   key: 'eu-north-1', 
  //   value: 'eu-north-1'
  // },
  
  // {
  //   text: 'London',
  //   key: 'eu-west-2', 
  //   value: 'eu-west-2'
  // },
  // {
  //   text: 'Paris',
  //   key: 'eu-west-3', 
  //   value: 'eu-west-3'
  // },
  
  // {
  //   text: 'Bahrain',
  //   key: 'me-south-1', 
  //   value: 'me-south-1'
  // },
  {
    text: 'N. America',
    key: 'us-east1', 
    value: 'us-east1'
  },
  // {
  //   text: 'N. California',
  //   key: 'us-west-1', 
  //   value: 'us-west-1'
  // }, 
]

export { mode, region  };