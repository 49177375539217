import React from 'react';
import PropType from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

function TableColumnDropDown({
  placeholder,
  options,
  onChange,
  value = [],
  valueProperty,
  type
}) {
  return (
    <Dropdown
      placeholder={placeholder}
      fluid
      multiple
      search
      selection
      options={options}
      onChange={onChange}
      value={value}
      data-valueproperty={valueProperty}
      data-type={type}
    />
  );
}

TableColumnDropDown.propTypes = {
  placeholder: PropType.string.isRequired,
  options: PropType.arrayOf(PropType.object).isRequired,
  onChange: PropType.func.isRequired,
  value: PropType.array,
  valueProperty: PropType.string,
  type: PropType.string
};

export default TableColumnDropDown;
