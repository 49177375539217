const names = {
  REQUEST_RUM_DATA: 'REQUEST_RUM_DATA_WP_LITE',
  RECEIVE_RUM_DATA: 'RECEIVE_RUM_DATA_WP_LITE',
  FAILURE_RUM_DATA: 'FAILURE_RUM_DATA_WP_LITE',

  REQUEST_RUM_LEADERBOARD: 'REQUEST_RUM_LEADERBOARD_WP_LITE',
  RECEIVE_RUM_LEADERBOARD: 'RECEIVE_RUM_LEADERBOARD_WP_LITE',
  FAILURE_RUM_LEADERBOARD: 'FAILURE_RUM_LEADERBOARD_WP_LITE',

  REQUEST_RUM_KEY_PAGES_DATA: 'REQUEST_RUM_KEY_PAGES_DATA_WP_LITE',
  RECEIVE_RUM_KEY_PAGES_DATA: 'RECEIVE_RUM_KEY_PAGES_DATA_WP_LITE',
  FAILURE_RUM_KEY_PAGES_DATA: 'FAILURE_RUM_KEY_PAGES_DATA_WP_LITE',

  REQUEST_BRAND_META_DATA: 'REQUEST_BRAND_META_DATA_WP_LITE',
  RECEIVE_BRAND_META_DATA: 'RECEIVE_BRAND_META_DATA_WP_LITE',
  FAILURE_BRAND_META_DATA: 'FAILURE_BRAND_META_DATA_WP_LITE',

  REQUEST_METRICS: 'REQUEST_METRICS_WP_LITE',
  RECEIVE_METRICS: 'RECEIVE_METRICS_WP_LITE',
  FAILURE_METRICS: 'FAILURE_METRICS_WP_LITE'
};

export default names;
