import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';
import defaultState from './defaultState';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore () {
  // if (process.env.REACT_APP_STAGE === 'prod') {
  return createStore(
    reducers,
    defaultState,
    applyMiddleware(thunkMiddleware)
  );
  // }
  // return createStore(
  //   reducers,
  //   defaultState,
  //   composeEnhancers(applyMiddleware(thunkMiddleware))
  // );
}
