import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './Modal.css';

class JsonModal extends React.PureComponent {
  state = {
    sortConfig: {
      key: null,
      direction: 'ascending',
    },
  };

  resetSortConfig = () => {
    this.setState({
      sortConfig: {
        key: null,
        direction: 'ascending',
      },
    });
  };

  handleSort = (key) => {
    this.setState((prevState) => {
      let direction = 'ascending';
      if (
        prevState.sortConfig.key === key &&
        prevState.sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      return {
        sortConfig: { key, direction },
      };
    });
  };

  renderSortIcon = (key) => {
    const { sortConfig } = this.state;
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '⇅';
  };
  
  renderTableRows = () => {
    const { jsonData } = this.props;
    const { sortConfig } = this.state;
    if (!jsonData || !jsonData.Pages) return null;

    let sortedPages = [...jsonData.Pages];
    if (sortConfig.key) {
      sortedPages.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortedPages.map((page, index) => (
      <tr key={index}>
        <td>{page.PageUrl}</td>
        <td>{page.DeviceType}</td>
        <td>{page.Percentile80.toFixed(2)}</td>
      </tr>
    ));
  };

  render() {
    const { isOpen, onClose, jsonData } = this.props;
    const todayDate = moment().format('DD-MM-YYYY');
    if (!isOpen) return null;

    if (jsonData && jsonData.status !== undefined) {
      return (
        <div className="modal" onClick={onClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={onClose}>&times;</span>
            <p>Last Modified Date: {todayDate}</p>
            <p>Status: Data is not available</p>
          </div>
        </div>
      );
    }

    return (
      <div className="modal" onClick={() => {
        this.resetSortConfig();
        onClose();
      }}
      >
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <span className="close"
            onClick={() => {
              this.resetSortConfig();
              onClose();
            }}
          >&times;</span>
          
          <h3>General Information</h3>
          <table className="json-table">
            <tbody>
              <tr><td>App ID</td><td>{jsonData.AppId}</td></tr>
              <tr><td>Begin Time</td><td>{jsonData.BeginTime}</td></tr>
              <tr><td>End Time</td><td>{jsonData.EndTime}</td></tr>
              <tr><td>Domain</td><td>{jsonData.Domain}</td></tr>
              <tr><td>Brand</td><td>{jsonData.Brand}</td></tr>
              <tr><td>Locale</td><td>{jsonData.Locale}</td></tr>
              <tr><td>Category</td><td>{jsonData.Category}</td></tr>
              <tr><td>Cluster</td><td>{jsonData.Cluster}</td></tr>
              <tr><td>Parent</td><td>{jsonData.Parent}</td></tr>
              <tr><td>Priority</td><td>{jsonData.Priority}</td></tr>
              <tr><td>Last Modified Date</td><td>{jsonData.Date}</td></tr>
            </tbody>
          </table>

          <h3>Pages Information</h3>
          <table className="json-table">
            <thead>
              <tr>
                <th>Page URL</th>
                <th onClick={() => this.handleSort('DeviceType')} style={{ cursor: 'pointer' }} >Device Type {this.renderSortIcon('DeviceType')} </th>
                <th onClick={() => this.handleSort('Percentile80')} style={{ cursor: 'pointer' }} >Percentile 80 {this.renderSortIcon('Percentile80')} </th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableRows()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

JsonModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  jsonData: PropTypes.object
};


export default JsonModal;
