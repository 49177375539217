import ActionNames from '../../actions/adobeTemplated/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: [],
  summaryData: {},
  error: null,
});

function rumKeyPagesDataReducer(state = initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_RUM_KEY_PAGES_DATA:
      return state.merge({loading: true, data: [], summaryData: {}});
    case ActionNames.RECEIVE_RUM_KEY_PAGES_DATA:
      return  state.merge({loading: false, data: action.data, summaryData: action.summaryData});
    case ActionNames.FAILURE_RUM_KEY_PAGES_DATA:
      return  state.merge({loading: false, error: action.error, data: [], summaryData: {}});
    default: 
      return state;
  }
} 

export default rumKeyPagesDataReducer;