import { combineReducers } from 'redux';
import keyPagesData from './keyPagesData';
import keyPagesMonthData from './keyPagesMonthData';
import rawDataForUrl from './rawDataForUrl';

export default combineReducers({
  keyPagesData,
  keyPagesMonthData,
  rawDataForUrl
});
