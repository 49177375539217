import React from 'react';
import PropType from 'prop-types';

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let anomalyData = [];
    if (this.props.anomalyData) {
      anomalyData = this.props.anomalyData;
    }
    return (
      <div className="container">
        <ul>
          {anomalyData.map((item, i) => (
            <li key={i} className="container-list">
              <span className="container-list-highlight"> {item.Brand}</span>{' '}
              {item.Locale} has considerably drop down in{' '}
              <span className="container-list-highlight">
                {' '}
                {item.pk.includes('Percentile80') &&
                  'Average page load time'}{' '}
              </span>{' '}
              <span className="container-list-highlight">
                {' '}
                {!item.pk.includes('Percentile80') &&
                  item.pk.split('_')[1]}{' '}
              </span>{' '}
              to{' '}
              <span className="container-list-highlight">
                {' '}
                {item.AnomalyValue}
              </span>{' '}
              {/* Reason {item.Reason} */}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

List.propTypes = {
  anomalyData: PropType.array
};
List.defaultProps = {
  loading: false
};
export default List;
