import ActionCreators from './creators';

const API_KEY = process.env.REACT_APP_ADOBE_SAAS_API_KEY;
const API_ENDPOINT = process.env.REACT_APP_ADOBE_SAAS_API;

const fetchOptions = {
  headers: { 'X-Api-Key': API_KEY },
  method: 'GET',
  mode: 'cors'
};

const actions = {
  fetchRumData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumData());
        const URL = API_ENDPOINT.concat(`/rumDataAll`);
        const fetchData = await fetch(URL, fetchOptions);
        const value = await fetchData.json();
        const { data, summaryData } = value;
        dispatch(ActionCreators.receiveRumData(data, summaryData));
      } catch (error) {
        dispatch(ActionCreators.failureRumData(error));
      }
    };
  },
  fetchRumLeaderboard() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumLeaderboard());
        const URL = API_ENDPOINT.concat(`/rumLeaderboard`);
        const fetchData = await fetch(URL, fetchOptions);
        const value = await fetchData.json();
        const data = value;
        dispatch(ActionCreators.receiveRumLeaderboard(data));
      } catch (error) {
        dispatch(ActionCreators.failureRumLeaderboard(error));
      }
    };
  },
  fetchRumKeyPagesData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestRumKeyPagesData());
        const URL = API_ENDPOINT.concat(`/rumDataKeyPages`);
        const fetchData = await fetch(URL, fetchOptions);
        const value = await fetchData.json();
        const { data, summaryData } = value;
        dispatch(ActionCreators.receiveRumKeyPagesData(data, summaryData));
      } catch (error) {
        dispatch(ActionCreators.failureRumKeyPagesData(error));
      }
    };
  },
  fetchBrandMetaData() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestBrandMetaData());
        const URL = API_ENDPOINT.concat('/brandMetaData');
        const fetchData = await fetch(URL, fetchOptions);
        const value = await fetchData.json();
        dispatch(ActionCreators.receiveBrandMetaData(value));
      } catch (error) {
        dispatch(ActionCreators.failureBrandMetaData(error));
      }
    };
  },
  fetchMetrics() {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestMetrics());
        const URL = API_ENDPOINT.concat('/metrics');
        const fetchData = await fetch(URL, fetchOptions);
        const value = await fetchData.json();
        dispatch(ActionCreators.receiveMetrics(value));
      } catch (error) {
        dispatch(ActionCreators.failureMetrics(error));
      }
    };
  }
};

export default actions;
