import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class Navigation extends Component {
  handleItemClick = target => {
    this.props.history.push(target);
  };
  render() {
    const { activeItem, items } = this.props;
    return (
      <Menu pointing secondary>
        <Menu.Menu position="right">
          {
            items.map(i=> 
              <Menu.Item
                key={i.id}
                name={i.name}
                active={i.value === activeItem}
                onClick={e => this.handleItemClick(i.link, e)}
              />
            )
          }
        </Menu.Menu>
      </Menu>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(Navigation);
