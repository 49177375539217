import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  onDemandExecuteData: {}
});

function onDemandReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_EXECUTE_ONDEMAND_LIGHTHOUSE:
      return state.merge({ onDemandExecuteData: {}, loading: true });
    case ActionNames.RECEIVE_EXECUTE_ONDEMAND_LIGHTHOUSE:
      return state.merge({ onDemandExecuteData: action.data, loading: false });
    case ActionNames.FAILURE_EXECUTE_ONDEMAND_LIGHTHOUSE:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default onDemandReducer;
