const names = {
  REQUEST_RUM_DATA: 'REQUEST_RUM_DATA_ADOBE_SAAS',
  RECEIVE_RUM_DATA: 'RECEIVE_RUM_DATA_ADOBE_SAAS',
  FAILURE_RUM_DATA: 'FAILURE_RUM_DATA_ADOBE_SAAS',

  REQUEST_RUM_LEADERBOARD: 'REQUEST_RUM_LEADERBOARD_ADOBE_SAAS',
  RECEIVE_RUM_LEADERBOARD: 'RECEIVE_RUM_LEADERBOARD_ADOBE_SAAS',
  FAILURE_RUM_LEADERBOARD: 'FAILURE_RUM_LEADERBOARD_ADOBE_SAAS',

  REQUEST_RUM_KEY_PAGES_DATA: 'REQUEST_RUM_KEY_PAGES_DATA_ADOBE_SAAS',
  RECEIVE_RUM_KEY_PAGES_DATA: 'RECEIVE_RUM_KEY_PAGES_DATA_ADOBE_SAAS',
  FAILURE_RUM_KEY_PAGES_DATA: 'FAILURE_RUM_KEY_PAGES_DATA_ADOBE_SAAS',

  REQUEST_BRAND_META_DATA: 'REQUEST_BRAND_META_DATA_ADOBE_SAAS',
  RECEIVE_BRAND_META_DATA: 'RECEIVE_BRAND_META_DATA_ADOBE_SAAS',
  FAILURE_BRAND_META_DATA: 'FAILURE_BRAND_META_DATA_ADOBE_SAAS',

  REQUEST_METRICS: 'REQUEST_METRICS_ADOBE_SAAS',
  RECEIVE_METRICS: 'RECEIVE_METRICS_ADOBE_SAAS',
  FAILURE_METRICS: 'FAILURE_METRICS_ADOBE_SAAS'
};

export default names;
