import { combineReducers } from 'redux';

import brands from './brands';
import categories from './categories';
import subCategories from './subCategories';

import clusters from './clusters';
import MCOs from './MCOs';
import countries from './countries';

import agencies from './agencies';
import dailyReport from './dailyReport';
import brandMetadata from './brandMetadata';
import appInstanceMetadata from './appInstanceMetadata';
import appMetadata from './appMetadata';
import rumLeaderboard from './rumLeaderboard';
import appMetadataKeyPages from './appMetadataKeyPages';
import appMetadataPerceivedLoad from './appMetadataPerceivedLoad';

import current from './current';
import all from './all';

export default combineReducers({
  brands,
  categories,
  subCategories,
  clusters,
  MCOs,
  countries,
  agencies,
  brandMetadata,
  appInstanceMetadata,
  appMetadata,
  rumLeaderboard,
  all,
  current,
  appMetadataKeyPages,
  appMetadataPerceivedLoad,
  dailyReport
});
