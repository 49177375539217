import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: {},
  classicData: {},
  saasData: {},
  athData: {},
  libertyData: {},
  templatedData: {},
  liteData: {},
  competitorData: {},
  error: null
});

function keyPagesDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
      return state.merge({ data: {}, loading: true });
    case ActionNames.RECEIVE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
      return state.merge({
        [`${action.platform}Data`]: action.data,
        loading: false
      });
    case ActionNames.FAILURE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default keyPagesDataReducer;
