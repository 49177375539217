import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  pageLoadData: [],
  pageLoadBrandName: '',
  pageLoadLoading: true
});


function brandRumDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_BRAND_RUM_DATA_PAGE_LOAD:
      return state.merge({
        pageLoadLoading: true
      });
    case ActionNames.RECEIVE_BRAND_RUM_DATA_PAGE_LOAD:
      return state.merge({
        pageLoadData: action.data,
        pageLoadBrandName: action.brandName,
        pageLoadLoading: false
      });
    case ActionNames.FAILURE_BRAND_RUM_DATA_PAGE_LOAD: 
      return state.merge({
        pageLoadData: [],
        pageLoadLoading: false,
        error: action.error,
      });
    case ActionNames.SET_BRAND: 
      return state.merge({
        pageLoadBrandName: action.data,
      });
    case ActionNames.CLEAR_BRAND: 
      return state.merge({
        pageLoadBrandName: '',
      })
    default:
      return state;
  }
}

export default brandRumDataReducer;

