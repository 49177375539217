import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Navigation from '../../components/Navigation';
import {
  Divider,
  Button,
  Dropdown,
  Input,
  Message,
  Icon
} from 'semantic-ui-react';
import {
  mode, 
  region
} from './OnDemandDefaults'
import { lighthouseItems } from '../../helpers/platformNavItems';
import './ondemand.css'




class OnDemandForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      mode: "3g",
      region: 'eu-west-1',
      email: "",
      urls: [],
      executeDisabled: true,
      csvName: "Upload CSV",
      userId: "",
      errorInCsv: false,
      searchEmail: "",
      disableUserId: false,
      disableSearchEmail: false
    }
  }

  componentDidUpdate = () => {
    if(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(this.state.email) && this.state.urls.length > 0){
      this.setState({
        executeDisabled: false
      })
    }else{
      this.setState({
        executeDisabled: true
      })
    }
  }

  uploadCSV = (e) => {
    this.setState({
      csvName: e.target.files[0].name
    })
    this.getAsText(e.target.files[0])
  };

  handleModeChange = (e ,{value}) => {
    this.setState({
      mode: value
    })
  }

  handleRegionChange = (e, {value}) => {
    this.setState({
      region: value
    })
  }

  handleEmailChange = (e, {value}) => {
    this.setState({
      email: value
    })
  }

  handleUserIdChange = (e, {value}) => {
    if(value.length > 0){
      this.setState({
        userId: value,
        disableSearchEmail: true
      })
    }else{
      this.setState({
        userId: value,
        disableSearchEmail: false
      })
    }
    
  }

  handleSearchEmailChange = (e, {value}) => {
    if(value.length > 0){
      this.setState({
        searchEmail: value,
        disableUserId: true
      })
    }else{
      this.setState({
        searchEmail: value,
        disableUserId: false
      })
    }
  }
  getAsText= (fileToRead) => {
    var reader = new FileReader();
    // Read file into memory as UTF-8      
    reader.readAsText(fileToRead);
    // Handle errors load
    reader.onload = this.fileReadingFinished;
    reader.onerror = this.errorHandler;
  }

  fileReadingFinished = (event) => {
    var csv = event.target.result;
    var allTextLines = csv.split(/\r\n|\n/);
    let nonHttpUrls = allTextLines.filter(url => !url.startsWith("http") && !url.startsWith(',,') && url !== ','&& url !== "")
    allTextLines = allTextLines.filter(Boolean)
    allTextLines = allTextLines.filter(url => url.startsWith("http"))
    if(nonHttpUrls[0] === undefined || nonHttpUrls[0].length === 0){
      allTextLines = allTextLines.map(item => {
        let itemArray = item.split(',');
        return {
          url: itemArray[0],
          username: itemArray[1]?itemArray[1]:"",
          password : itemArray[2]?itemArray[2]:""
        }
      })
      this.setState({
        urls: allTextLines,
        errorInCsv: false
      })
    }else{
      this.setState({
        urls: [],
        errorInCsv: true
      })
    }
  }

  errorHandler = (event) => {
    if (event.target.error.name === "NotReadableError") {
      alert("Cannot read file!");
    }
  }

  handleExecute = () => {
    this.props.changeAction('execute')
    this.props.execute({
      Urls: this.state.urls,
      Mode: this.state.mode,
      Email: this.state.email,
      Region: this.state.region
    })
  }

  handlerReport = () => {
    if(this.state.userId.length > 0){
      this.props.changeAction('fetch')
      this.props.fetchData(this.state.userId.toString())
    }else if(this.state.searchEmail.length > 0){
      this.props.changeAction('fetchEmail')
      this.props.fetchReportForEmail(this.state.searchEmail)
    }
  }

  downloadSampleClicked = () => {
    const csv = "https://www.demourl.com/1,username,password\r\nhttps://www.demourl.com/2,username,password\r\nhttps://www.demourl.com/3";
  
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `Sample.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }
  render() {
    return (
      <div> 
        <Navigation activeItem={this.props.prefix} items={lighthouseItems} />
        <div>
          <span>
            <Button type="button" size="mini" onClick={this.downloadSampleClicked}>
              <Icon name="download" />
      Sample CSV
            </Button>
          </span>
          <Message>
            <Message.Header>Execute Lighthouse For Particular Urls</Message.Header>
            <Message.List>
              <Message.Item>Format the csv according to the sample csv and upload it using the upload CSV button</Message.Item>
              <Message.Item>Select the region and mode you want to run on </Message.Item>
              <Message.Item>Enter comma seperated email to get the report Id on multiple Emails</Message.Item>
            </Message.List>
          </Message>
          <div className="formDiv">
            <span className="leftMargin textCentre">
              <Button as="label" htmlFor="file" type="button">
                {this.state.csvName}
              </Button>
              <input type="file" id="file" hidden onChange={this.uploadCSV} accept=".csv" />
              <Message negative={true} size="mini" hidden={!this.state.errorInCsv}>CSV format error. Please download sample csv</Message>
            </span>
            <span className="leftMargin">
              <label>
                      Mode
                <Dropdown
                  placeholder="Mobile"
                  options={mode}
                  selection
                  compact
                  onChange={this.handleModeChange}
                />
              </label>
            </span>
            <span className="leftMargin">
              <label>
                      Region
                <Dropdown 
                  placeholder={region[0].text}
                  options={region.sort((a, b) => a.text -b.text)}
                  selection
                  compact
                  onChange={this.handleRegionChange}
                />
              </label>
            </span>
            <span className="leftMargin">
              <Input focus placeholder="Email" onChange={this.handleEmailChange} value={this.state.email} />
            </span>
            <span className="submitButtonMargin">
              <Button disabled={this.state.executeDisabled} primary onClick={this.handleExecute} loading={this.props.loadingExec}>Execute</Button>
            </span>
         
          </div>
        </div>
        <div>
          <Divider horizontal>Get Report</Divider>
        </div>
        <Message>
          <Message.Header>Fetching the Report</Message.Header>
          <Message.List>
            <Message.Item>Fetch the report using the report Id</Message.Item>
            <Message.Item>Fetch the report using the email Id</Message.Item>
          </Message.List>
        </Message>
        <div className="formDiv">
          <span className="leftMargin">
            <Input focus placeholder="Report Id" onChange={this.handleUserIdChange} value={this.state.userId} disabled={this.state.disableUserId} />
          </span>
          <span className="leftMargin">
            <Divider veritcal>Or</Divider>
        </span>
        <span className="leftMargin">
            <Input focus placeholder="Email" onChange={this.handleSearchEmailChange} disabled={this.state.disableSearchEmail} value={this.state.searchEmail} />
          </span>
          <span className="submitButtonMargin">
            <Button primary onClick={this.handlerReport} loading={this.props.loadingReport || this.props.loadingEmailReport}>Search</Button>
          </span>
         
        </div>
      </div>
    );
  }
}

OnDemandForm.propTypes = {
  prefix: PropTypes.string,
  execute: PropTypes.func,
  fetchData: PropTypes.func,
  fetchReportForEmail: PropTypes.func,
  loadingExec: PropTypes.bool,
  loadingReport: PropTypes.bool,
  changeAction: PropTypes.func,
  loadingEmailReport: PropTypes.bool
};

export default OnDemandForm;
