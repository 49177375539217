import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { decode } from 'jsonwebtoken';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions';
import SpanningWarningComponent from '../MessageComponents/SpanningWarningComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

class Login extends Component {

  componentDidMount () {
    this.props.clearLogin()
    sessionStorage.removeItem('loginData')
  }

  render () {
    const { initLogin, setLogin, clearLogin, appData, redirectTo } = this.props
    const { loggedInEmail, loginLoading, loginError } = appData
    const startLogin = () => {
      initLogin()
    }

    const responseMessage = (response) => {
      const { email } = decode(response.credential);
      const loginData = { loggedInEmail: email, isLoggedIn: true }
      sessionStorage.setItem('loginData', response.credential)
      setLogin(loginData)
    };

    const errorMessage = (error) => {
      setLogin({ error })
    };

    const cancelLogin = () => {
      clearLogin()
      googleLogout();
      return <Redirect to="/login" />
    }


    if (loginError) {
      return <SpanningWarningComponent message={loginError.message} />
    }

    if (loggedInEmail) {
      return <Redirect to={redirectTo || "/"} />
    }

    if (loginLoading) {
      return (
        <div className="login-in-progress">
          <p>Login is in progress in another window</p>
          <CircularProgress />
          <p><button className="switch__button button switch-btn" onClick={cancelLogin}>Click here to Cancel</button></p>
        </div>
      );
    }

    return (
      <div className="login">
        <div className="login-main">
          <div className="switch" id="switch-cnt">
            <div className="switch__circle"></div>
            <div className="switch__circle switch__circle--t"></div>
            <div className="switch__container" id="switch-c1">
              <h2 className="switch__title title">Welcome !</h2>
              <p className="switch__description description">Please login to access the Sitespeed dashboard</p>
              <GoogleLogin click_listener={startLogin} onSuccess={responseMessage} onError={errorMessage} theme="filled_blue" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  appData: PropTypes.object,
  setLogin: PropTypes.func,
  initLogin: PropTypes.func,
  errorLogin: PropTypes.func,
  clearLogin: PropTypes.func,
  redirectTo: PropTypes.string,
}

function mapStateToProps (state) {
  return {
    appData: state.appData
  }
}
function mapDispatchToProps (dispatch) {
  return {
    initLogin () {
      dispatch(Actions.initLogin());
    },
    setLogin (loginData) {
      dispatch(Actions.setLogin(loginData));
    },
    errorLogin (loginData) {
      dispatch(Actions.errorLogin(loginData));
    },
    clearLogin () {
      dispatch(Actions.clearLogin());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
