import React from 'react';
import matchSorter from 'match-sorter';
import { Link } from 'react-router-dom';
const moment = require('moment');

function getStaticRumColumns(isAdobeClassic) {
  return [
    {
      id: 'Domain',
      Header: 'Domain',
      accessor: d => d.Domain,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['Domain']
        });
      },
      Cell: row => (
        <a
          href={`https://${row.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.value}
        </a>
      ),
      filterAll: true,
      filterable: false
    },
    {
      Header: 'Brand',
      accessor: 'Brand',
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Brand));
        } else {
          return rows;
        }
      },
      Cell: row => {
        if (isAdobeClassic) {
          return (
            <Link to={{ pathname: `adobe-classic/rum/${row.value}` }}>
              {row.value}
            </Link>
          );
        } else {
          return row.value;
        }
      },
      filterAll: true
    },
    {
      id: 'Locale',
      Header: 'Locale',
      accessor: 'Locale',
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Locale));
        } else {
          return rows;
        }
      },
      filterAll: true
    },
    {
      Header: 'Category',
      accessor: 'Category',
      Cell: row => {
        if (isAdobeClassic) {
          return (
            <Link to={{ pathname: `adobe-classic/rum/category/${row.value}` }}>
              {row.value}
            </Link>
          );
        } else {
          return row.value;
        }
      },
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Category));
        } else {
          return rows;
        }
      },
      filterAll: true
    },
    {
      id: 'Cluster',
      Header: 'Cluster',
      accessor: 'Cluster',
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Cluster));
        } else {
          return rows;
        }
      },
      filterAll: true
    },
    {
      id: 'Parent',
      Header: 'Parent',
      accessor: 'Parent',
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Parent));
        } else {
          return rows;
        }
      },
      filterAll: true
    },
    {
      id: 'Priority',
      Header: 'Priority',
      accessor: 'Priority',
      filterMethod: (filter, rows) => {
        if (filter.value) {
          return rows.filter(i => filter.value.includes(i.Priority));
        } else {
          return rows;
        }
      },
      filterAll: true
    },
    {
      id: 'DateAdded',
      Header: 'Date Added',
      accessor: 'DateAdded',
      sortMethod: (a, b) => {
        let result = moment
          .utc(a, 'DD/MM/YYYY')
          .diff(moment.utc(b, 'DD/MM/YYYY'));
        return result;
      },
      // Cell: row => <Link to={{ pathname: `${row.value}` }}>{row.value}</Link>,
      // filterAll: true,
      filterable: false
    }
  ];
}

const rumTableColumnData = [
  {
    selectText: 'Brand',
    filtersName: 'brandFilters',
    filtersValue: 'brandValue',
    type: 'Brand'
  },
  {
    selectText: 'Locale',
    filtersName: 'localeFilters',
    filtersValue: 'localeValue',
    type: 'Locale'
  },
  {
    selectText: 'Category',
    filtersName: 'categoryFilters',
    filtersValue: 'categoryValue',
    type: 'Category'
  },
  {
    selectText: 'Cluster',
    filtersName: 'clusterFilters',
    filtersValue: 'clusterValue',
    type: 'Cluster'
  },
  {
    selectText: 'Parent',
    filtersName: 'parentFilters',
    filtersValue: 'parentValue',
    type: 'Parent'
  },
  {
    selectText: 'Priority',
    filtersName: 'priorityFilters',
    filtersValue: 'priorityValue',
    type: 'Priority'
  },
];

const getCommonCsvOptions = () => {
  return {
    prependHeader: false,
    expandArrayObjects: false
  };
}

const getRumAllPagesHeaders = () => {
  return [
    'Domain',
    'Brand',
    'Locale',
    'Cluster',
    'Category',
    'Parent',
    'Priority'
  ];
}

const getKeyPagesHeaders = () => {
  return [
    'Domain',
    'Brand',
    'Locale',
    'Cluster',
    'Category',
    'PageUrl',
    'Parent',
    'Priority'
  ];
}

const getKeyPagesClassicHeaders = () => {
  return [
    'Domain',
    'Brand',
    'Locale',
    'Cluster',
    'Category',
    'SortKey',
    'Parent',
    'Priority'
  ];
}

const getSyntheticHeaders = () => {
  return [
    'Domain',
    'Brand',
    'Locale',
    'PageUrl',
    'Parent',
    'Priority'
  ];
}

const getLighthouseHeaders = () => {
  return [
    'Domain',
    'Brand',
    'Locale',
    'PageUrl',
    'IsCxx',
    'Tier',
    'SuperCategory'
  ];
}

const getCsvOptionsRumAllPages = (timePeriod) => {
  return {
    ...getCommonCsvOptions(),
    keys: [
      ...getRumAllPagesHeaders(),
      `${timePeriod}.average`,
      `${timePeriod}.mobileAverage`,
      `${timePeriod}.desktopAverage`,
      `${timePeriod}.tabletAverage`,
      `${timePeriod}.unknownAverage`
    ]
  };
};

const getCsvOptionsKeyPages = () => {
  return {
    ...getCommonCsvOptions(),
    keys: [
      ...getKeyPagesHeaders(),
      'Months',
      'Weeks'
    ]
  };
}

const getCsvOptionsKeyPagesClassic = () => {
  return {
    ...getCommonCsvOptions(),
    keys: [
      ...getKeyPagesClassicHeaders(),
      'Months',
      'Weeks'
    ]
  };
};

const getCsvOptionsSynthetic = () => {
  return {
    ...getCommonCsvOptions(),
    keys: [
      ...getSyntheticHeaders(),
      'Months',
      'Weeks',
      'Days'
    ]
  };
};

const getCsvOptionsLighthouse = () => {
  return {
    ...getCommonCsvOptions(),
    keys: [
      ...getLighthouseHeaders(),
      'Months',
      'Weeks',
      'Days'
    ]
  };
};

const genesisBrands = [
  'Axe',
  'Ala',
  'DIG',
  'Dove',
  'Hellmanns',
  'Knorr',
  'Lipton',
  'Lux',
  'Magnum',
  'OMO',
  'Ponds',
  'Rexona',
  'Signal',
  'Sunsilk',
  'Tresemme',
];
const genesisLocales = [
  'Argentina',
  'Australia',
  'Brazil',
  'Canada - English',
  'Canada - French',
  'China',
  'France',
  'Germany',
  'India',
  'Indonesia - Indonesian',
  'Indonesia - English',
  'Japan',
  'Netherlands - Dutch',
  'Philippines',
  'Russia',
  'Thailand - Thai',
  'Thailand - English',
  'Turkey - Turkish',
  'United Kingdom',
  'United States - English',
  'United States - Spanish'
];

const genesisDomains = [
  'www.ala.com.ar/',
  'www.axe.com/ar/',
  'www.axe.com/br/',
  'www.axe.com/ca/en/',
  'www.axe.com/ca/fr/',
  'www.axe.com/de/',
  'www.axe.com/fr',
  'www.axe.com/id/',
  'www.axe.com/nl/',
  'www.axe.com/tr/',
  'www.lynxformen.com/uk/',
  'www.axe.com/us/en/',
  'www.axe.com/us/es/',
  'www.algida.com.tr/',
  'www.biotex.dk/',
  'www.breeze.co.th/',
  'www.breeze.com.ph/',
  'www.cif.com.ar/',
  'www.cif.com.tr/',
  'www.cifclean.co.uk/',
  'www.ciflimpadores.com.br/',
  'www.continental.com.au/',
  'www.comfort.com.ar/',
  'www.comfort.com.ar/',
  'www.comfort.com.br/',
  'www.comfort.com.vn/',
  'www.comfortworld.co.uk/',
  'www.clearhaircare.com/br/',
  'www.clearhaircare.com/id/',
  'www.clearhaircare.com/ph/',
  'www.clearhaircare.com/th/',
  'www.clearhaircare.com/tr/',
  'www.clearhaircare.com/vn/',
  'www.degreedeodorant.com/ca/en/',
  'www.degreedeodorant.com/us/en/',
  'www.degreedeodorant.com/ca/fr/',
  'www.domestos.com/pl/',
  'www.domestos.com/ru/',
  'www.domestos.com/tr/',
  'www.domestos.com/uk/',
  'www.domestos.com/za/',
  'www.domexforsanitation.com/',
  'www.dove.com/ar/',
  'www.dove.com/au/',
  'www.dove.com/br/',
  'www.dove.com/ca/en/',
  'www.dove.com/cl/',
  'www.dove.com/co/',
  'www.dove.com/de/',
  'www.dove.com/dk/',
  'www.dove.com/es/',
  'www.dove.com/fr/',
  'www.dove.com/in/',
  'www.dove.com/id/',
  'www.dove.com/it/',
  'www.dove.com/jp/',
  'www.dove.com/mx/',
  'www.dove.com/nl/',
  'www.dove.com/ph/',
  'www.dove.com/pl/',
  'www.dove.com/ru/',
  'www.dove.com/sa/ar/',
  'www.dove.com/sa/en/',
  'www.dove.com/se/',
  'www.dove.com/th/',
  'www.dove.com/uk/',
  'www.dove.com/us/en/',
  'www.dove.com/us/es/',
  'www.dove.com/za/',
  'www.elidor.com.tr/',
  'www.frigo.es/',
  'www.fab.com.co/',
  'www.goodhumor.com/us/en/',
  'www.heladosholanda.com.mx/',
  'www.hellmanns.com/ar/',
  'www.hellmanns.com/ca/en/',
  'www.hellmanns.com/ca/fr/',
  'www.hellmanns.com/cl/',
  'www.hellmanns.com/es/',
  'www.hellmanns.com/uk/',
  'www.hellmanns.com/us/en/',
  'www.hellmanns.com.mx/',
  'www.hellmanns.com.br/',
  'www.herkessofraya.com/',
  'www.kibon.com.br/',
  'www.knorr.com.mx/',
  'www.knorr.com/ar/',
  'www.knorr.com/br/',
  'www.knorr.com/ca/en/',
  'www.knorr.com/de/',
  'www.knorr.com/fr/',
  'www.knorr.com/nl/',
  'www.knorr.com/ph/',
  'www.knorr.com/th/',
  'www.knorr.com/uk/',
  'www.knorr.com/us/en/',
  'www.knorr.co.za/',
  'www.royco.co.id/',
  'www.langnese.de/',
  'www.lipton.com/arabia/ar/',
  'www.lipton.com/arabia/en/',
  'www.lipton.com/fr/',
  'www.lipton.com/tr/',
  'www.lipton.com/us/en/',
  'www.lipton.nl/',
  'www.lovebeautyandplanet.com/br/',
  'www.lovebeautyandplanet.com/uk/',
  'www.lovebeautyandplanet.com/us/en/',
  'www.lovebeautyandplanet.com/us/es/',
  'www.lux.com/bd/',
  'www.lux.com/br/',
  'www.lux.com/id/',
  'www.lux.com/in/',
  'www.lux.com/sa/ar/',
  'www.lux.com/sa/en/',
  'www.lynxformen.com/uk/',
  'www.magnaticecream.ru/',
  'www.magnumicecream.com/au/',
  'www.magnumicecream.com/br/',
  'www.magnumicecream.com/ca/fr/',
  'www.magnumicecream.com/de/',
  'www.magnumicecream.com/es/',
  'www.magnumicecream.com/fr/',
  'www.magnumicecream.com/id/id/',
  'www.magnumicecream.com/it/',
  'www.magnumicecream.com/mx/es/',
  'www.magnumicecream.com/nl/',
  'www.magnumicecream.com/ph/',
  'www.magnumicecream.com/th/th/',
  'www.magnumicecream.com/tr/',
  'www.magnumicecream.com/uk/',
  'www.magnumicecream.com/us/en/',
  'www.mentadent.it/',
  'www.molto.co.id/',
  'www.missionsignal.fr/',
  'www.nexxus.com/ca/en/',
  'www.nexxus.com/ca/fr/home.html',
  'www.nexxus.com/en/us/home',
  'www.omo.com/au/',
  'www.omo.com/br/',
  'www.omo.com/cl/',
  'www.omo.com/tr/',
  'www.omo.com/vn/',
  'www.omo.com/za/',
  'www.pepsodent.in/',
  'www.persil.com/uk/',
  'www.ponds.com/co/',
  'www.ponds.com/id/',
  'www.ponds.com/mx/',
  'www.ponds.com/ph/',
  'www.ponds.com/th/',
  'www.ponds.com/us/en/',
  'www.ponds.com/vn/',
  'www.ponds.com/za/',
  'www.prodent.nl/',
  'www.psvietnam.vn/',
  'www.rexona.com/ar/',
  'www.rexona.com/au/',
  'www.rexona.com/br/',
  'www.rexona.com/co/',
  'www.rexona.com/de/',
  'www.rexona.com/es/',
  'www.rexona.com/id/',
  'www.rexona.com/in/',
  'www.rexona.com/ph/',
  'www.rexona.com/nl/',
  'www.rexona.com/mx/',
  'www.rexona.com/ru/',
  'www.rexona.com/tr/',
  'www.rexona.com/th/',
  'www.rinso.com/id/',
  'www.royco.co.id/',
  'www.seda.com.br/',
  'www.sedal.com.ar/',
  'www.signalturkiye.com/',
  'www.skip.com/fr/',
  'www.sunsilk.co.id/',
  'www.surfexcel.in/',
  'www.sedal.com.ar/',
  'www.sedal.com.mx/',
  'www.sunsilk.com.ph/',
  'www.sunsilk.com.vn/',
  'www.sunsilk.in/',
  'www.sunsilk.pk/',
  'www.sunsilkthailand.com/',
  'www.suredeodorant.co.uk/',
  'www.surfexcel.in/',
  'www.sharehappy.it/',
  'www.shield.co.za/',
  'www.selectaphilippines.com/',
  'www.tanyapepsodent.com/',
  'www.tresemme.com.mx/',
  'www.tresemme.com/ar/',
  'www.tresemme.com/br/',
  'www.tresemme.com/ca/en/',
  'www.tresemme.com/id/',
  'www.tresemme.com/in/',
  'www.tresemme.com/ph/',
  'www.tresemme.com/ru/',
  'www.tresemme.com/th/',
  'www.tresemme.com/uk/',
  'www.tresemme.com/us/en/',
  'www.via.se/',
  'www.vim.com.ar/',
  'www.viss.de/',
  'www.wallsicecream.com/id/',
  'www.wallsicecream.com/uk/',
  'www.wallsthailand.com/',
  'www.kwalitywalls.in/',
];

export {
  getStaticRumColumns,
  rumTableColumnData,
  genesisBrands,
  genesisLocales,
  getCsvOptionsRumAllPages,
  getCsvOptionsKeyPages,
  getCsvOptionsKeyPagesClassic,
  getCsvOptionsSynthetic,
  getCsvOptionsLighthouse,
  getRumAllPagesHeaders,
  getKeyPagesHeaders,
  getKeyPagesClassicHeaders,
  getSyntheticHeaders,
  getLighthouseHeaders,
  genesisDomains
};
