import React from 'react';
import Navigation from '../Navigation';
import PropTypes from 'prop-types';
import { rumChartNavItems } from '../../helpers/platformNavItems';

function RumChartsNotAvailable(props) {
  let platform = '';
  if (props.location) {
    const initialPath = props.location.pathname.split('/')[1];
    platform = initialPath;
    if (initialPath.includes('-')) {
      platform = initialPath.split('-')[1];
    }
  }
  return (
    <span>
      <Navigation activeItem={platform} items={rumChartNavItems} />
      <div style={{ padding: '20px', height: '100%' }}>
        <h1>Rum Charts currently only available for Adobe Classic</h1>
      </div>
    </span>
  );
}

RumChartsNotAvailable.propTypes = {
  location: PropTypes.object
};

export default RumChartsNotAvailable;
