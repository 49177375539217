import React from 'react';
import Navigation from '../components/Navigation';
// import { Actions as AdobeClassicActions } from '../store/actions';
// import { Actions as AdobeTemplatedActions } from '../store/actions/adobeTemplated';
import { Actions as WPLiteActions } from '../store/actions/wpLite/';
import { Actions as AdobeSaasActions } from '../store/actions/adobeSaas/';
import { Actions as LibertyActions } from '../store/actions/liberty/';
import { Actions as ATHActions } from '../store/actions/ath/';
import { rumTableNavItems } from '../helpers/platformNavItems';
import { connect } from 'react-redux';
import RumSummaryTable from './templates/RumSummaryTable';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

class AllPlatformsRumTable extends React.PureComponent {
  state = {
    summaryData: {},
    loading: true
  };
  componentDidMount () {
    this.props.fetchData();
  }
  componentDidUpdate (prevProps) {
    const {
      saasSummaryData,
      libertySummaryData,
      athSummaryData,
      liteSummaryData,
      saasLoading,
      athLoading,
      libertyLoading,
      liteLoading
    } = this.props;
    if (
      prevProps.saasSummaryData !== saasSummaryData ||
      prevProps.liteSummaryData !== liteSummaryData ||
      prevProps.athSummaryData !== athSummaryData ||
      prevProps.libertySummaryData !== libertySummaryData
    ) {
      if (
        !saasLoading &&
        !liteLoading &&
        !athLoading &&
        !libertyLoading
      ) {
        const timeIntervals = { Months: [], Weeks: [], Days: [] };

        for (let timeInterval of Object.keys(timeIntervals)) {
          for (let timeIntervalData of saasSummaryData[timeInterval]) {
            const intervalSummary = Object.assign({}, timeIntervalData);
            const platforms = [
              liteSummaryData,
              athSummaryData,
              libertySummaryData,
            ];
            for (let platform of platforms) {
              const platformData = platform[timeInterval].find(
                i => i.id === intervalSummary.id
              );
              if (platformData) {
                intervalSummary.trueGreenCount += platformData.trueGreenCount;
                intervalSummary.greenCount += platformData.greenCount;
                intervalSummary.amberCount += platformData.amberCount;
                intervalSummary.redCount += platformData.redCount;
                intervalSummary.total += platformData.total;
                intervalSummary.noData += platformData.noData;
              }
            }
            timeIntervals[timeInterval].push(intervalSummary);
          }
        }

        const summaryData = Immutable(timeIntervals);
        this.setState({ summaryData, loading: false });
      }
    }
  }
  render () {
    return (
      <span>
        <Navigation activeItem="all" items={rumTableNavItems} />
        <div style={{ padding: '20px' }}>
          <h1>
            Real User Monitoring (RUM) data - All pages, All strategic platforms
          </h1>
          <p>
            RUM (Real User Monitoring) Summary data for Adobe Saas, 
            Wordpress Lite, ATH and Liberty.
          </p>
          <RumSummaryTable
            summaryData={this.state.summaryData}
            loading={this.state.loading}
            showDaily={true}
          />
        </div>
      </span>
    );
  }
}
AllPlatformsRumTable.propTypes = {
  saasSummaryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  saasLoading: PropTypes.bool,
  liteSummaryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  liteLoading: PropTypes.bool,
  athSummaryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  athLoading: PropTypes.bool,
  libertySummaryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  libertyLoading: PropTypes.bool,
  fetchData: PropTypes.func,
};

function mapStateToProps (state) {
  return {
    saasSummaryData: state.adobeSaas.rumData.summaryData,
    saasLoading: state.adobeSaas.rumData.loading,
    liteSummaryData: state.wpLite.rumData.summaryData,
    liteLoading: state.wpLite.rumData.loading,
    athSummaryData: state.ath.rumData.summaryData,
    athLoading: state.ath.rumData.loading,
    libertySummaryData: state.liberty.rumData.summaryData,
    libertyLoading: state.liberty.rumData.loading,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    fetchData () {
      // dispatch(AdobeClassicActions.fetchAppMetadata());
      // dispatch(AdobeTemplatedActions.fetchRumData());
      dispatch(AdobeSaasActions.fetchRumData());
      dispatch(WPLiteActions.fetchRumData());
      dispatch(ATHActions.fetchRumData());
      dispatch(LibertyActions.fetchRumData());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllPlatformsRumTable);
