import React from 'react';
import matchSorter from 'match-sorter';
import { Link } from 'react-router-dom';

const BrandMetadataTableColumns = [
  {
    id: 'Domain',
    Header: 'Domain',
    accessor: d => d.Domain,
    Cell: row => (
      <a href={`https://${row.value}`}  rel="noopener">
        {row.value}
      </a>
    ),
    filterAll: true,
    filterable: false
  },
  {
    id: 'Brand',
    Header: 'Brand',
    accessor: d => d.Brand,
    Cell: row => (
      <Link to={{ pathname: `adobe-classic/rum/${row.value}` }}>{row.value}</Link>
    ),
    aggregate: () => '',
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Brand));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'Locale',
    Header: 'Locale',
    accessor: d => d.Locale,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Locale));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'SuperCategory',
    Header: 'Super Category',
    accessor: d => d.SuperCategory,
    Cell: row => (
      <Link to={{ pathname: `adobe-classic/rum/category/${row.value}` }}>{row.value}</Link>
    ),
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.SuperCategory));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'SubCategory',
    Header: 'Sub Category',
    accessor: d => d.SubCategory,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.SubCategory));
      } else {
        return rows;
      }
    },
    filterAll: true,
    show: false
  },
  {
    id: 'Cluster',
    Header: 'Cluster',
    accessor: d => d.Cluster,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Cluster));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'Parent',
    Header: 'Parent',
    accessor: d => d.Parent,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Parent));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'Priority',
    Header: 'Priority',
    accessor: d => d.Priority,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Priority));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'MCO',
    Header: 'MCO',
    accessor: d => d.MCO,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.MCO));
      } else {
        return rows;
      }
    },
    filterAll: true
  },
  {
    id: 'Country',
    Header: 'Country',
    accessor: d => d.Country,
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Country));
      } else {
        return rows;
      }
    },
    filterAll: true,
  },
  {
    id: 'Agency',
    Header: 'Agency',
    accessor: d => d.Agency,
    aggregate: () => '',
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Agency));
      } else {
        return rows;
      }
    },
    filterAll: true,
  },
  {
    id: 'Appname',
    Header: 'AppName',
    accessor: d => d.AppName,
    aggregate: () => '',
    filterMethod: (filter, rows) => {
      return matchSorter(rows, filter.value, {
        keys: ['appname']
      });
    },
    filterAll: true,
  },
  {
    id: 'nrId',
    Header: 'New Relic App ID',
    accessor: d => {
      if (d.IdSiteKey) {
        return d.IdSiteKey.split('#')[0];
      } else {
        return d.AppId;
      }
    },
    aggregate: () => '',
    filterMethod: (filter, rows) => {
      return matchSorter(rows, filter.value, {
        keys: ['nrId']
      });
    },
    filterAll: true,
    filterable: false,
  }
];

const TableViewColumns = [
  {
    id: 'Domain',
    Header: 'Domain',
    accessor: d => d.Domain
  },
  {
    id: 'AppName',
    Header: 'App Name',
    accessor: d => d.AppName
  },
  {
    id: 'Percentile80Average30Days',
    Header: 'Average Full Page Load Speed',
    accessor: d => d.Percentile80Average30Days
  },
  {
    id: 'Min30DayAverage',
    Header: 'Minimum Full Page Load Speed',
    accessor: d => d.Min30DayAverage
  },
  {
    id: 'Max30DayAverage',
    Header: 'Maximum Full Page Load Speed',
    accessor: d => d.Max30DayAverage
  }
];

const BrandMetaTableColumnData = [
  {
    selectText: 'Brand',
    filtersName: 'brandFilters',
    filtersValue: 'brandValue',
    type: 'Brand'
  },
  {
    selectText: 'Locale',
    filtersName: 'localeFilters',
    filtersValue: 'localeValue',
    type: 'Locale'
  },
  {
    selectText: 'Category',
    filtersName: 'categoryFilters',
    filtersValue: 'categoryValue',
    type: 'SuperCategory'
  },
  {
    selectText: 'Category',
    filtersName: 'categoryFilters',
    filtersValue: 'categoryValue',
    type: 'SuperCategory'
  },
  {
    selectText: 'Sub Category',
    filtersName: 'subCategoryFilters',
    filtersValue: 'subCategoryValue',
    type: 'SubCategory'
  },
  {
    selectText: 'Cluster',
    filtersName: 'clusterFilters',
    filtersValue: 'clusterValue',
    type: 'Cluster'
  },
  {
    selectText: 'Parent',
    filtersName: 'parentFilters',
    filtersValue: 'parentValue',
    type: 'Parent'
  },
  {
    selectText: 'Priority',
    filtersName: 'priorityFilters',
    filtersValue: 'priorityValue',
    type: 'Priority'
  },
  {
    selectText: 'MCO',
    filtersName: 'mcoFilters',
    filtersValue: 'mcoValue',
    type: 'MCO'
  },
  {
    selectText: 'Country',
    filtersName: 'countryFilters',
    filtersValue: 'countryValue',
    type: 'Country'
  },
  {
    selectText: 'Agency',
    filtersName: 'agencyFilters',
    filtersValue: 'agencyValue',
    type: 'Agency'
  }
];

export {
  BrandMetadataTableColumns,
  TableViewColumns,
  BrandMetaTableColumnData
};
