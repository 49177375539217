import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';

class DateRange extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      startDate: moment(),
      endDate: moment(),
      focusedInput: null
    };
  }

  onDownloadClick() {
    this.props.onDownloadClick(this.state.startDate, this.state.endDate);
  }

  render() {
    return (
      <div className="date-range__container">
        <DateRangePicker
          onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate })}}
          startDateId="startDate"
          endDateId="endDate"
          displayFormat="DD-MM-YYYY"
          firstDayOfWeek={1}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          isOutsideRange={(date) => date > moment()}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
        />

        <div className="date-range__download-button">
          <Button
            basic
            loading={this.props.loading}
            icon="download"
            content="Download CSV"
            onClick={this.onDownloadClick.bind(this)}
          />
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  loading: PropTypes.bool,
  onDownloadClick: PropTypes.func
};

export default DateRange;
