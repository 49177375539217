import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: [],
  error: null,
  charts: {}
});

function categoriesReducer(state=initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_RUM_CATEGORIES: 
      return state.merge({data: [], loading: true});
  
    case ActionNames.RECEIVE_RUM_CATEGORIES: 
      return state.merge({
        loading: false,
        data: action.data,
        charts: action.charts
      });

    case ActionNames.FAILURE_RUM_CATEGORIES: 
      return state.merge({error: action.error, loading: false});

    case ActionNames.REQUEST_CHARTS_CATEGORIES: {
      const charts = Object.assign({}, state.charts, { [action.name]: { loading: true, data: [] }})
      return state.merge({charts});
    }
    case ActionNames.RECEIVE_CHARTS_CATEGORIES: {
      const chart = Object.assign({}, {loading: false}, action.data);
      const charts = Object.assign({}, state.charts, { [action.name]: chart });
      return state.merge({charts});
    }
    case ActionNames.FAILURE_CHARTS_CATEGORIES: {
      const charts = Object.assign({}, state.charts, { [action.name]: { loading: false, data: [] }})
      return state.merge({charts});
    }

    default: 
      return state;
  }
}

export default categoriesReducer;