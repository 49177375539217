import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: {},
  error: null,
});

function metricsReducer(state = initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_METRICS:
      return state.merge({loading: true, data: {}});
    case ActionNames.RECEIVE_METRICS:
      return  state.merge({loading: false, data: action.data,});
    case ActionNames.FAILURE_METRICS:
      return  state.merge({loading: false, error: action.error, data: {}});
    default:
      return state;
  }
}

export default metricsReducer;
