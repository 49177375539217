import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import RumTable from '../../containers/wp-lite/RumTable';
// import RumKeyPagesTable from '../../containers/wp-lite/RumKeyPages';
// import SyntheticKeyPages from '../../containers/wp-lite/SyntheticKeyPages';
// import SyntheticTestRuns from '../../containers/SyntheticTestRuns';
import NoMatch from '../MessageComponents/NoMatch';
import BrandMetaData from '../../containers/wp-lite/BrandMetaData';
import RumChartsNotAvailable from '../MessageComponents/RumChartsNotAvailable';
import LighthouseTestRuns3g from '../../containers/LighthouseTestRuns3g';
import LighthouseKeyPagesTable from '../../containers/wp-lite/LighthouseKeyPagesTable';
import LighthouseTestRunsCable from '../../containers/LighthouseTestRunsCable';
import LighthouseAggregatedRuns3g from '../../containers/LighthouseAggregatedRuns3g';
import LighthouseAggregatedRunsCable from '../../containers/LighthouseAggregatedRunsCable';
import { connect } from 'react-redux';

function WPLiteRouter ({ match }) {


  return (
    <Switch>
      <Route exact path={`${match.url}/`}>
        <Redirect exact to={`${match.url}/rum-table`} />
      </Route>
      <Route exact path={`${match.url}/rum-table`} component={RumTable} />
      {/* <Route
        exact
        path={`${match.url}/rum-table-key-pages`}
        component={RumKeyPagesTable}
      /> */}
      <Route
        exact
        path={`${match.url}/rum`}
        component={RumChartsNotAvailable}
      />
      {/* <Route
        exact
        path={`${match.url}/synthetic`}
        component={SyntheticKeyPages}
      /> */}
      {/* <Route
        exact
        path={`${match.url}/synthetic-test-runs`}
        component={SyntheticTestRuns}
      /> */}
      {/* <Route
        exact
        path={`${match.url}/synthetic/synthetic-test-runs`}
        component={SyntheticTestRuns}
      /> */}
      <Route
        exact
        path={`${match.url}/lighthouse`}
        component={LighthouseKeyPagesTable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-test-runs-3g`}
        component={LighthouseTestRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-test-runs-3g`}
        component={LighthouseTestRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-test-runs-cable`}
        component={LighthouseTestRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-test-runs-cable`}
        component={LighthouseTestRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-aggregated-runs-3g`}
        component={LighthouseAggregatedRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-aggregated-runs-3g`}
        component={LighthouseAggregatedRuns3g}
      />
      <Route
        exact
        path={`${match.url}/lighthouse-aggregated-runs-cable`}
        component={LighthouseAggregatedRunsCable}
      />
      <Route
        exact
        path={`${match.url}/lighthouse/lighthouse-aggregated-runs-cable`}
        component={LighthouseAggregatedRunsCable}
      />
      <Route exact path={`${match.url}/data`} component={BrandMetaData} />
      <Route component={NoMatch} />
    </Switch>
  );
}

WPLiteRouter.propTypes = {
  match: PropTypes.object,
};

export default connect()(WPLiteRouter);
