import ActionNames from '../../actions/ath/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: [],
  error: null
});

function rumLeaderboardReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_RUM_LEADERBOARD:
      return state.merge({ loading: true, data: [] });
    case ActionNames.RECEIVE_RUM_LEADERBOARD:
      return state.merge({ loading: false, data: action.data });
    case ActionNames.FAILURE_RUM_LEADERBOARD:
      return state.merge({ loading: false, error: action.error, data: [] });
    default:
      return state;
  }
}

export default rumLeaderboardReducer;
