import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loggedInEmail: null,
  isLoggedIn: false,
  loginLoading: false,
  loginError: null
});

function appDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.INIT_LOGIN:
      return state.merge({
        loginLoading: true,
        loggedInEmail: null,
        isLoggedIn: false,
        loginError: null
      });
    case ActionNames.SET_LOGIN:
      return state.merge({
        loginLoading: false,
        loggedInEmail: action.data.loggedInEmail,
        isLoggedIn: action.data.isLoggedIn
      });
    case ActionNames.ERROR_LOGIN:
      return state.merge({
        loginLoading: false,
        loggedInEmail: null,
        isLoggedIn: false
      });
    case ActionNames.CLEAR_LOGIN:
      return state.merge({
        loggedInEmail: null,
        isLoggedIn: false,
        loginLoading: false,
        loginError: null
      });
    default:
      return state;
  }
}

export default appDataReducer;
