import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

class Filter extends React.Component {
  onChange = (e, { value }) => {
    this.props.onChange(value);
  };

  render() {
    return (
      <Form className="radio-filter">
        {this.props.title && <span>{this.props.title}</span>}
        {this.props.data.map(v => (
          <Form.Field key={v.id}>
            <Radio
              className="radio"
              label={v.text}
              name="radioGroup"
              value={v.value}
              checked={v.value === this.props.value}
              onChange={this.onChange.bind(this)}
            />
          </Form.Field>
        ))}
      </Form>
    );
  }
}

Filter.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string
};
export default Filter;
