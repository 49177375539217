import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: {},
  error: null
});

function allMetricsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_ALL_METRICS:
      return state.merge({ loading: true, data: {} });
    case ActionNames.RECEIVE_ALL_METRICS:
      return state.merge({
        loading: false,
        data: action.data
      });
    case ActionNames.FAILURE_ALL_METRICS:
      return state.merge({
        loading: false,
        // error: action.error.message,
        error: action.error && action.error.message,
        data: {}
      });
    default:
      return state;
  }
}

export default allMetricsReducer;
