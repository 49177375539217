import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: [],
  error: null,
});

function brandsReducer(state=initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_SITES: 
      return state.merge({data: [], loading: true});
    case ActionNames.RECEIVE_SITES: 
      return state.merge({data: action.data.items, average: action.data.average, loading: false})
    case ActionNames.FAILURE_SITES: 
      return state.merge({error: action.error, loading: false});
    default: 
      return state;
  }
}

export default brandsReducer;