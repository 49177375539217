import { combineReducers } from 'redux';

import keyPagesData from './keyPagesData';
import keyPagesSummary from './keyPagesSummary'

import competitorKeyPages from './competitorKeyPages';
import competitorKeyPagesSummary from './competitorKeyPagesSummary'

export default combineReducers({
  keyPagesData,
  keyPagesSummary,
  competitorKeyPages,
  competitorKeyPagesSummary
});