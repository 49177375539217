import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  average: null,
  loading: false,
  sitesCount: null,
  items:[]
});

function agenciesReducer(state=initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_CURRENT:
      return state.merge({loading:true});
    case ActionNames.RECEIVE_CURRENT:
      return Immutable(Object.assign({},{loading: false}, action.data))
    case ActionNames.FAILURE_CURRENT:
      return state.merge({ loading:false });
    default: 
      return state;
  }
}

export default agenciesReducer;