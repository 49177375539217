import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import './ondemand.css'


class RecentReportTableTemplate extends PureComponent {

    reportIdOnClick = (e, value) => {
      this.props.changeAction('fetch')
      this.props.fetchData(e)
    }
    render() {
      const columns = [
        {
          Header: 'Report Id',
          accessor: 'ReportId',
          Cell: row => {
            return <button className="reportIdButton" onClick={this.reportIdOnClick.bind(this, row.value)}>{row.value}</button>
          },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            return Number(a) > Number(b) ? 1 : -1;
          },
        },
        {
          Header: 'Email',
          accessor: "Email"
        }
      ];

      return (
        <div style={{ padding: '20px', height: '100%' }}>
          <ReactTable
            data={this.props.data.asMutable()}
            columns={columns}
            pageSize={this.props.data.length}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={true}
            className="-striped -highlight"
          />
        </div>
      );
    }
}

RecentReportTableTemplate.propTypes = {
  data: PropTypes.array,
  fetchData: PropTypes.func,
  changeAction: PropTypes.func
}

export default RecentReportTableTemplate