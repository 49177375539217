import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  onDemandReportData: []
});

function onDemandReportReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_ONDEMAND_LIGHTHOUSE_REPORT:
      return state.merge({ onDemandReportData: [], loading: true });
    case ActionNames.RECEIVE_ONDEMAND_LIGHTHOUSE_REPORT:
      return state.merge({ onDemandReportData: action.data, loading: false });
    case ActionNames.FAILURE_ONDEMAND_LIGHTHOUSE_REPORT:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default onDemandReportReducer;
