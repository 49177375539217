import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: [],
  summaryData: {},
  error: null
});

function cxxRumDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_CXX_SITE_DATA:
      return state.merge({ loading: true, data: [], summaryData: {} });
    case ActionNames.RECEIVE_CXX_SITE_DATA:
      return state.merge({
        loading: false,
        data: action.data,
        summaryData: action.summaryData
      });
    case ActionNames.FAILURE_CXX_SITE_DATA:
      return state.merge({
        loading: false,
        error: action.error.message,
        data: [],
        summaryData: {}
      });
    default:
      return state;
  }
}

export default cxxRumDataReducer;
