import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions/wpLite/';
import BrandMetadata from '../templates/BrandMetadata';

class WPLiteBrandMetaData extends React.PureComponent{
  render() {
    return <BrandMetadata
      {...this.props}
      prefix="wpLite"
    />
  }
}

WPLiteBrandMetaData.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func
}

function mapStateToProps(state) {
  return {
    data: state.wpLite.brandMetaData.data,
    loading: state.wpLite.brandMetaData.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData() {
      dispatch(Actions.fetchBrandMetaData());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WPLiteBrandMetaData)