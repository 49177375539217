import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Divider, Button, Checkbox, Header, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import { json2csvAsync } from 'json-2-csv';
import Navigation from '../../components/Navigation';
import RumSummaryTable from './RumSummaryTable';
import Filter from '../../components/Filter';
import TableColumnDropDown from '../../components/TableColumnDropDown';
import List from '../../components/List';
import JsonModal from './JsonModal';
import DateRange from '../../components/DateRange';
import {
  getStaticRumColumns,
  rumTableColumnData,
  getCsvOptionsRumAllPages,
  genesisDomains,
  getRumAllPagesHeaders
} from '../../helpers/rumTableHelpers';
import {
  RED,
  GREEN,
  TRUEGREEN,
  AMBER,
  NOT_AVAILABLE_COLOUR,
  NOT_AVAILABLE,
  SMALL_TEXT_SIZE,
  LARGE_TEXT_SIZE,
  WEIGHT_NORMAL,
  WEIGHT_BOLD
} from '../../helpers/constants';
import { isGreen, isAmber, isRed, isTrueGreen } from '../../helpers/averageRUM';
import NavItems from '../../helpers/rumNavItems';
import { rumTableNavItems } from '../../helpers/platformNavItems';

const DEVICE_TYPE_MAPPINGS = {
  All: {
    average: 'average',
    visitors: 'visitors'
  },
  Mobile: {
    average: 'mobileAverage',
    visitors: 'mobileVisitors'
  },
  Desktop: {
    average: 'desktopAverage',
    visitors: 'desktopVisitors'
  },
  Tablet: {
    average: 'tabletAverage',
    visitors: 'tabletVisitors'
  },
  Unknown: {
    average: 'unknownAverage',
    visitors: 'unknownVisitors'
  }
};

const DEVICE_TYPE_MAPPINGS_LOCALE = {
  All: {
    average: 'localeAverage',
    visitors: 'localeVisitors'
  },
  Mobile: {
    average: 'mobileLocaleAverage',
    visitors: 'mobileLocaleVisitors'
  },
  Desktop: {
    average: 'desktopLocaleAverage',
    visitors: 'desktopLocaleVisitors'
  },
  Tablet: {
    average: 'tabletLocaleAverage',
    visitors: 'tabletLocaleVisitors'
  },
  Unknown: {
    average: 'unknownLocaleAverage',
    visitors: 'unknownLocaleVisitors'
  }
};

class RumTable extends React.PureComponent {
  state = {
    data: [],
    anomalyData: [],
    display: 'Months',
    deviceTypeDisplay: 'All',
    staticColumns: getStaticRumColumns(),
    filtered: [],
    brandValue: [],
    localeValue: [],
    clusterValue: [],
    categoryValue: [],
    parentValue: [],
    priorityValue: [],
    localeFilters: [],
    clusterFilters: [],
    brandFilters: [],
    categoryFilters: [],
    parentFilters: [],
    priorityFilters: [],
    navItems: this.getNavItems(),
    pivots: [],
    startDate: null,
    endDate: null,
    focusedInput: null,
    startRange: null,
    endRange: null,
    rangeLoading: false,
    localeChecked: false,
    isModalOpen: false,
    modalData: null,
  };

  handleViewJsonReport = (data) => {
    this.setState({ 
      modalData: data,
      isModalOpen: true,
    });
  }
  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  getFilters() {
    let filters = [
      {
        value: 'Months',
        text: 'Monthly',
        id: 0
      },
      {
        value: 'Weeks',
        text: 'Weekly',
        id: 1
      }
    ];
    if (this.props.showDaily) {
      filters.push({ value: 'Days', text: 'Daily', id: 2 });
    }
    return filters;
  }

  getDeviceTypeFilters() {
    return [
      {
        value: 'All',
        text: 'All',
        id: 0
      },
      {
        value: 'Mobile',
        text: 'Mobile',
        id: 1
      },
      {
        value: 'Desktop',
        text: 'Desktop',
        id: 2
      },
      {
        value: 'Tablet',
        text: 'Tablet',
        id: 3
      },
      {
        value: 'Unknown',
        text: 'Unknown',
        id: 4
      }
    ];
  }

  getNavItems() {
    if (this.props.prefix === 'adobe-classic') {
      return NavItems;
    } else {
      return NavItems.slice(1, 3);
    }
  }
  getPlatform(prefix) {
    switch (prefix) {
      case 'adobe-classic':
        return 'ADOBE_CLASSIC';
      case 'adobeTemplated':
        return 'ADOBE_TEMPLATED';
      case 'adobeSaas':
        return 'ADOBE_SAAS';
      case 'liberty':
        return 'LIBERTY';  
      case 'ath':
        return 'ATH';
      case 'wpLite':
        return 'WP_LITE';
      default:
        return;
    }
  }
  componentDidMount() {
    if (this.props.location) {
      const initialPath = this.props.location.pathname.split('/')[1];
      let platform = initialPath;
      if (initialPath.includes('-')) {
        platform = initialPath.split('-')[1];
      }
      this.setState({ platform });
    }
    this.props.fetchData();
    // const date = new Date().toISOString().slice(0, 10);
    // const platform = this.getPlatform(this.props.prefix);
    // if (platform) {
    //   this.props.fetchDailyAnomalyReport('rum', platform, date);
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDropDownStates(this.props.data);
      const data = this.props.loading ? [] : [...this.props.data.asMutable()];
      this.setState({ data });
    }
    if (prevProps.anomalyData !== this.props.anomalyData) {
      const anomalyData = this.props.anomalyDataloading
        ? []
        : [...this.props.anomalyData.asMutable()];
      this.setState({ anomalyData });
    }
    if (
      prevProps.dailyReportData !== this.props.dailyReportData &&
      this.props.dailyReportData.length !== 0
    ) {
      const days = [];

      for (
        let day = this.state.startRange.clone();
        day <= this.state.endRange;
        day.add(1, 'day')
      ) {
        days.push(day.format('DD/MM/YYYY'));
      }

      this.getCsvForRangeReport({ Days: days });

      this.setState({
        rangeLoading: false
      });
    }
  }

  getFiltersForType(data, type) {
    const uniqueItems = [...new Set(data.map(i => i[type]))].sort();
    const dropDownItems = uniqueItems.map((item, index) => {
      return {
        key: `${index}-${item}`,
        value: item,
        text: item
      };
    });
    return dropDownItems;
  }

  setDropDownStates(data) {
    const brandFilters = this.getFiltersForType(data, 'Brand');
    const localeFilters = this.getFiltersForType(data, 'Locale');
    const categoryFilters = this.getFiltersForType(data, 'Category');
    const clusterFilters = this.getFiltersForType(data, 'Cluster');
    const parentFilters = this.getFiltersForType(data, 'Parent');
    const priorityFilters = this.getFiltersForType(data, 'Priority');
    this.setState({
      categoryFilters,
      brandFilters,
      localeFilters,
      clusterFilters,
      parentFilters,
      priorityFilters
    });
  }

  getColumnDropdown({ selectText, filtersName, filtersValue, type }) {
    return () => (
      <TableColumnDropDown
        placeholder={selectText}
        options={this.state[filtersName]}
        onChange={this.selectItem}
        value={this.state[filtersValue]}
        type={type}
        valueProperty={filtersValue}
      />
    );
  }

  clearFilters() {
    this.setState({
      filtered: [],
      brandValue: [],
      localeValue: [],
      categoryValue: [],
      clusterValue: [],
      parentValue: [],
      priorityValue: [],
    });
  }

  selectItem = (event, data) => {
    const type = data[`data-type`];
    const valueProperty = data[`data-valueproperty`];

    if (data.value.length > 0) {
      this.setState({
        filtered: this.updateCurrentFilters(
          { id: type, value: data.value },
          type
        ),
        [valueProperty]: data.value
      });
    } else {
      if (event.target.className === 'delete icon') {
        let parentValue = event.target.parentElement.getAttribute('value');
        this.setState({
          filtered: this.state.filtered.filter(el => {
            return el.id !== type && el.value !== data.value;
          }),
          [valueProperty]: this.state[valueProperty].filter(el => {
            return el !== parentValue;
          })
        });
      }
    }
  };

  updateCurrentFilters(data, type) {
    let existingFilter = false;
    let filters = this.state.filtered.map(el => {
      if (el.id === type) {
        el.value = data.value;
        existingFilter = true;
      }
      return el;
    });

    if (existingFilter === false) {
      filters.push(data);
    }

    return filters;
  }

  getDailyReport(startDate, endDate) {
    this.setState(() => ({
      startRange: startDate,
      endRange: endDate,
      rangeLoading: true
    }));

    this.props.fetchDailyReport(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD')
    );
  }

  getColumns() {
    let isAdobeClassic = this.props.prefix === 'adobe-classic';
    const staticColumns = getStaticRumColumns(isAdobeClassic);
    staticColumns[1].Filter = this.getColumnDropdown(rumTableColumnData[0]);
    staticColumns[2].Filter = this.getColumnDropdown(rumTableColumnData[1]);
    staticColumns[3].Filter = this.getColumnDropdown(rumTableColumnData[2]);
    staticColumns[4].Filter = this.getColumnDropdown(rumTableColumnData[3]);
    staticColumns[5].Filter = this.getColumnDropdown(rumTableColumnData[4]);
    staticColumns[6].Filter = this.getColumnDropdown(rumTableColumnData[5]);
    const dynamicColumns = this.getDynamicColumns();
    let columns;
    if(this.props.prefix === 'adobeSaas'){
      columns = [...staticColumns,
        {
          id: 'WorstPages',
          Header: 'Rum Pages',
          accessor: 'WorstPages',
          Cell: row => (
            row.value ? (
              <button
                onClick={() => this.handleViewJsonReport(JSON.parse(row.value))}
                style={{ cursor: 'pointer', color: '#652c90', textDecoration: 'none', background: 'none', border: 'none', fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif' }}
              >
                View Bottom Performing RUM Pages
              </button>
            ) : (
              <span style={{ color: 'rgb(211, 211, 211)', fontSize: '12px'}}> 
              N/A
              </span>
            )
          ),
          headerStyle: { textAlign: 'center' },
          style: { textAlign: 'center', fontSize: '14px', fontWeight: 'normal'},
          filterable: false
        }, ...dynamicColumns];
    }else{
      columns = [...staticColumns, ...dynamicColumns];
    }
    
    return columns;
  }

  getDynamicColumns() {
    const { display, deviceTypeDisplay } = this.state;

    const averageProp = DEVICE_TYPE_MAPPINGS[deviceTypeDisplay].average;
    const visitorsProp = DEVICE_TYPE_MAPPINGS[deviceTypeDisplay].visitors;
    const localeAverageProp =
      DEVICE_TYPE_MAPPINGS_LOCALE[deviceTypeDisplay].average;
    const localeVisitorsProp =
      DEVICE_TYPE_MAPPINGS_LOCALE[deviceTypeDisplay].visitors;
    const months = [...this.props.summaryData[display]].reverse();
    // loops each day, week or month depending on display setting
    return months.map(item => {
      const current = item.id;
      return {
        id: item.date,
        Header: item.date,
        width: 100,
        accessor: d => {
          const row = d[display].find(i => i.id === current);
          const average = row ? row[averageProp] : NOT_AVAILABLE;
          const visitors =
            row && row[visitorsProp] ? row[visitorsProp] : NOT_AVAILABLE;
          const localeAverage = row ? row[localeAverageProp] : NOT_AVAILABLE;
          const localeVisitors =
            row && row[localeVisitorsProp]
              ? row[localeVisitorsProp]
              : NOT_AVAILABLE;
          const itemToShow = this.state.visitorsChecked
            ? this.state.localeChecked
              ? localeVisitors
              : visitors
            : this.state.localeChecked
              ? localeAverage
              : average;
          return average === 0 ? NOT_AVAILABLE : itemToShow;
        },
        aggregate: vals => {
          const numericValues = vals.filter(v => typeof v === typeof 0);
          if (numericValues.length > 0) {
            return _.round(_.mean(numericValues), 2);
          }
          return NOT_AVAILABLE;
        },
        sortMethod: (a, b) => {
          // check if we have N/A in the data, we could use isNaN here but it's a bit flakey, if we get other "text"
          // things in here this sort function will need to be updated.
          if (a === NOT_AVAILABLE) a = 0;
          if (b === NOT_AVAILABLE) b = 0;

          if (a === b) {
            return 0;
          }
          return a > b ? 1 : -1;
        },
        filterable: false,
        // this is a mess as we keep adding new checkboxes
        // it works but needs refactoring
        getProps: (state, rowInfo) => {
          let style = {
            color: NOT_AVAILABLE_COLOUR,
            fontWeight: WEIGHT_NORMAL,
            fontSize: SMALL_TEXT_SIZE,
            textAlign: 'center'
          };
          // brand pivot not set
          if (rowInfo && rowInfo.row && rowInfo.row._original) {
            const row = rowInfo.row._original[display].find(
              i => i.id === current
            );
            if (row) {
              style.color = this.getAverageColour(row[averageProp]);
              if (rowInfo.row[item.date] !== NOT_AVAILABLE) {
                this.setFontLargeAndBold(style);
              }
            }
          } else {
            // brand pivot is set
            if (rowInfo) {
              this.setFontLargeAndBold(style);
              if (this.state.visitorsChecked) {
                style.color = 'black';
                if (rowInfo.row[item.date] === NOT_AVAILABLE) {
                  this.setFontSmallAndNotBold(style);
                }
              } else {
                style.color = this.getAverageColour(rowInfo.row[item.date]);
                if (rowInfo.row[item.date] === NOT_AVAILABLE) {
                  this.setFontSmallAndNotBold(style);
                }
              }
            }
          }
          return { style };
        }
      };
    });
  }

  setFontLargeAndBold(style) {
    style.fontWeight = WEIGHT_BOLD;
    style.fontSize = LARGE_TEXT_SIZE;
  }

  setFontSmallAndNotBold(style) {
    style.fontWeight = WEIGHT_NORMAL;
    style.fontSize = SMALL_TEXT_SIZE;
  }

  onFilterChange = display => {
    this.setState({ display });
    this.setState({ localeChecked: false });
  };

  onDeviceTypeFilterChange = deviceTypeDisplay => {
    this.setState({ deviceTypeDisplay });
    this.setState({ localeChecked: false });
  };

  handlePivotChecked(pivot) {
    const oldPivots = this.state.pivots;
    const index = oldPivots.indexOf(pivot);

    const pivots =
      index !== -1
        ? [...oldPivots.slice(0, index), ...oldPivots.slice(index + 1)]
        : [...oldPivots, pivot];

    this.setState({ pivots });
  }

  handleVisitorsChecked = () => {
    !this.state.visitorsChecked
      ? this.setState({ visitorsChecked: true })
      : this.setState({ visitorsChecked: false });
  };
  handelLocaleChecked = () => {
    !this.state.localeChecked
      ? this.setState({ localeChecked: true })
      : this.setState({ localeChecked: false });
  };

  getAverageColour(average) {
    let averageColour = NOT_AVAILABLE_COLOUR;
    if (isRed(average)) {
      averageColour = RED;
    }
    if (isAmber(average)) {
      averageColour = AMBER;
    }
    if (isGreen(average)) {
      averageColour = GREEN;
    }
    if (isTrueGreen(average)) {
      averageColour = TRUEGREEN;
    }
    return averageColour;
  }

  clickGenesisSitesCheckbox = (event, checkBox) => {
    if (checkBox.checked) {
      const data = this.state.data.filter(item => {
        return genesisDomains.includes(item.Domain);
      });
      this.setState({ data });
    } else {
      const data = this.props.data.asMutable();
      this.setState({ data });
    }
  };

  getCsvHeaders(summaryData, deviceFilter, display) {
    const staticHeaders = getRumAllPagesHeaders();
    const csvData = {
      headers: staticHeaders.join(',').concat(','),
      dateHeaders: '\n'.concat(','.repeat(staticHeaders.length))
    };

    // construct dynamic device headers with correct spacing
    csvData.headers = deviceFilter.reduce(
      (acc, device) =>
        acc +
        `${display}-${device}-Average${','.repeat(
          summaryData[display].length
        )}`,
      csvData.headers
    );

    // construct dateHeaders
    csvData.dateHeaders += summaryData[display]
      .asMutable()
      .reverse()
      .reduce((acc, timePeriod) => acc + `${timePeriod.id},`, '')
      .repeat(deviceFilter.length);
    return csvData;
  }

  prepareDataForCsv(summaryData, deviceFilter, display, rawData) {
    const data = _.sortBy(rawData, ['Brand', 'Locale']);
    let csvOptions = getCsvOptionsRumAllPages(display);

    // format data for csv rows
    // const flatData = data.map(brandData => {
    //   let avrgObj = {
    //     average: '',
    //     mobileAverage: '',
    //     desktopAverage: '',
    //     tabletAverage: '',
    //     unknownAverage: ''
    //   };

    //   for (const device of deviceFilter) {
    //     const deviceAverageArr = brandData[display]
    //       .asMutable()
    //       .reverse()
    //       .map(avrgs => {
    //         return avrgs[DEVICE_TYPE_MAPPINGS[device].average];
    //       });

    //     // add zeros if device data is not long enough
    //     const diff = summaryData[display].length - deviceAverageArr.length;
    //     if (diff > 0) {
    //       const padding = Array.from({ length: diff }, () => 0);
    //       avrgObj[DEVICE_TYPE_MAPPINGS[device].average] = [
    //         ...deviceAverageArr,
    //         ...padding
    //       ];
    //     } else {
    //       avrgObj[DEVICE_TYPE_MAPPINGS[device].average] = deviceAverageArr;
    //     }
    //   }

    //   // copy brandData object and assign the new avrgObj to relevant time period
    //   const newBrandData = { ...brandData.asMutable(), [display]: avrgObj };

    //   return newBrandData;
    // });

    const flatData = data.map(brandData => {
      let avrgObj = {
        average: '',
        mobileAverage: '',
        desktopAverage: '',
        tabletAverage: '',
        unknownAverage: ''
      };

      for (const device of deviceFilter) {
        let deviceAverageArr = Array(summaryData[display].length).fill(0);
  
        for (let i = 0; i < summaryData[display].length; i++) {
          const summaryDate = summaryData[display][i].id;
          let matchingEntry = brandData[display].find(date => date.id === summaryDate);
  
          if (matchingEntry) {
            deviceAverageArr[i] = matchingEntry[DEVICE_TYPE_MAPPINGS[device].average] || 0;
          }
        }
        avrgObj[DEVICE_TYPE_MAPPINGS[device].average] = deviceAverageArr.reverse();
      }

      // copy brandData object and assign the new avrgObj to relevant time period
      const newBrandData = { ...brandData.asMutable(), [display]: avrgObj };

      return newBrandData;
    });

    return { flatData, csvOptions };
  }

  async generateCsv() {
    const { summaryData, data } = this.props;
    const { display } = this.state;
    const deviceFilter = Object.keys(DEVICE_TYPE_MAPPINGS);

    const { flatData, csvOptions } = this.prepareDataForCsv(
      summaryData,
      deviceFilter,
      display,
      data
    );
    const csvData = this.getCsvHeaders(summaryData, deviceFilter, display);

    let csv = await json2csvAsync(flatData, csvOptions);

    csv = csv
      .replace(/("\[)/gm, '')
      .replace(/(\]")/gm, '')
      .replace(/(null)/gm, '0');

    return csvData.headers + csvData.dateHeaders + '\n' + csv;
  }

  getCsvHeadersDailyReport(dailyRange, deviceFilter, display) {
    const staticHeaders = getRumAllPagesHeaders();
    const csvData = {
      headers: staticHeaders.join(',').concat(','),
      dateHeaders: '\n'.concat(','.repeat(staticHeaders.length))
    };

    // construct dynamic device headers with correct spacing
    csvData.headers = deviceFilter.reduce(
      (acc, device) =>
        acc +
        `${display}-${device}-Average${','.repeat(dailyRange[display].length)}`,
      csvData.headers
    );

    csvData.dateHeaders += dailyRange.Days.reverse()
      .reduce((acc, timePeriod) => acc + `${timePeriod},`, '')
      .repeat(deviceFilter.length);
    return csvData;
  }

  async generateCsvForRangeReport(dailyRange, data) {
    const { display } = this.state;
    const deviceFilter = Object.keys(DEVICE_TYPE_MAPPINGS);

    const { flatData, csvOptions } = this.prepareDataForCsv(
      dailyRange,
      deviceFilter,
      display,
      data
    );

    const csvData = this.getCsvHeadersDailyReport(
      dailyRange,
      deviceFilter,
      display
    );

    let csv = await json2csvAsync(flatData, csvOptions);

    csv = csv
      .replace(/("\[)/gm, '')
      .replace(/(\]")/gm, '')
      .replace(/(null)/gm, '0');

    return csvData.headers + csvData.dateHeaders + '\n' + csv;
  }

  async getCsvForRangeReport(dailyRange) {
    const csv = await this.generateCsvForRangeReport(
      dailyRange,
      this.props.dailyReportData
    );

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `${this.state.platform}-${this.state.display}-RUM.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }

  async getCsv() {
    const csv = await this.generateCsv();

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `${this.state.platform}-${this.state.display}-RUM.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }

  getTrProps(state, rowInfo) {
    let appId;

    appId =
      _.get(rowInfo, 'subRows[0]._original.AppId') ||
      _.get(rowInfo, 'row._original.AppId');

    if (!appId) {
      return {};
    }

    return {
      'data-app-id': appId,
      title: `App Id: ${appId}`
    };
  }

  handelTierChanged = (e, { value }) => {
    if (value !== 'all') {
      let tierData = [];
      if (Number(value) === 2) {
        tierData = this.props.data.asMutable().filter(item => {
          if (item.Tier !== 1) {
            return item;
          }
        });
      } else {
        tierData = this.props.data.asMutable().filter(item => {
          if (item.Tier === Number(value)) {
            return item;
          }
        });
      }
      this.setState({ data: tierData });
    } else {
      const data = this.props.data.asMutable();
      this.setState({ data });
    }
  };
  getTiers() {
    let tierSet = new Set();
    this.props.data.forEach(item => {
      if (item.Tier !== undefined) {
        tierSet.add(item.Tier);
      }
    });
    let tierArray = [];
    tierArray.push({ text: 'All', value: 'all', key: 'all' });
    for (let item of tierSet) {
      let tierJson = {
        text: String(item),
        key: String(item),
        value: String(item)
      };
      tierArray.push(tierJson);
    }
    tierArray.sort((a, b) => a.value - b.value);
    return tierArray;
  }
  render() {
    const columns = this.props.loading
      ? getStaticRumColumns()
      : this.getColumns();
    // const data = this.props.loading ? [] : [...this.props.data];
    const anomalyData = this.props.anomalyDataloading
      ? []
      : [...this.props.anomalyData];
    const tierOptions = this.props.loading ? [] : this.getTiers();
    const startDate = this.props.summaryData.StartDate;
    const endDate = this.props.summaryData.EndDate;
    const isAdobeClassic = this.props.prefix === 'adobe-classic';
    const isCxxSites = this.props.prefix === 'cxx';
    const showAnomaly =
      this.getPlatform(this.props.prefix) && anomalyData.length ? true : false;
    return (
      <span>
        <Navigation activeItem={this.state.platform} items={rumTableNavItems} />
        {!isCxxSites && (
          <Navigation activeItem="rumAll" items={this.state.navItems} />
        )}

        <div style={{ padding: '20px' }}>
          <div className="header-grid">
            <h1>Real User Monitoring (RUM) data - All pages</h1>
            <span />
          </div>
          {showAnomaly && (
            <div>
              <Header as="h3">Today's Anomaly Data </Header>
              <List
                prefix={this.props.prefix}
                anomalyData={anomalyData}
              ></List>{' '}
            </div>
          )}

          <RumSummaryTable
            summaryData={this.props.summaryData}
            loading={this.props.loading}
            keyMarkets={this.props.keyMarkets}
            showDaily={this.props.showDaily}
            platform={this.state.platform}
          />
          <Divider />
          <ReactTable
            loading={this.props.loading}
            pageSize={this.state.data.length}
            showPagination={false}
            columns={columns}
            data={this.state.data}
            className="-striped -highlight rum-table"
            filterable
            filtered={this.state.filtered}
            pivotBy={this.state.pivots}
            defaultSorted={[{ id: 'Brand' }, { id: 'Locale' }]}
            getTrProps={this.getTrProps}
            // style={{
            //   maxHeight: '90vh' // This will force the table body to overflow and scroll, since there is not enough room
            // }}
            // minRows={10}
          >
            {(state, makeTable) => {
              return (
                <div>
                  <div className="header-grid">
                    <h3>
                      All apps{' '}
                      {!this.props.loading && ' ' + startDate + '-' + endDate}
                    </h3>
                    {isAdobeClassic && (
                      <span>
                        <Checkbox
                          label="CXX set only"
                          onClick={this.clickGenesisSitesCheckbox}
                        />
                      </span>
                    )}
                  </div>
                  <Divider />
                  <div className="header-grid">
                    <h3>
                      {state.sortedData.length} sites, Average Full Page Load
                      speeds (80th Percentile)
                    </h3>

                    <span>
                      <Button
                        basic
                        disabled={this.props.loading}
                        loading={this.props.loading}
                        icon="download"
                        content="Download CSV"
                        onClick={this.getCsv.bind(this)}
                      />
                    </span>
                  </div>
                  <Filter
                    title="Device Type:"
                    data={this.getDeviceTypeFilters()}
                    value={this.state.deviceTypeDisplay}
                    onChange={this.onDeviceTypeFilterChange}
                  />
                  <Filter
                    title="Time Period:"
                    data={this.getFilters()}
                    value={this.state.display}
                    onChange={this.onFilterChange}
                  />
                  <div>
                    {this.state.display === 'Days' &&
                    this.props.prefix === 'adobe-classic' ? (
                        <DateRange
                          onDownloadClick={this.getDailyReport.bind(this)}
                          loading={this.state.rangeLoading}
                        />
                      ) : null}
                  </div>
                  <div className="header-cb">
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Brand')}
                        defaultChecked={this.state.pivots.includes('Brand')}
                      />
                      Brand Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Locale')}
                        defaultChecked={this.state.pivots.includes('Locale')}
                      />
                      Market Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Category')}
                        defaultChecked={this.state.pivots.includes('Category')}
                      />
                      Category Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={this.handleVisitorsChecked}
                        defaultChecked={this.state.visitorsChecked}
                      />
                      Show Visitors
                    </label>
                    <label>
                      Tier
                      <Dropdown
                        style={{ marginLeft: 7 }}
                        placeholder="All"
                        options={tierOptions}
                        selection
                        compact
                        onChange={this.handelTierChanged}
                      />
                    </label>
                    {this.state.display === 'Days' &&
                    this.props.prefix === 'adobe-classic' &&
                    this.state.deviceTypeDisplay === 'All' ? (
                        <label>
                          <input
                            style={{ marginRight: 10 }}
                            type="checkbox"
                            onChange={this.handelLocaleChecked}
                            defaultChecked={this.state.localeChecked}
                          />
                          Locale Specific
                        </label>
                      ) : null}
                  </div>
                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
          
          <JsonModal 
            jsonData={this.state.modalData} 
            isOpen={this.state.isModalOpen} 
            onClose={this.closeModal} 
          />

        </div>
      </span>
    );
  }
}

RumTable.propTypes = {
  prefix: PropTypes.string,
  data: PropTypes.array,
  summaryData: PropTypes.object,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  keyMarkets: PropTypes.array,
  showDaily: PropTypes.bool,
  location: PropTypes.object,
  fetchDailyReport: PropTypes.func,
  dailyReportData: PropTypes.array,
  anomalyData: PropTypes.array,
  // fetchDailyAnomalyReport: PropTypes.func
};

RumTable.defaultProps = {
  data: [],
  anomalyData: [],
  summaryData: {},
  keyMarkets: []
};

export default RumTable;
