const isTrueGreen = (average) => {
  return average < 2.5 && average > 0;
};

const isGreen = (average) => {
  return average < 5 && average >= 2.5;
};
const isAmber = (average) => {
  return average >=5 && average < 10;
};

const isRed = (average) => {
  return average >= 10;
};

export {
  isTrueGreen,
  isGreen, 
  isRed,
  isAmber,
}
