import ActionNames from './names';

function ActionCreator(actionKey, actionData = {}) {
  return Object.assign({}, actionData, { type: actionKey });
}

export default {
  requestBrandRumDataPageLoad() {
    return ActionCreator(ActionNames.REQUEST_BRAND_RUM_DATA_PAGE_LOAD);
  },
  receiveBrandRumDataPageLoad(data, brandName) {
    return ActionCreator(ActionNames.RECEIVE_BRAND_RUM_DATA_PAGE_LOAD, {
      data,
      brandName
    });
  },
  failureBrandRumDataPageLoad(error) {
    return ActionCreator(ActionNames.FAILURE_BRAND_RUM_DATA_PAGE_LOAD, {
      error
    });
  },

  requestD2Apps() {
    return ActionCreator(ActionNames.REQUEST_D2_APPS);
  },
  recieveD2Apps(data) {
    return ActionCreator(ActionNames.RECEIVE_D2_APPS, { data });
  },
  failureD2Apps(error) {
    return ActionCreator(ActionNames.FAILURE_D2_APPS, { error });
  },

  requestRumDataDevices() {
    return ActionCreator(ActionNames.REQUEST_RUM_DATA_DEVICES);
  },
  receiveRumDataDevices(data, brandName) {
    return ActionCreator(ActionNames.RECEIVE_RUM_DATA_DEVICES, {
      data,
      brandName
    });
  },
  failureRumDataDevices(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_DATA_DEVICES, { error });
  },

  requestRumDataPageLoad() {
    return ActionCreator(ActionNames.REQUEST_RUM_DATA_PAGE_LOAD);
  },
  receiveRumDataPageLoad(data, brandName) {
    return ActionCreator(ActionNames.RECEIVE_RUM_DATA_PAGE_LOAD, {
      data,
      brandName
    });
  },
  failureRumDataPageLoad(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_DATA_PAGE_LOAD, { error });
  },

  requestRumCategories() {
    return ActionCreator(ActionNames.REQUEST_RUM_CATEGORIES);
  },
  receiveRumCategories(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_CATEGORIES, { data, charts });
  },
  failureRumCategories(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_CATEGORIES, { error });
  },

  requestRumSubCategories() {
    return ActionCreator(ActionNames.REQUEST_RUM_SUBCATEGORIES);
  },
  receiveRumSubCategories(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_SUBCATEGORIES, {
      data,
      charts
    });
  },
  failureRumSubCategories(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_SUBCATEGORIES, { error });
  },

  requestRumBrands() {
    return ActionCreator(ActionNames.REQUEST_RUM_BRANDS);
  },
  receiveRumBrands(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_BRANDS, { data, charts });
  },
  failureRumBrands(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_BRANDS, { error });
  },

  setBrand(data) {
    return ActionCreator(ActionNames.SET_BRAND, { data });
  },

  clearBrand() {
    return ActionCreator(ActionNames.CLEAR_BRAND);
  },

  initLogin() {
    return ActionCreator(ActionNames.INIT_LOGIN);
  },
  setLogin(data) {
    return ActionCreator(ActionNames.SET_LOGIN, { data });
  },
  errorLogin(data) {
    return ActionCreator(ActionNames.ERROR_LOGIN, { data });
  },
  clearLogin() {
    return ActionCreator(ActionNames.CLEAR_LOGIN);
  },



  requestRumClusters() {
    return ActionCreator(ActionNames.REQUEST_RUM_CLUSTERS);
  },
  receiveRumClusters(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_CLUSTERS, { data, charts });
  },
  failureRumClusters(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_CLUSTERS, { error });
  },

  requestRumMCOs() {
    return ActionCreator(ActionNames.REQUEST_RUM_MCOS);
  },
  receiveRumMCOs(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_MCOS, { data, charts });
  },
  failureRumMCOs(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_MCOS, { error });
  },

  requestRumCountries() {
    return ActionCreator(ActionNames.REQUEST_RUM_COUNTRIES);
  },
  receiveRumCountries(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_COUNTRIES, { data, charts });
  },
  failureRumCountries(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_COUNTRIES, { error });
  },

  requestRumAgencies() {
    return ActionCreator(ActionNames.REQUEST_RUM_AGENCIES);
  },
  receiveRumAgencies(data, charts) {
    return ActionCreator(ActionNames.RECEIVE_RUM_AGENCIES, { data, charts });
  },
  failureRumAgencies(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_AGENCIES, { error });
  },

  requestApps() {
    return ActionCreator(ActionNames.REQUEST_APPS);
  },
  receiveApps(ids, data) {
    return ActionCreator(ActionNames.RECEIVE_APPS, { ids, data });
  },
  failureApps(error) {
    return ActionCreator(ActionNames.FAILURE_APPS, { error });
  },

  requestChart(appId) {
    return ActionCreator(ActionNames.REQUEST_CHART, { appId });
  },
  receiveChart(appId, data) {
    return ActionCreator(ActionNames.RECEIVE_CHART, { appId, data });
  },
  failureChart(appId, error) {
    return ActionCreator(ActionNames.FAILURE_CHART, { appId, error });
  },

  requestBrandMetadata() {
    return ActionCreator(ActionNames.REQUEST_BRAND_METADATA);
  },
  receiveBrandMetadata(data) {
    return ActionCreator(ActionNames.RECEIVE_BRAND_METADATA, { data });
  },
  failureBrandMetadata(error) {
    return ActionCreator(ActionNames.FAILURE_BRAND_METADATA, { error });
  },

  requestAppInstanceMetadata() {
    return ActionCreator(ActionNames.REQUEST_APP_INSTANCE_METADATA);
  },
  receiveAppInstanceMetadata(data) {
    return ActionCreator(ActionNames.RECEIVE_APP_INSTANCE_METADATA, { data });
  },
  failureAppInstanceMetadata(error) {
    return ActionCreator(ActionNames.FAILURE_APP_INSTANCE_METADATA, { error });
  },

  requestBrandAdoptionData() {
    return ActionCreator(ActionNames.REQUEST_BRAND_ADOPTION_DATA);
  },
  receiveBrandAdoptionData(data) {
    return ActionCreator(ActionNames.RECEIVE_BRAND_ADOPTION_DATA, { data });
  },
  failureBrandAdoptionData(error) {
    return ActionCreator(ActionNames.FAILURE_BRAND_ADOPTION_DATA, { error });
  },

  requestAppMetadata() {
    return ActionCreator(ActionNames.REQUEST_APP_METADATA);
  },
  receiveAppMetadata(data) {
    return ActionCreator(ActionNames.RECEIVE_APP_METADATA, { data });
  },
  failureAppMetadata(error) {
    return ActionCreator(ActionNames.FAILURE_APP_METADATA, { error });
  },

  requestDailyReport() {
    return ActionCreator(ActionNames.REQUEST_DAILY_REPORT);
  },
  receiveDailyReport(data) {
    return ActionCreator(ActionNames.RECEIVE_DAILY_REPORT, { data });
  },
  failureDailyReport(error) {
    return ActionCreator(ActionNames.FAILURE_DAILY_REPORT, { error });
  },

  requestRumLeaderboard() {
    return ActionCreator(ActionNames.REQUEST_RUM_LEADERBOARD);
  },
  receiveRumLeaderboard(data) {
    return ActionCreator(ActionNames.RECEIVE_RUM_LEADERBOARD, { data });
  },
  failureRumLeaderboard(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_LEADERBOARD, { error });
  },

  requestAppMetadataKeyPages() {
    return ActionCreator(ActionNames.REQUEST_APP_METADATA_KEY_PAGES);
  },
  receiveAppMetadataKeyPages(data) {
    return ActionCreator(ActionNames.RECEIVE_APP_METADATA_KEY_PAGES, { data });
  },
  failureAppMetadataKeyPages(error) {
    return ActionCreator(ActionNames.FAILURE_APP_METADATA_KEY_PAGES, { error });
  },

  requestAppMetadataPerceivedLoad() {
    return ActionCreator(ActionNames.REQUEST_APP_METADATA_PERCEIVED_LOAD);
  },
  receiveAppMetadataPerceivedLoad(data) {
    return ActionCreator(ActionNames.RECEIVE_APP_METADATA_PERCEIVED_LOAD, {
      data
    });
  },
  failureAppMetadataPerceivedLoad(error) {
    return ActionCreator(ActionNames.FAILURE_APP_METADATA_PERCEIVED_LOAD, {
      error
    });
  },

  requestChartsAgencies(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_AGENCIES, { name });
  },
  receiveChartsAgencies(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_AGENCIES, { name, data });
  },
  failureChartsAgencies(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_AGENCIES, { error });
  },

  requestChartsCategories(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_CATEGORIES, { name });
  },
  receiveChartsCategories(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_CATEGORIES, { name, data });
  },
  failureChartsCategories(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_CATEGORIES, { error });
  },

  requestChartsSubCategories(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_SUBCATEGORIES, { name });
  },
  receiveChartsSubCategories(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_SUBCATEGORIES, {
      name,
      data
    });
  },
  failureChartsSubCategories(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_SUBCATEGORIES, { error });
  },

  requestChartsBrands(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_BRANDS, { name });
  },
  receiveChartsBrands(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_BRANDS, { name, data });
  },
  failureChartsBrands(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_BRANDS, { error });
  },

  requestChartsClusters(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_CLUSTERS, { name });
  },
  receiveChartsClusters(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_CLUSTERS, { name, data });
  },
  failureChartsClusters(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_CLUSTERS, { error });
  },

  requestChartsMCOs(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_MCOS, { name });
  },
  receiveChartsMCOs(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_MCOS, { name, data });
  },
  failureChartsMCOs(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_MCOS, { error });
  },

  requestChartsCountries(name) {
    return ActionCreator(ActionNames.REQUEST_CHARTS_COUNTRIES, { name });
  },
  receiveChartsCountries(name, data) {
    return ActionCreator(ActionNames.RECEIVE_CHARTS_COUNTRIES, { name, data });
  },
  failureChartsCountries(error) {
    return ActionCreator(ActionNames.FAILURE_CHARTS_COUNTRIES, { error });
  },

  requestAllSites() {
    return ActionCreator(ActionNames.REQUEST_SITES);
  },
  receiveAllSites(data) {
    return ActionCreator(ActionNames.RECEIVE_SITES, { data });
  },
  failureAllSite(error) {
    return ActionCreator(ActionNames.FAILURE_SITES, { error });
  },

  requestCurrent() {
    return ActionCreator(ActionNames.REQUEST_CURRENT, {});
  },
  receiveCurrent(data) {
    return ActionCreator(ActionNames.RECEIVE_CURRENT, { data });
  },
  failureCurrent(error) {
    return ActionCreator(ActionNames.FAILURE_CURRENT, { error });
  },

  requestCompetitorKeyPagesData() {
    return ActionCreator(ActionNames.REQUEST_COMPETITOR_KEY_PAGES);
  },
  receiveCompetitorKeyPagesData(data) {
    return ActionCreator(ActionNames.RECEIVE_COMPETITOR_KEY_PAGES, { data });
  },
  failureCompetitorKeyPagesData(error) {
    return ActionCreator(ActionNames.FAILURE_COMPETITOR_KEY_PAGES, { error });
  },

  requestCompetitorKeyPagesSummary() {
    return ActionCreator(ActionNames.REQUEST_COMPETITOR_KEY_PAGES_SUMMARY);
  },
  receiveCompetitorKeyPagesSummary(data) {
    return ActionCreator(ActionNames.RECEIVE_COMPETITOR_KEY_PAGES_SUMMARY, {
      data
    });
  },
  failureCompetitorKeyPagesSummary(error) {
    return ActionCreator(ActionNames.FAILURE_COMPETITOR_KEY_PAGES_SUMMARY, {
      error
    });
  },

  requestSynethicKeyPagesData() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_KEY_PAGES);
  },
  receiveSynethicKeyPagesData(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_KEY_PAGES, { data });
  },
  failureSynethicKeyPagesData(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_KEY_PAGES, { error });
  },

  requestSynethicKeyPagesSummary() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_KEY_PAGES_SUMMARY);
  },
  receiveSynethicKeyPagesSummary(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_KEY_PAGES_SUMMARY, {
      data
    });
  },
  failureSynethicKeyPagesSummary(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_KEY_PAGES_SUMMARY, {
      error
    });
  },

  requestMetrics() {
    return ActionCreator(ActionNames.REQUEST_METRICS);
  },
  receiveMetrics(data) {
    return ActionCreator(ActionNames.RECEIVE_METRICS, {
      data
    });
  },
  failureMetrics(error) {
    return ActionCreator(ActionNames.FAILURE_METRICS, {
      error
    });
  },

  requestSyntheticKeyPagesForPlatformData() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_KEY_PAGES_FOR_PLATFORM);
  },
  receiveSyntheticKeyPagesForPlatformData(data, platform) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM, {
      data,
      platform
    });
  },
  failureSyntheticKeyPagesForPlatformData(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM, {
      error
    });
  },

  requestLighthouseKeyPagesForPlatformData() {
    return ActionCreator(ActionNames.REQUEST_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM);
  },
  receiveLighthouseKeyPagesForPlatformData(data, platform) {
    return ActionCreator(ActionNames.RECEIVE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM, {
      data,
      platform
    });
  },
  failureLighthouseKeyPagesForPlatformData(error) {
    return ActionCreator(ActionNames.FAILURE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM, {
      error
    });
  },

  requestLighthouseSummaryForPlatformData() {
    return ActionCreator(ActionNames.REQUEST_LIGHTHOUSE_SUMMARY_FOR_PLATFORM);
  },
  receiveLighthouseSummaryForPlatformData(data, platform) {
    return ActionCreator(ActionNames.RECEIVE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM, {
      data,
      platform
    });
  },
  failureLighthouseSummaryForPlatformData(error) {
    return ActionCreator(ActionNames.FAILURE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM, {
      error
    });
  },

  requestSyntheticMonthSummaryData() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_MONTH_SUMMARY);
  },
  receiveSyntheticMonthSummaryData(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_MONTH_SUMMARY, {
      data
    });
  },
  failureSyntheticMonthSummaryData(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_MONTH_SUMMARY, {
      error
    });
  },

  requestSyntheticRawDataForUrlAndPlatform() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_RAW_DATA);
  },
  receiveSyntheticRawDataForUrlAndPlatform(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_RAW_DATA, {
      data
    });
  },
  failureSyntheticRawDataForUrlAndPlatform(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_RAW_DATA, {
      error
    });
  },


  requestLighthouseRawDataForUrlAndPlatform() {
    return ActionCreator(ActionNames.REQUEST_LIGHTHOUSE_RAW_DATA);
  },
  receiveLighthouseRawDataForUrlAndPlatform(data) {
    return ActionCreator(ActionNames.RECEIVE_LIGHTHOUSE_RAW_DATA, {
      data
    });
  },
  failureLighthouseRawDataForUrlAndPlatform(error) {
    return ActionCreator(ActionNames.FAILURE_LIGHTHOUSE_RAW_DATA, {
      error
    });
  },

  requestLighthouseSummaryDataForBrandAndLocale() {
    return ActionCreator(ActionNames.REQUEST_LIGHTHOUSE_SUMMARY_DATA);
  },
  receiveLighthouseSummaryDataForBrandAndLocale(data) {
    return ActionCreator(ActionNames.RECEIVE_LIGHTHOUSE_SUMMARY_DATA, {
      data
    });
  },
  failureLighthouseSummaryDataForBrandAndLocale(error) {
    return ActionCreator(ActionNames.FAILURE_LIGHTHOUSE_SUMMARY_DATA, {
      error
    });
  },

  requestExecuteOnDemandLighthouseReport() {
    return ActionCreator(ActionNames.REQUEST_EXECUTE_ONDEMAND_LIGHTHOUSE);
  },
  receiveExecuteOnDemandLighthouseReport(data) {
    return ActionCreator(ActionNames.RECEIVE_EXECUTE_ONDEMAND_LIGHTHOUSE, {
      data
    });
  },
  failureExecuteOnDemandLighthouseReport(error) {
    return ActionCreator(ActionNames.FAILURE_EXECUTE_ONDEMAND_LIGHTHOUSE, {
      error
    });
  },

  requestOnDemandLighthouseReport() {
    return ActionCreator(ActionNames.REQUEST_ONDEMAND_LIGHTHOUSE_REPORT);
  },
  receiveOnDemandLighthouseReport(data) {
    return ActionCreator(ActionNames.RECEIVE_ONDEMAND_LIGHTHOUSE_REPORT, {
      data
    })
  },
  failureOnDemandLighthouseReport(error) {
    return ActionCreator(ActionNames.FAILURE_ONDEMAND_LIGHTHOUSE_REPORT, {
      error
    })
  },


  requestOnDemandRecentReport() {
    return ActionCreator(ActionNames.REQUEST_ONDEMAND_RECENT_REPORT);
  },
  receiveOnDemandRecentReport(data) {
    return ActionCreator(ActionNames.RECEIVE_ONDEMAND_RECENT_REPORT, {
      data
    })
  },
  failureOnDemandRecentReport(error) {
    return ActionCreator(ActionNames.FAILURE_ONDEMAND_RECENT_REPORT, {
      error
    })
  },


  requestOnDemandEmailReport() {
    return ActionCreator(ActionNames.REQUEST_ONDEMAND_EMAIL_REPORT);
  },
  receiveOnDemandEmailReport(data) {
    return ActionCreator(ActionNames.RECEIVE_ONDEMAND_EMAIL_REPORT, {
      data
    })
  },
  failureOnDemandEmailReport(error) {
    return ActionCreator(ActionNames.FAILURE_ONDEMAND_EMAIL_REPORT, {
      error
    })
  },


  requestSyntheticSelfService() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_SELF_SERVICE);
  },
  receiveSyntheticSelfService(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_SELF_SERVICE, {
      data
    });
  },
  failureSyntheticSelfService(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_SELF_SERVICE, {
      error
    });
  },

  requestSyntheticSelfServiceSummary() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_SELF_SERVICE_SUMMARY);
  },
  receiveSyntheticSelfServiceSummary(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_SELF_SERVICE_SUMMARY, {
      data
    });
  },
  failureSyntheticSelfServiceSummary(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_SELF_SERVICE_SUMMARY, {
      error
    });
  },

  requestSyntheticSelfServiceMobile() {
    return ActionCreator(ActionNames.REQUEST_SYNTHETIC_SELF_SERVICE_MOBILE);
  },
  receiveSyntheticSelfServiceMobile(data) {
    return ActionCreator(ActionNames.RECEIVE_SYNTHETIC_SELF_SERVICE_MOBILE, {
      data
    });
  },
  failureSyntheticSelfServiceMobile(error) {
    return ActionCreator(ActionNames.FAILURE_SYNTHETIC_SELF_SERVICE_MOBILE, {
      error
    });
  },

  requestAllMetrics() {
    return ActionCreator(ActionNames.REQUEST_ALL_METRICS);
  },

  receiveAllMetrics(data) {
    return ActionCreator(ActionNames.RECEIVE_ALL_METRICS, { data });
  },

  failureAllMetrics() {
    return ActionCreator(ActionNames.FAILURE_ALL_METRICS);
  },

  requestCxxSiteData() {
    return ActionCreator(ActionNames.REQUEST_CXX_SITE_DATA);
  },
  receiveCxxSiteData(data, summaryData) {
    return ActionCreator(ActionNames.RECEIVE_CXX_SITE_DATA, {
      data,
      summaryData
    });
  },
  failureCxxSiteData(error) {
    return ActionCreator(ActionNames.FAILURE_CXX_SITE_DATA, {
      error
    });
  }
};
