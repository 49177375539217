const actionNames = {
  REQUEST_BRAND_RUM_DATA_PAGE_LOAD: 'REQUEST_BRAND_RUM_DATA_PAGE_LOAD',
  RECEIVE_BRAND_RUM_DATA_PAGE_LOAD: 'RECEIVE_BRAND_RUM_DATA_PAGE_LOAD',
  FAILURE_BRAND_RUM_DATA_PAGE_LOAD: 'FAILURE_BRAND_RUM_DATA_PAGE_LOAD',

  REQUEST_D2_APPS: 'REQUEST_D2_APPS',
  RECEIVE_D2_APPS: 'RECEIVE_D2_APPS',
  FAILURE_D2_APPS: 'FAILURE_D2_APPS',

  REQUEST_RUM_DATA_DEVICES: 'REQUEST_RUM_DATA_DEVICES',
  RECEIVE_RUM_DATA_DEVICES: 'RECEIVE_RUM_DATA_DEVICES',
  FAILURE_RUM_DATA_DEVICES: 'FAILURE_RUM_DATA_DEVICES',

  REQUEST_RUM_DATA_PAGE_LOAD: 'REQUEST_RUM_DATA_PAGE_LOAD',
  RECEIVE_RUM_DATA_PAGE_LOAD: 'RECEIVE_RUM_DATA_PAGE_LOAD',
  FAILURE_RUM_DATA_PAGE_LOAD: 'FAILURE_RUM_DATA_PAGE_LOAD',

  REQUEST_RUM_CATEGORIES: 'REQUEST_RUM_CATEGORIES',
  RECEIVE_RUM_CATEGORIES: 'RECEIVE_RUM_CATEGORIES',
  FAILURE_RUM_CATEGORIES: 'FAILURE_RUM_CATEGORIES',

  REQUEST_RUM_SUBCATEGORIES: 'REQUEST_RUM_SUBCATEGORIES',
  RECEIVE_RUM_SUBCATEGORIES: 'RECEIVE_RUM_SUBCATEGORIES',
  FAILURE_RUM_SUBCATEGORIES: 'FAILURE_RUM_SUBCATEGORIES',

  REQUEST_RUM_BRANDS: 'REQUEST_RUM_BRANDS',
  RECEIVE_RUM_BRANDS: 'RECEIVE_RUM_BRANDS',
  FAILURE_RUM_BRANDS: 'FAILURE_RUM_BRANDS',

  SET_BRAND: 'SET_BRAND',
  CLEAR_BRAND: 'CLEAR_BRAND',

  INIT_LOGIN: 'INIT_LOGIN',
  SET_LOGIN: 'SET_LOGIN',
  ERROR_LOGIN: 'ERROR_LOGIN',
  CLEAR_LOGIN: 'CLEAR_LOGIN',


  REQUEST_RUM_CLUSTERS: 'REQUEST_RUM_CLUSTERS',
  RECEIVE_RUM_CLUSTERS: 'RECEIVE_RUM_CLUSTERS',
  FAILURE_RUM_CLUSTERS: 'FAILURE_RUM_CLUSTERS',

  REQUEST_RUM_MCOS: 'REQUEST_RUM_MCOS',
  RECEIVE_RUM_MCOS: 'RECEIVE_RUM_MCOS',
  FAILURE_RUM_MCOS: 'FAILURE_RUM_MCOS',

  REQUEST_RUM_COUNTRIES: 'REQUEST_RUM_COUNTRIES',
  RECEIVE_RUM_COUNTRIES: 'RECEIVE_RUM_COUNTRIES',
  FAILURE_RUM_COUNTRIES: 'FAILURE_RUM_COUNTRIES',

  REQUEST_RUM_AGENCIES: 'REQUEST_RUM_AGENCIES',
  RECEIVE_RUM_AGENCIES: 'RECEIVE_RUM_AGENCIES',
  FAILURE_RUM_AGENCIES: 'FAILURE_RUM_AGENCIES',

  REQUEST_APPS: 'REQUEST_APPS',
  RECEIVE_APPS: 'RECEIVE_APPS',
  FAILURE_APPS: 'FAILURE_APPS',

  REQUEST_CHART: 'REQUEST_CHART',
  RECEIVE_CHART: 'RECEIVE_CHART',
  FAILURE_CHART: 'FAILURE_CHART',

  REQUEST_BRAND_METADATA: 'REQUEST_BRAND_METADATA',
  RECEIVE_BRAND_METADATA: 'RECEIVE_BRAND_METADATA',
  FAILURE_BRAND_METADATA: 'FAILURE_BRAND_METADATA',

  REQUEST_APP_INSTANCE_METADATA: 'REQUEST_APP_INSTANCE_METADATA',
  RECEIVE_APP_INSTANCE_METADATA: 'RECEIVE_APP_INSTANCE_METADATA',
  FAILURE_APP_INSTANCE_METADATA: 'FAILURE_APP_INSTANCE_METADATA',

  REQUEST_BRAND_ADOPTION_DATA: 'REQUEST_BRAND_ADOPTION_DATA',
  RECEIVE_BRAND_ADOPTION_DATA: 'RECEIVE_BRAND_ADOPTION_DATA',
  FAILURE_BRAND_ADOPTION_DATA: 'FAILURE_BRAND_ADOPTION_DATA',

  REQUEST_APP_METADATA: 'REQUEST_APP_METADATA',
  RECEIVE_APP_METADATA: 'RECEIVE_APP_METADATA',
  FAILURE_APP_METADATA: 'FAILURE_APP_METADATA',

  REQUEST_DAILY_REPORT: 'REQUEST_DAILY_REPORT',
  RECEIVE_DAILY_REPORT: 'RECEIVE_DAILY_REPORT',
  FAILURE_DAILY_REPORT: 'FAILURE_DAILY_REPORT',

  REQUEST_RUM_LEADERBOARD: 'REQUEST_RUM_LEADERBOARD',
  RECEIVE_RUM_LEADERBOARD: 'RECEIVE_RUM_LEADERBOARD',
  FAILURE_RUM_LEADERBOARD: 'FAILURE_RUM_LEADERBOARD',

  REQUEST_APP_METADATA_KEY_PAGES: 'REQUEST_APP_METADATA_KEY_PAGES',
  RECEIVE_APP_METADATA_KEY_PAGES: 'RECEIVE_APP_METADATA_KEY_PAGES',
  FAILURE_APP_METADATA_KEY_PAGES: 'FAILURE_APP_METADATA_KEY_PAGES',

  REQUEST_APP_METADATA_PERCEIVED_LOAD: 'REQUEST_APP_METADATA_PERCEIVED_LOAD',
  RECEIVE_APP_METADATA_PERCEIVED_LOAD: 'RECEIVE_APP_METADATA_PERCEIVED_LOAD',
  FAILURE_APP_METADATA_PERCEIVED_LOAD: 'FAILURE_APP_METADATA_PERCEIVED_LOAD',

  REQUEST_CHARTS_AGENCIES: 'REQUEST_CHARTS_AGENCIES',
  RECEIVE_CHARTS_AGENCIES: 'RECEIVE_CHARTS_AGENCIES',
  FAILURE_CHARTS_AGENCIES: 'FAILURE_CHARTS_AGENCIES',

  REQUEST_CHARTS_BRANDS: 'REQUEST_CHARTS_BRANDS',
  RECEIVE_CHARTS_BRANDS: 'RECEIVE_CHARTS_BRANDS',
  FAILURE_CHARTS_BRANDS: 'FAILURE_CHARTS_BRANDS',

  REQUEST_CHARTS_CATEGORIES: 'REQUEST_CHARTS_CATEGORIES',
  RECEIVE_CHARTS_CATEGORIES: 'RECEIVE_CHARTS_CATEGORIES',
  FAILURE_CHARTS_CATEGORIES: 'FAILURE_CHARTS_CATEGORIES',

  REQUEST_CHARTS_SUBCATEGORIES: 'REQUEST_CHARTS_SUBCATEGORIES',
  RECEIVE_CHARTS_SUBCATEGORIES: 'RECEIVE_CHARTS_SUBCATEGORIES',
  FAILURE_CHARTS_SUBCATEGORIES: 'FAILURE_CHARTS_SUBCATEGORIES',

  REQUEST_CHARTS_CLUSTERS: 'REQUEST_CHARTS_CLUSTERS',
  RECEIVE_CHARTS_CLUSTERS: 'RECEIVE_CHARTS_CLUSTERS',
  FAILURE_CHARTS_CLUSTERS: 'FAILURE_CHARTS_CLUSTERS',

  REQUEST_CHARTS_MCOS: 'REQUEST_CHARTS_MCOS',
  RECEIVE_CHARTS_MCOS: 'RECEIVE_CHARTS_MCOS',
  FAILURE_CHARTS_MCOS: 'FAILURE_CHARTS_MCOS',

  REQUEST_CHARTS_COUNTRIES: 'REQUEST_CHARTS_COUNTRIES',
  RECEIVE_CHARTS_COUNTRIES: 'RECEIVE_CHARTS_COUNTRIES',
  FAILURE_CHARTS_COUNTRIES: 'FAILURE_CHARTS_COUNTRIES',

  REQUEST_SITES: 'REQUEST_SITES',
  RECEIVE_SITES: 'RECEIVE_SITES',
  FAILURE_SITES: 'FAILURE_SITES',

  REQUEST_CURRENT: 'REQUEST_CURRENT',
  RECEIVE_CURRENT: 'RECEIVE_CURRENT',
  FAILURE_CURRENT: 'FAILURE_CURRENT',

  REQUEST_SYNTHETIC_KEY_PAGES: 'REQUEST_SYNTHETIC_KEY_PAGES',
  RECEIVE_SYNTHETIC_KEY_PAGES: 'RECEIVE_SYNTHETIC_KEY_PAGES',
  FAILURE_SYNTHETIC_KEY_PAGES: 'FAILURE_SYNTHETIC_KEY_PAGES',

  REQUEST_SYNTHETIC_KEY_PAGES_SUMMARY: 'REQUEST_SYNTHETIC_KEY_PAGES_SUMMARY',
  RECEIVE_SYNTHETIC_KEY_PAGES_SUMMARY: 'RECEIVE_SYNTHETIC_KEY_PAGES_SUMMARY',
  FAILURE_SYNTHETIC_KEY_PAGES_SUMMARY: 'FAILURE_SYNTHETIC_KEY_PAGES_SUMMARY',

  REQUEST_COMPETITOR_KEY_PAGES: 'REQUESTCOMPETITOR_KEY_PAGES',
  RECEIVE_COMPETITOR_KEY_PAGES: 'RECEIVECOMPETITOR_KEY_PAGES',
  FAILURE_COMPETITOR_KEY_PAGES: 'FAILURECOMPETITOR_KEY_PAGES',

  REQUEST_COMPETITOR_KEY_PAGES_SUMMARY: 'REQUESTCOMPETITOR_KEY_PAGES_SUMMARY',
  RECEIVE_COMPETITOR_KEY_PAGES_SUMMARY: 'RECEIVECOMPETITOR_KEY_PAGES_SUMMARY',
  FAILURE_COMPETITOR_KEY_PAGES_SUMMARY: 'FAILURECOMPETITOR_KEY_PAGES_SUMMARY',

  REQUEST_METRICS: 'REQUEST_METRICS_ADOBE_CLASSIC',
  RECEIVE_METRICS: 'RECEIVE_METRICS_ADOBE_CLASSIC',
  FAILURE_METRICS: 'FAILURE_METRICS_ADOBE_CLASSIC',

  REQUEST_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
    'REQUEST_SYNTHETIC_KEY_PAGES_FOR_PLATFORM',
  RECEIVE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
    'RECEIVE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM',
  FAILURE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM:
    'FAILURE_SYNTHETIC_KEY_PAGES_FOR_PLATFORM',

  REQUEST_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM:
    'REQUEST_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM',
  RECEIVE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM:
    'RECEIVE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM',
  FAILURE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM:
    'FAILURE_LIGHTHOUSE_KEY_PAGES_FOR_PLATFORM',

  REQUEST_LIGHTHOUSE_SUMMARY_FOR_PLATFORM:
    'REQUEST_LIGHTHOUSE_SUMMARY_FOR_PLATFORM',
  RECEIVE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM:
    'RECEIVE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM',
  FAILURE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM:
    'FAILURE_LIGHTHOUSE_SUMMARY_FOR_PLATFORM',

  REQUEST_SYNTHETIC_MONTH_SUMMARY: 'REQUEST_SYNTHETIC_MONTH_SUMMARY',
  RECEIVE_SYNTHETIC_MONTH_SUMMARY: 'RECEIVE_SYNTHETIC_MONTH_SUMMARY',
  FAILURE_SYNTHETIC_MONTH_SUMMARY: 'FAILURE_SYNTHETIC_MONTH_SUMMARY',

  REQUEST_SYNTHETIC_RAW_DATA: 'REQUEST_SYNTHETIC_RAW_DATA',
  RECEIVE_SYNTHETIC_RAW_DATA: 'RECEIVE_SYNTHETIC_RAW_DATA',
  FAILURE_SYNTHETIC_RAW_DATA: 'FAILURE_SYNTHETIC_RAW_DATA',

  REQUEST_LIGHTHOUSE_RAW_DATA: 'REQUEST_LIGHTHOUSE_RAW_DATA',
  RECEIVE_LIGHTHOUSE_RAW_DATA: 'RECEIVE_LIGHTHOUSE_RAW_DATA',
  FAILURE_LIGHTHOUSE_RAW_DATA: 'FAILURE_LIGHTHOUSE_RAW_DATA',

  REQUEST_LIGHTHOUSE_SUMMARY_DATA: 'REQUEST_LIGHTHOUSE_SUMMARY_DATA',
  RECEIVE_LIGHTHOUSE_SUMMARY_DATA: 'RECEIVE_LIGHTHOUSE_SUMMARY_DATA',
  FAILURE_LIGHTHOUSE_SUMMARY_DATA: 'FAILURE_LIGHTHOUSE_SUMMARY_DATA',

  REQUEST_EXECUTE_ONDEMAND_LIGHTHOUSE: 'REQUEST_EXECUTE_ONDEMAND_LIGHTHOUSE',
  RECEIVE_EXECUTE_ONDEMAND_LIGHTHOUSE: 'RECEIVE_EXECUTE_ONDEMAND_LIGHTHOUSE',
  FAILURE_EXECUTE_ONDEMAND_LIGHTHOUSE: 'FAILURE_EXECUTE_ONDEMAND_LIGHTHOUSE',

  REQUEST_ONDEMAND_LIGHTHOUSE_REPORT: 'REQUEST_ONDEMAND_LIGHTHOUSE_REPORT',
  RECEIVE_ONDEMAND_LIGHTHOUSE_REPORT: 'RECEIVE_ONDEMAND_LIGHTHOUSE_REPORT',
  FAILURE_ONDEMAND_LIGHTHOUSE_REPORT: 'FAILURE_ONDEMAND_LIGHTHOUSE_REPORT',

  REQUEST_ONDEMAND_RECENT_REPORT: 'REQUEST_ONDEMAND_RECENT_REPORT',
  RECEIVE_ONDEMAND_RECENT_REPORT: 'RECEIVE_ONDEMAND_RECENT_REPORT',
  FAILURE_ONDEMAND_RECENT_REPORT: 'FAILURE_ONDEMAND_RECENT_REPORT',

  REQUEST_ONDEMAND_EMAIL_REPORT: 'REQUEST_ONDEMAND_EMAIL_REPORT',
  RECEIVE_ONDEMAND_EMAIL_REPORT: 'RECEIVE_ONDEMAND_EMAIL_REPORT',
  FAILURE_ONDEMAND_EMAIL_REPORT: 'FAILURE_ONDEMAND_EMAIL_REPORT',

  REQUEST_SYNTHETIC_SELF_SERVICE: 'REQUEST_SYNTHETIC_SELF_SERVICE',
  RECEIVE_SYNTHETIC_SELF_SERVICE: 'RECEIVE_SYNTHETIC_SELF_SERVICE',
  FAILURE_SYNTHETIC_SELF_SERVICE: 'FAILURE_SYNTHETIC_SELF_SERVICE',

  REQUEST_SYNTHETIC_SELF_SERVICE_SUMMARY:
    'REQUEST_SYNTHETIC_SELF_SERVICE_SUMMARY',
  RECEIVE_SYNTHETIC_SELF_SERVICE_SUMMARY:
    'RECEIVE_SYNTHETIC_SELF_SERVICE_SUMMARY',
  FAILURE_SYNTHETIC_SELF_SERVICE_SUMMARY:
    'FAILURE_SYNTHETIC_SELF_SERVICE_SUMMARY',

  REQUEST_SYNTHETIC_SELF_SERVICE_MOBILE:
    'REQUEST_SYNTHETIC_SELF_SERVICE_MOBILE',
  RECEIVE_SYNTHETIC_SELF_SERVICE_MOBILE:
    'RECEIVE_SYNTHETIC_SELF_SERVICE_MOBILE',
  FAILURE_SYNTHETIC_SELF_SERVICE_MOBILE:
    'FAILURE_SYNTHETIC_SELF_SERVICE_MOBILE',

  REQUEST_ALL_METRICS: 'REQUEST_ALL_METRICS',
  RECEIVE_ALL_METRICS: 'RECEIVE_ALL_METRICS',
  FAILURE_ALL_METRICS: 'FAILURE_ALL_METRICS',

  REQUEST_CXX_SITE_DATA: 'REQUEST_CXX_SITE_DATA',
  RECEIVE_CXX_SITE_DATA: 'RECEIVE_CXX_SITE_DATA',
  FAILURE_CXX_SITE_DATA: 'FAILURE_CXX_SITE_DATA'
};

export default actionNames;
