import React, { Component } from 'react';
import Header from './Header';
import Main from './routers/Main';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';

function getBrowser () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1]
  };
}

function checkBrowserSupport () {
  const { name, version } = getBrowser();
  return name === 'Chrome' && parseFloat(version) > 66.0;
}

class App extends Component {
  constructor () {
    super();
    // if (process.env.REACT_APP_STAGE === 'prod') {
    ReactGA.initialize('UA-41903606-8');
    ReactGA.pageview(window.location.pathname + window.location.search);
    // }
  }
  getSiteTitle () {
    // const siteTitle = 'Unilever Site Speed (Beta)';
    const siteTitle = 'Unilever Site Speed';
    return siteTitle
    // if (process.env.REACT_APP_STAGE === 'prod') {
    //   return siteTitle;
    // } else {
    //   return `[${process.env.REACT_APP_STAGE} site] ${siteTitle}`;
    // }
  }
  render () {
    const supported = checkBrowserSupport();
    const meta = {
      title: this.getSiteTitle(),
      meta: {
        name: {
          version: process.env.REACT_APP_STAGE
        }
      }
    };
    const url = window.location.pathname;
    const match = new RegExp('(.)*(table|data|synthetic)(.)*');
    const matchAdobeTemplated = new RegExp('(.)*(adobe-templated)(.)*');
    const matchWpLite = new RegExp('(.)*(wp-lite)(.)*');
    const matchAdobe = new RegExp('(.)*(adobe-classic)(.)*');
    const matchAdobeSaas = new RegExp('(.)*(adobe-saas)(.)*');
    const matchLiberty = new RegExp('(.)*(liberty)(.)*');
    const matchAth = new RegExp('(.)*(ath)(.)*');
    const matchCompetitor = new RegExp('(.)*(competitor)(.)*');
    const matchSelfService = new RegExp('(.)*(self-service)(.)*');

    match.test(window.location.pathname);

    const prefix = matchWpLite.test(url)
      ? 'wp-lite'
      : matchAdobe.test(url)
        ? 'adobe-classic'
        : matchAdobeSaas.test(url)
          ? 'adobe-saas'
          : matchLiberty.test(url)
            ? 'liberty'
            : matchAdobeTemplated.test(url)
              ? 'adobe-templated'
              : matchAth.test(url)
                ? 'ath'
                : matchCompetitor.test(url)
                  ? 'competitor'
                  : matchSelfService.test(url)
                    ? 'self-service'
                    : '';
    const grid = !match.test(window.location.pathname);
    return (
      <DocumentMeta {...meta}>
        <div className={grid ? 'app' : 'non-grid-app'}>
          <Header prefix={prefix} />
          <div className="span-full">
            {!supported && (
              <div className="ui black message" style={{ margin: '8px' }}>
                <div className="header">
                  Sorry, your browser is not supported
                </div>
                <p>
                  Currently the Site Speed Dashboards only support Chrome above
                  version 66.0. This site may not work as expected in other
                  browsers.
                  <br />
                  <br />
                  Please update your browser or download Chrome{' '}
                  <a href="https://www.google.com/chrome/">here.</a>
                </p>
              </div>
            )}
            <Main />
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

export default App;
