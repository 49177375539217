const items = [
  {
    id: 0,
    name: 'general',
    value: 'data',
    link: 'data'
  },
  {
    id: 1,
    name: 'technical data',
    value: 'technical',
    link: 'technical'
  },
  {
    id: 2,
    name: 'brand adoption',
    value: 'adoption',
    link: 'adoption'
  }
];

export default items;
