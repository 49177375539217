import ActionCreators from './creators';

const API_KEY = process.env.REACT_APP_ANOMALY_DETECTION_API_KEY;
const API_ENDPOINT = process.env.REACT_APP_ANOMALY_DETECTION_API;

const fetchOptions = {
  headers: { 'X-Api-Key': API_KEY },
  method: 'GET',
  mode: 'cors'
};

const actions = {
  fetchDailyAnomalyReport( service,platform,date,) {
    return async dispatch => {
      try {
        dispatch(ActionCreators.requestDailyAnomalyReport());
        const URL = API_ENDPOINT.concat(`/anomalies/${service}/${platform}/${date}`);
        const data = await fetch(URL, fetchOptions);

        let value = await data.json();
        dispatch(ActionCreators.receiveDailyAnomalyReport(value));
      } catch (err) {
        dispatch(ActionCreators.failureDailyAnomalyReport(err));
      }
    };
  }
};

export default actions;
