import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: {},
  error: null
});

function competitorKeyPagesSummaryReducer(state = initialState, action) {
  switch(action.type) {
    case ActionNames.REQUEST_COMPETITOR_KEY_PAGES_SUMMARY:
      return state.merge({ data: {}, loading: true });
    case ActionNames.RECEIVE_COMPETITOR_KEY_PAGES_SUMMARY:
      return state.merge({ data: action.data, loading: false });
    case ActionNames.FAILUR_COMPETITOR_KEY_PAGES_SUMMARY:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default competitorKeyPagesSummaryReducer;
