import React from 'react';
import RumTableTemplate from '../templates/RumTable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions/adobeSaas';
import { AnomalyActions } from '../../store/actions/anomalyDetection';

class AdobeSaasRumTable extends React.PureComponent {
  render() {
    let anomalyData = [];
    if (this.props.anomalyData) {
      anomalyData = this.props.anomalyData;
    }
    const { loading, anomalyDataloading } = this.props;
    return (
      <RumTableTemplate
        {...this.props}
        fetchData={this.props.fetchData}
        loading={loading}
        showDaily={true}
        prefix="adobeSaas"
        anomalyData={anomalyData}
        anomalyDataloading={anomalyDataloading}
        fetchDailyAnomalyReport={this.props.fetchDailyAnomalyReport}
      />
    );
  }
}

AdobeSaasRumTable.propTypes = {
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  summaryData: PropTypes.object,
  anomalyDataloading: PropTypes.bool,
  anomalyData: PropTypes.array,
  fetchDailyAnomalyReport: PropTypes.func
};

function mapStateToProps(state) {
  return {
    data: state.adobeSaas.rumData.data,
    loading: state.adobeSaas.rumData.loading,
    summaryData: state.adobeSaas.rumData.summaryData,
    anomalyDataloading: state.anomalyDetection.anomalyDailyReport.loading,
    anomalyData: state.anomalyDetection.anomalyDailyReport.anomalyData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData() {
      return dispatch(Actions.fetchRumData());
    },
    fetchDailyAnomalyReport(service, platform, date) {
      return dispatch(
        AnomalyActions.fetchDailyAnomalyReport(service, platform, date)
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdobeSaasRumTable);
