import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Filter from '../components/Filter';
import Navigation from '../components/Navigation';
import { Actions as AdobeSaasActions } from '../store/actions/adobeSaas';
import { Actions as LibertyActions } from '../store/actions/liberty';
import { Actions as ATHActions } from '../store/actions/ath';
import { Actions as WPLiteActions } from '../store/actions/wpLite';
import ReactTable from 'react-table';
import {
  RED,
  GREEN,
  TRUEGREEN,
  AMBER,
  NOT_AVAILABLE_COLOUR,
  LARGE_TEXT_SIZE,
  WEIGHT_BOLD
} from '../helpers/constants';
import { isGreen, isAmber, isRed, isTrueGreen } from '../helpers/averageRUM';
import { rumTableNavItems } from '../helpers/platformNavItems';

const PLATFORM_NAMES = {
  ADOBE_SAAS: { KEY: 'adobeSaas', NAME: 'Adobe Saas' },
  ATH: { KEY: 'ath', NAME: 'ATH' },
  LIBERTY: { KEY: 'liberty', NAME: 'Liberty' },
  WP_LITE: { KEY: 'wpLite', NAME: 'Wordpress Lite' }
};
const PLATFORMS = [
  {
    value: 'all',
    text: 'All platforms',
    id: 0
  },
  // {
  //   value: PLATFORM_NAMES.ADOBE_CLASSIC.KEY,
  //   text: PLATFORM_NAMES.ADOBE_CLASSIC.NAME,
  //   id: 1
  // },
  // {
  //   value: PLATFORM_NAMES.ADOBE_TEMPLATED.KEY,
  //   text: PLATFORM_NAMES.ADOBE_TEMPLATED.NAME,
  //   id: 2
  // },
  {
    value: PLATFORM_NAMES.ADOBE_SAAS.KEY,
    text: PLATFORM_NAMES.ADOBE_SAAS.NAME,
    id: 3
  },
  {
    value: PLATFORM_NAMES.WP_LITE.KEY,
    text: PLATFORM_NAMES.WP_LITE.NAME,
    id: 4
  },
  {
    value: PLATFORM_NAMES.ATH.KEY,
    text: PLATFORM_NAMES.ATH.NAME,
    id: 5
  },
  {
    value: PLATFORM_NAMES.LIBERTY.KEY,
    text: PLATFORM_NAMES.LIBERTY.NAME,
    id: 6
  }
];

class RumLeaderboard extends React.PureComponent {
  state = {
    allData: [],
    wpLiteData: [],
    currentData: [],
    adobeSaasData: [],
    athData: [],
    libertyData: [],
    platform: 'all',
    navPlatform: 'leaderboard'
  };
  componentDidMount () {
    this.props.fetchData();
  }
  getAverageColour (average) {
    let averageColour = NOT_AVAILABLE_COLOUR;
    if (isRed(average)) {
      averageColour = RED;
    }
    if (isAmber(average)) {
      averageColour = AMBER;
    }
    if (isGreen(average)) {
      averageColour = GREEN;
    }
    if (isTrueGreen(average)) {
      averageColour = TRUEGREEN;
    }
    return averageColour;
  }
  getColumns () {
    return [
      {
        id: 'Position',
        Header: '#',
        Cell: row => {
          return <h3>{row.index + 1}</h3>;
        },
        width: 50
      },
      { id: 'Brand', Header: 'Brand', accessor: d => d.brand },
      {
        id: 'WeeklyAverage',
        Header: 'Weekly Average',
        accessor: d => d.weeklyAverage,
        getProps: (state, rowInfo) => {
          let style = {
            color: NOT_AVAILABLE_COLOUR,
            fontWeight: WEIGHT_BOLD,
            fontSize: LARGE_TEXT_SIZE,
            textAlign: 'center'
          };
          style.color = rowInfo ? this.getAverageColour(rowInfo.original.weeklyAverage) : NOT_AVAILABLE_COLOUR;
          return { style };
        }
      },
      {
        id: 'Platform',
        Header: 'Platform',
        accessor: d => d.platform
      }
    ];
  }
  getDataForPlatform (platformPrefix, platformName) {
    let platformData = this.props[platformPrefix + 'Loading']
      ? []
      : [...this.props[platformPrefix + 'Data']];
    platformData = platformData.map(currentRow => {
      const row = { ...currentRow };
      row.platform = platformName;
      return row;
    });
    return platformData;
  }
  componentDidUpdate (prevProps) {
    if (
      prevProps.adobeSaasData !== this.props.adobeSaasData ||
      prevProps.athData !== this.props.athData ||
      prevProps.libertyData !== this.props.libertyData ||
      prevProps.wpLiteData !== this.props.wpLiteData
    ) {
      const adobeSaasData = this.getDataForPlatform(
        PLATFORM_NAMES.ADOBE_SAAS.KEY,
        PLATFORM_NAMES.ADOBE_SAAS.NAME
      );
      const athData = this.getDataForPlatform(
        PLATFORM_NAMES.ATH.KEY,
        PLATFORM_NAMES.ATH.NAME
      );
      const libertyData = this.getDataForPlatform(
        PLATFORM_NAMES.LIBERTY.KEY,
        PLATFORM_NAMES.LIBERTY.NAME
      );
      const wpLiteData = this.getDataForPlatform(
        PLATFORM_NAMES.WP_LITE.KEY,
        PLATFORM_NAMES.WP_LITE.NAME
      );

      let allData = [
        ...adobeSaasData,
        ...athData,
        ...libertyData,
        ...wpLiteData
      ];
      allData = allData.sort((a, b) => a.weeklyAverage - b.weeklyAverage);
      const currentData = allData;
      this.setState({
        adobeSaasData,
        athData,
        libertyData,
        wpLiteData,
        allData,
        currentData
      });
    }
  }
  onPlatformFilterChange = platform => {
    const currentData = this.state[platform + 'Data'];
    this.setState({ platform, currentData });
  };
  render () {

    const week =
      !this.state.loading && this.state.adobeSaasData[0]
        ? new Date(this.state.adobeSaasData[0].date).toDateString()
        : '';
    return (
      <span>
        <Navigation
          activeItem={this.state.navPlatform}
          items={rumTableNavItems}
        />

        <div style={{ padding: '20px' }}>
          <h1>Brand RUM Leaderboard - w/c {week}</h1>
          <Filter
            data={PLATFORMS}
            value={this.state.platform}
            onChange={this.onPlatformFilterChange}
          />
          <ReactTable
            loading={
              this.props.wpLiteLoading &&
              this.props.adobeSaasLoading &&
              this.props.athLoading &&
              this.props.libertyLoading
            }
            pageSize={this.state.currentData.length}
            showPagination={false}
            columns={this.getColumns()}
            data={this.state.currentData}
            className="-striped -highlight rum-table"
          />
        </div>
      </span>
    );
  }
}
RumLeaderboard.propTypes = {
  adobeSaasLoading: PropTypes.bool,
  adobeSaasData: PropTypes.array,
  athLoading: PropTypes.bool,
  athData: PropTypes.array,
  libertyLoading: PropTypes.bool,
  libertyData: PropTypes.array,
  wpLiteLoading: PropTypes.bool,
  wpLiteData: PropTypes.array,
  fetchData: PropTypes.func,
};
function mapStateToProps (state) {
  return {
    adobeSaasData: state.adobeSaas.rumLeaderboard.data,
    adobeSaasLoading: state.adobeSaas.rumLeaderboard.loading,
    athData: state.ath.rumLeaderboard.data,
    athLoading: state.ath.rumLeaderboard.loading,
    libertyData: state.liberty.rumLeaderboard.data,
    libertyLoading: state.liberty.rumLeaderboard.loading,
    wpLiteData: state.wpLite.rumLeaderboard.data,
    wpLiteLoading: state.wpLite.rumLeaderboard.loading,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    fetchData () {
      dispatch(AdobeSaasActions.fetchRumLeaderboard());
      dispatch(ATHActions.fetchRumLeaderboard());
      dispatch(LibertyActions.fetchRumLeaderboard());
      dispatch(WPLiteActions.fetchRumLeaderboard());
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RumLeaderboard);
