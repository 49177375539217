import ActionNames from '../../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: false,
  data: {},
  error: null
});

function appMetadataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_APP_METADATA:
      return state.merge({ data: {}, loading: true });
    case ActionNames.RECEIVE_APP_METADATA:
      return state.merge({ data: action.data, loading: false });
    case ActionNames.FAILURE_APP_METADATA:
      return state.merge({ error: action.error, loading: false });
    default:
      return state;
  }
}

export default appMetadataReducer;
