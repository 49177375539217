import React from 'react';
import PropType from 'prop-types';
import ReactTable from 'react-table';
// import { RED, AMBER, GREEN } from '../helpers/averageFMP';
import { RED, AMBER, GREEN, TRUEGREEN } from '../helpers/constants';

const TABLE_STYLES = {
  color: 'black',
  fontSize: '22px',
  fontWeight: 'bolder'
};

const columns = [
  {
    Header: 'Platform',
    accessor: 'platform'
  },
  {
    Header: 'Sites count',
    accessor: 'sitesCount',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      return {
        style: style
      };
    }
  },
  {
    id: 'acceptable',
    Header: 'Acceptable %',
    accessor: d => d.acceptable + '%',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      return {
        style: style
      };
    }
  },
  {
    Header: 'True Green count (<2.5)',
    accessor: 'trueGreenCount',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      style.color = TRUEGREEN;
      return {
        style: style
      };
    }
  },
  {
    Header: 'Green count (2.5-5)',
    accessor: 'greenCount',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      style.color = GREEN;
      return {
        style: style
      };
    }
  },
  {
    Header: 'Amber count (5-10)',
    accessor: 'amberCount',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      style.color = AMBER;
      return {
        style: style
      };
    }
  },
  {
    Header: 'Red count (>10)',
    accessor: 'redCount',
    getProps: () => {
      const style = Object.assign({}, TABLE_STYLES);
      style.color = RED;
      return {
        style: style
      };
    }
  },
  {
    Header: 'Link to dashboard',
    accessor: 'link',
    Cell: row => {
      if (row.value) {
        return <a href={row.value}>Rum dashboards</a>;
      }
      return <span>-</span>;
    }
  },
  {
    Header: 'Sites on platform',
    accessor: 'sites',
    Cell: row => {
      if (row.value) {
        return <a href={row.value}>View sites</a>;
      }
      return <span>-</span>;
    }
  }
];

function RumHomeTable({ loading, data }) {
  return (
    <span>
      <h2>
        Real User Monitoring (RUM) - Full Page Load 80th Percentile (last 30
        days)
      </h2>
      <p>
        Full page load is the time it takes a website page and its (non ajax)
        resources (eg images, css, js etc) to load. The numbers below represent
        the speed of all pages on each site. Rather than taking an average
        value, we use the 80th percentile to filter out outliars. (Eg people on
        mobile in tunnels, who&#39;s experience we can&#39;t control)
      </p>
      <p>
        RUM (Real user monitoring) is analytics data collected from the
        experience our consumers have on our sites, and is collected nightly
        from{' '}
        <a
          href="https://newrelic.com/products/browser-monitoring"
          target="_blank"
          rel="noopener noreferrer"
        >
          New Relic Browser
        </a>
        &nbsp;our chosen site speed analytics tool.
      </p>
      <p>
        The numbers are displayed in a RAG/traffic light system, for Full Page
        Load time:
      </p>
      <ul>
        <li>
          <span style={{ color: GREEN, fontWeight: 'bold' }}>Green</span> rating
          is less then 5 seconds.
        </li>
        <li>
          <span style={{ color: AMBER, fontWeight: 'bold' }}>Amber</span> rating
          between 5 &amp; 10 seconds.
        </li>
        <li>
          <span style={{ color: RED, fontWeight: 'bold' }}>Red</span> rating for
          10+ seconds.
        </li>
      </ul>

      <ReactTable
        loading={loading}
        data={data}
        columns={columns}
        showPagination={false}
        showPageSizeOptions={false}
        pageSize={data.length}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.index === data.length - 1) {
            return {
              style: {
                background: '#F5F5F5',
                fontWeight: 'bolder'
              }
            };
          }
          return {};
        }}
      />
      <br />
      <a href="/all/rum-table">
        View RUM summary by month, week or day by following this link.
      </a>
    </span>
  );
}

RumHomeTable.propTypes = {
  loading: PropType.bool,
  data: PropType.array
};

export default RumHomeTable;
