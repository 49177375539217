import ActionNames from '../../actions/competitor/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  data: [],
  error: null
});

function brandMetaDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_BRAND_META_DATA:
      return state.merge({ loading: true, data: [] });
    case ActionNames.RECEIVE_BRAND_META_DATA:
      return state.merge({ loading: false, data: action.data });
    case ActionNames.FAILURE_BRAND_META_DATA:
      return state.merge({ loading: false, error: action.error, data: [] });
    default:
      return state;
  }
}

export default brandMetaDataReducer;
