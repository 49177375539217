/* eslint-disable indent */
function extractDomain(url) {
    url = url.trim();
    let cleanedUrl = url.replace(/^https?:\/\/(www\.)?/, '');
    if (cleanedUrl.endsWith('/home.html')) {
      const lastSlashIndex = cleanedUrl.lastIndexOf('/', cleanedUrl.length - 1);
      return cleanedUrl.substring(0, lastSlashIndex);
    }
    return cleanedUrl;
  }

  function getLastNonEmptyElement(arr) {
    for (let i = arr.length - 1; i >= 0; i--) {
        let element = JSON.parse(arr[i]);
        if (Object.keys(element.auditList).length > 0) {
            return JSON.stringify(element);
        }
    }
    return JSON.stringify({"status": "Report is empty"});
  }
  
  const downloadJsonReport = (reportData, temp, PageUrl) => {
    PageUrl = extractDomain(PageUrl);
    let jsonStr = getLastNonEmptyElement(reportData);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${temp}-${PageUrl}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export { downloadJsonReport };