import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  pageLoadData: [],
  pageLoadBrandName: '',
  pageLoadLoading: true,
  devicesLoading: true,
  devicesData: []
});

function rumDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_RUM_DATA_PAGE_LOAD:
      return state.merge({
        pageLoadLoading: true
      });
    case ActionNames.RECEIVE_RUM_DATA_PAGE_LOAD:
      return state.merge({
        pageLoadData: action.data,
        pageLoadBrandName: action.brandName,
        pageLoadLoading: false
      });
    case ActionNames.REQUEST_RUM_DATA_DEVICES:
      return state.merge({
        devicesLoading: true
      });
    case ActionNames.RECEIVE_RUM_DATA_DEVICES:
      return state.merge({
        devicesData: action.data,
        devicesLoading: true
      });

    default:
      return state;
  }
}

export default rumDataReducer;