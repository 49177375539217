export const RED = '#FF0000';
export const AMBER = '#FFA500';
export const GREEN = '#63ce7c';
export const TRUEGREEN = '#15792D';
export const NOT_AVAILABLE_COLOUR = '#D3D3D3';
export const NOT_AVAILABLE = 'N/A';
export const SMALL_TEXT_SIZE = '12px';
export const LARGE_TEXT_SIZE = '22px';
export const WEIGHT_NORMAL = 'normal';
export const WEIGHT_BOLD = 'bolder';
