
import ActionNames from '../actions/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  data: [],
  loading: true
});

function d2AppsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_D2_APPS:
      return state.merge({
        loading: true,
      });
    case ActionNames.RECEIVE_D2_APPS:
      return state.merge({
        data: action.data,
        loading: false,
      });
    case ActionNames.FAILURE_D2_APPS: 
      return state.merge({
        loading: false,
        error: action.error,
      });

    default:
      return state;
  }
}

export default d2AppsReducer;