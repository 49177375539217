/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {
  Divider,
  Checkbox,
  Button,
  Dropdown,
  Header,
  Icon
} from 'semantic-ui-react';
import _ from 'lodash';
import { json2csvAsync } from 'json-2-csv';

import metricVals from '../../helpers/metric';
import LighthouseSummaryTable from './LighthouseSummaryTable';
import { getAverageColour, getPageWeightColor, getJSETColor } from '../../helpers/averagePerformance';
import Filter from '../../components/Filter';
import Navigation from '../../components/Navigation';
import { lighthouseItems } from '../../helpers/platformNavItems';
import {
  getNextSummaryMonth,
  getPrevSummaryMonth,
  getNextItemsMonth,
  getPrevItemsMonth,
  getNextSummaryWeek,
  getPrevSummaryWeek,
  getNextItemsWeek,
  getPrevItemsWeek,
  getNextSummaryDay,
  getPrevSummaryDay,
  getNextItemsDay,
  getPrevItemsDay
} from '../../helpers/lighthouseDate';
import { rumTableColumnData } from '../../helpers/rumTableHelpers';
import TableColumnDropDown from '../../components/TableColumnDropDown';
import {
  getCsvOptionsLighthouse,
  getLighthouseHeaders
} from '../../helpers/rumTableHelpers';
import moment from 'moment';
import List from '../../components/List';

let columns = [
  {
    Header: 'Domain',
    accessor: 'Domain',
    filterable: false,
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          fontWeight: '700'
        }
      };
    }
  },
  {
    Header: 'Brand',
    accessor: 'Brand',
    filterAll: true,
    Cell: row => {
      if (!row.groupedByPivot) {
        return <span />;
      }
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Brand));
      } else {
        return rows;
      }
    },
    aggregate: values => values[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  },
  {
    Header: 'Locale',
    accessor: 'Locale',
    Cell: row => {
      if (!row.groupedByPivot) {
        return <span />;
      }
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.Locale));
      } else {
        return rows;
      }
    },
    filterAll: true,
    aggregate: vals => vals[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  },
  {
    Header: 'Category',
    accessor: 'SuperCategory',
    Cell: row => {
      if (!row.groupedByPivot) {
        return <span />;
      }
      return row.value;
    },
    filterMethod: (filter, rows) => {
      if (filter.value) {
        return rows.filter(i => filter.value.includes(i.SuperCategory));
      } else {
        return rows;
      }
    },
    filterAll: true,
    aggregate: vals => vals[0],
    Aggregated: row => {
      if (typeof row.isExpanded === 'object') {
        return (
          <div style={{ height: '100%', fontWeight: '700' }}>{row.value}</div>
        );
      }
      return <div style={{ fontWeight: '700' }}>{row.value}</div>;
    }
  }
];

class LighthouseKeyPagesTable extends Component {
  constructor () {
    super();
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      metric: 'Performance',
      filterDisplay: 'Months',
      Fcp: false,
      filtered: [],
      pivots: ['Domain'],
      categoryFilters: [],
      categoryValue: [],
      localeFilters: [],
      localeValue: [],
      brandFilters: [],
      parentFilters: [],
      priorityFilters: [],
      brandValue: [],
      parentValue: [],
      priorityValue: [],
      cxxData: false,
      data: [],
      mode: 'data3g'
    };
  }

  nextSummaryClicked = async display => {
    // this.setState({
    //   loadingSummaryNew: true
    // })
    let newDate = '';
    switch (display) {
      case 'Months':
        newDate = getNextSummaryMonth(this.state.date);
        break;
      case 'Days':
        newDate = getNextSummaryDay(this.state.date);
        break;
      case 'Weeks':
        newDate = getNextSummaryWeek(this.state.date);
        break;
      default:
        break;
    }
    if (moment(newDate).isBefore(moment())) {
      this.props.fetchSummaryData(newDate, this.state.metric);
      this.setState({
        date: newDate
      });
    } else {
      this.props.fetchSummaryData(moment().format('YYYY-MM-DD'), this.state.metric);
      this.setState({
        date: moment().format('YYYY-MM-DD')
      });
    }
  };

  prevSummaryClicked = display => {
    let newDate = '';
    switch (display) {
      case 'Months':
        newDate = getPrevSummaryMonth(this.state.date);
        break;
      case 'Days':
        newDate = getPrevSummaryDay(this.state.date);
        break;
      case 'Weeks':
        newDate = getPrevSummaryWeek(this.state.date);
        break;
      default:
        break;
    }
    this.props.fetchSummaryData(newDate, this.state.metric);
    this.setState({
      date: newDate
    });
  };

  nextItemsClicked = () => {
    let newDate = '';
    switch (this.state.filterDisplay) {
      case 'Months':
        newDate = getNextItemsMonth(this.state.date);
        break;
      case 'Days':
        newDate = getNextItemsDay(this.state.date);
        break;
      case 'Weeks':
        newDate = getNextItemsWeek(this.state.date);
        break;
      default:
        break;
    }
    if (moment(newDate).isBefore(moment())) {
      this.props.fetchData(newDate, this.state.metric);
      this.setState({
        date: newDate
      });
    } else {
      this.props.fetchData(moment().format('YYYY-MM-DD'), this.state.metric);
      this.setState({
        date: moment().format('YYYY-MM-DD')
      });
    }
  };

  prevItemsClicked = () => {
    let newDate = '';
    switch (this.state.filterDisplay) {
      case 'Months':
        newDate = getPrevItemsMonth(this.state.date);
        break;
      case 'Days':
        newDate = getPrevItemsDay(this.state.date);
        break;
      case 'Weeks':
        newDate = getPrevItemsWeek(this.state.date);
        break;
      default:
        break;
    }
    this.props.fetchData(newDate, this.state.metric);
    this.setState({
      date: newDate
    });
  };

  toggle = () => {
    this.changeData();
    if (this.state.mode === 'data3g') {
      this.setState({
        mode: 'dataCable'
      });
    } else {
      this.setState({
        mode: 'data3g'
      });
    }
  };

  getDynamicColumns () {
    const currentColumns = [...columns];
    const filter = this.state.filterDisplay;
    const daysWeeksOrMonths = this.props.keyPagesData[0][filter];
    // reverse data to show most recent on the left of the table
    currentColumns.push(
      {
        id: 'view runs',
        Header: 'View runs',
        Cell: row => {
          if (row.groupedByPivot) {
            // const brand = encodeURIComponent(row.row.Brand);
            // const locale = encodeURIComponent(row.row.Locale);
            return <span></span>;
            // return this.state.mode === 'data3g' ? (
            //   <a
            //     href={`lighthouse-aggregated-runs-3g?brand=${brand}&locale=${locale}`}
            //     style={{ fontWeight: '700' }}
            //   >
            //       Aggregated Report
            //   </a>
            // ) : (
            //   <a
            //     href={`lighthouse-aggregated-runs-cable?brand=${brand}&locale=${locale}`}
            //   >
            //       Aggregated Report
            //   </a>
            // );
          }
          const url = encodeURIComponent(row.original.sk);
          return this.state.mode === 'data3g' ? (
            <a href={`lighthouse-test-runs-3g?page=${url}`}>Page Report</a>
          ) : (
            <a href={`lighthouse-test-runs-cable?page=${url}`}>Page Report</a>
          );
        },
        filterable: false,
        aggregate: vals => vals[0]
      },
      {
        id: 'pages',
        Header: 'Pages',
        accessor: d => d.sk,
        Cell: row => {
          if (row.groupedByPivot) {
            return <span>{row.value}</span>;
          }
          const { Domain } = row.row;
          const startIndex = row.value.indexOf(Domain) + Domain.length;
          let page = row.value.slice(startIndex);
          page = page.startsWith('/') ? page : `/${page}`;

          return (
            <a href={`${row.value}`} target="_blank" rel="noopener noreferrer">
              {page}
            </a>
          );
        },
        filterable: false,
        aggregate: vals => vals.length
      }
    );
    daysWeeksOrMonths.forEach(timePeriod => {
      currentColumns.push({
        id: timePeriod.Date,
        Header: timePeriod.Header,
        accessor: d => {
          const item = d[filter].find(i => i.Date === timePeriod.Date);
          const metric = item ? item[this.state.metric] : 0;
          return metric === 0 ? '-' : metric;
        },
        Cell: row => {
          if (!isNaN(parseFloat(row.value))) {
            return <span>{_.round(parseFloat(row.value), 2)}</span>;
          }
          return <span>{row.value}</span>;
        },
        getProps: (state, rowInfo) => {
          let colour = 'black';
          let fontWeight = 'bolder';
          let fontSize = '22px';
          if (rowInfo) {
            if(timePeriod.PageWeight || timePeriod.PageWeight == 0){
              colour = getPageWeightColor(rowInfo.row[timePeriod.Date]);
            }else if (timePeriod.JavaScriptExecutionTime || timePeriod.JavaScriptExecutionTime == 0){
              colour = getJSETColor(rowInfo.row[timePeriod.Date]);
            }else {
              colour = getAverageColour(rowInfo.row[timePeriod.Date]);
            }
          }
          return {
            style: {
              color: colour,
              textAlign: 'center',
              fontWeight,
              fontSize
            }
          };
        },
        aggregate: vals => {
          const numericValues = vals.filter(v => !isNaN(v));
          if (numericValues.length > 0) {
            return _.mean(numericValues);
          }
          return '-';
        },
        filterable: false
      });
    });
    return currentColumns;
  }
  getPlatform (prefix) {
    switch (prefix) {
      case 'classic':
        return 'ADOBE_CLASSIC';
      case 'templated':
        return 'ADOBE_TEMPLATED';
      case 'saas':
        return 'ADOBE_SAAS';
      case 'liberty':
        return 'LIBERTY';
      case 'ath':
        return 'ATH';
      case 'lite':
        return 'WP_LITE';
      default:
        return;
    }
  }
  componentDidMount () {
    this.props.fetchData(this.state.date, this.state.metric);
    this.props.fetchSummaryData(this.state.date, this.state.metric);
    // const date = new Date().toISOString().slice(0, 10);
    // const platform = this.getPlatform(this.props.prefix);
    // if (platform) {
    //   this.props.fetchDailyAnomalyReport('lighthouse', platform, date);
    // }
  }

  changeData = () => {
    if (this.state.mode === 'data3g') {
      this.setState({
        data: this.props.keyPagesDataCable
      });
      if (this.state.cxxData) {
        this.setState({
          cxxData: this.props.keyPagesDataCable.filter(item => item.IsCxx)
        });
      }
    } else {
      this.setState({
        data: this.props.keyPagesData
      });
      if (this.state.cxxData) {
        this.setState({
          cxxData: this.props.keyPagesData.filter(item => item.IsCxx)
        });
      }
    }
  };
  componentDidUpdate (prevProps) {
    if (
      prevProps.keyPagesData !== this.props.keyPagesData &&
      this.props.keyPagesDataCable !== prevProps.keyPagesDataCable
    ) {
      // add dropdown filters for brands and locales
      if (this.state.mode === 'data3g') {
        this.setDropDownStates(this.props.keyPagesData);
        this.setState({
          data: this.props.keyPagesData
        });
      } else {
        this.setDropDownStates(this.props.keyPagesDataCable);
        this.setState({
          data: this.props.keyPagesDataCable
        });
      }
    }
  }
  getFilters () {
    return [
      {
        value: 'Months',
        text: 'Monthly',
        id: 0
      },
      {
        value: 'Weeks',
        text: 'Weekly',
        id: 1
      }
      // {
      //   value: 'Days',
      //   text: 'Daily',
      //   id: 2
      // }
    ];
  }

  clickGenesisSitesCheckbox = (event, checkBox) => {
    if (checkBox.checked) {
      const cxxData = this.state.data.filter(item => item.IsCxx);
      this.setState({ cxxData });
    } else {
      this.setState({ cxxData: false });
    }
  };

  onFilterChange = filterDisplay => {
    this.setState({ filterDisplay });
  };

  getFiltersForType (data, type) {
    const uniqueItems = [...new Set(data.map(i => i[type]))].sort();
    const dropDownItems = uniqueItems.map((item, index) => {
      return {
        key: `${index}-${item}`,
        value: item,
        text: item
      };
    });
    return dropDownItems;
  }

  setDropDownStates (data) {
    const localeFilters = this.getFiltersForType(data, 'Locale');
    const brandFilters = this.getFiltersForType(data, 'Brand');
    const categoryFilters = this.getFiltersForType(data, 'SuperCategory');
    const parentFilters = this.getFiltersForType(data, 'Parent');
    const priorityFilters = this.getFiltersForType(data, 'Priority');
    this.setState({ localeFilters, brandFilters, categoryFilters, parentFilters, priorityFilters });
  }

  getColumnDropdown ({ selectText, filtersName, filtersValue, type }) {
    return () => (
      <TableColumnDropDown
        placeholder={selectText}
        options={this.state[filtersName]}
        onChange={this.selectItem}
        value={this.state[filtersValue]}
        type={type}
        valueProperty={filtersValue}
      />
    );
  }

  getColumns () {
    columns[2].Filter = this.getColumnDropdown(rumTableColumnData[1]);
    columns[1].Filter = this.getColumnDropdown(rumTableColumnData[0]);
    columns[3].Filter = this.getColumnDropdown({
      selectText: 'Category',
      filtersName: 'categoryFilters',
      filtersValue: 'categoryValue',
      type: 'SuperCategory'
    });
    const dynamicColumns = this.getDynamicColumns();
    return [...dynamicColumns];
  }

  selectItem = (event, data) => {
    const type = data[`data-type`];
    const valueProperty = data[`data-valueproperty`];

    if (data.value.length > 0) {
      this.setState({
        filtered: this.updateCurrentFilters(
          { id: type, value: data.value },
          type
        ),
        [valueProperty]: data.value
      });
    } else {
      if (event.target.className === 'delete icon') {
        let parentValue = event.target.parentElement.getAttribute('value');
        this.setState({
          filtered: this.state.filtered.filter(el => {
            return el.id !== type && el.value !== data.value;
          }),
          [valueProperty]: this.state[valueProperty].filter(el => {
            return el !== parentValue;
          })
        });
      }
    }
  };

  updateCurrentFilters (data, type) {
    let existingFilter = false;
    let filters = this.state.filtered.map(el => {
      if (el.id === type) {
        el.value = data.value;
        existingFilter = true;
      }
      return el;
    });

    if (existingFilter === false) {
      filters.push(data);
    }

    return filters;
  }

  // getCsvHeaders() {
  //   const csvData = {
  //     headers:
  //       'Domain,Brand,Locale,PageUrl,IsCxx,Tier,SuperCategory,Monthly-Averages,,,,,,Weekly-Averages,,,,,,Daily,,,,,,,',
  //     dateHeaders:
  //       '\n'.concat(','.repeat(7)) +
  //       'Performance,Accessibility,SEO,BestPractices,FCP,ObservedLoad,Performance,Accessibility,SEO,BestPractices,FCP,ObservedLoad,Performance,Accessibility,SEO,BestPractices,FCP,ObservedLoad'
  //   };
  //   return csvData;
  // }

  getCsvHeaders (summaryData, filter, dates) {
    const staticHeaders = getLighthouseHeaders();
    const csvData = {
      headers: staticHeaders.join(',').concat(','),
      dateHeaders: '\n'.concat(','.repeat(staticHeaders.length))
    };

    // get dateHeaders and device headers with correct spacing
    filter.forEach(timePeriod => {
      csvData.headers += `${timePeriod.text}-Averages`;
      let newdata = '';
      if (summaryData != undefined) {
        summaryData[timePeriod.value].asMutable().forEach(data => {
          newdata += `${data.Header}, `;
          csvData.headers += ',';
          dates[timePeriod.value].push(data.Date);
        });
      }
      csvData.dateHeaders += newdata;
    });

    return csvData;
  }

  handleFcpChecked = () => {
    this.setState({
      Fcp: !this.state.Fcp
    });
  };
  prepareDataForCsv (dates, filter, rawData) {
    const keyPagesData = _.sortBy(rawData, ['Brand', 'Locale']);
    let csvOptions = getCsvOptionsLighthouse();

    const flatData = keyPagesData.map(siteData => {
      let avrgObj = { Months: '', Weeks: '', Days: '' };

      for (const timePeriod of filter) {
        const averageArr = siteData[timePeriod.value]
          .asMutable()
          .filter(avrgs => dates[timePeriod.value].includes(avrgs.Date));
        const obj = {};
        for (const item of averageArr) {
          obj[item.Date] = item[this.state.metric];
        }

        const finalArr = dates[timePeriod.value].map(x => {
          const res = !obj[x] ? 0 : obj[x];
          return res;
        });

        avrgObj[timePeriod.value] = finalArr;
      }

      const newSiteData = Object.assign({}, siteData, {
        PageUrl: siteData.sk,
        Months: avrgObj['Months'],
        Weeks: avrgObj['Weeks'],
        Days: avrgObj['Days']
      });
      return newSiteData;
    });

    return { flatData, csvOptions };
  }

  // prepareDataForCsv(dates, filter, rawData) {
  //   console.log(rawData)
  //   const keyPagesData = _.sortBy(rawData, ['Brand', 'Locale']);
  //   let csvOptions = getCsvOptionsSynthetic();
  //   csvOptions.keys = [
  //     'Domain',
  //     'Brand',
  //     'Locale',
  //     'PageUrl',
  //     'IsCxx',
  //     'Tier',
  //     'SuperCategory',
  //     'Months',
  //     'Weeks',
  //     'Days'
  //   ];

  //   const flatData = keyPagesData.map(siteData => {
  //     let avrgObj = { Months: '', Weeks: '', Days: '' };
  //     for (const timePeriod of filter) {
  //       let obj = [];
  //       obj.push(
  //         siteData[timePeriod.value].Performance,
  //         siteData[timePeriod.value].Accessibility,
  //         siteData[timePeriod.value].SEO,
  //         siteData[timePeriod.value].BestPractices,
  //         siteData[timePeriod.value].Fcp,
  //         siteData[timePeriod.value].ObservedLoad
  //       );
  //       avrgObj[timePeriod.value] = obj;
  //     }

  //     const newSiteData = Object.assign({}, siteData, {
  //       Months: avrgObj['Months'],
  //       Weeks: avrgObj['Weeks'],
  //       Days: avrgObj['Days']
  //     });
  //     return newSiteData;
  //   });

  //   return { flatData, csvOptions };
  // }

  getTiers () {
    let tierSet = new Set();
    this.props.keyPagesData.forEach(item => {
      if (item.Tier !== undefined) {
        tierSet.add(item.Tier);
      }
    });
    let tierArray = [];
    tierArray.push({ text: 'All', value: 'all', key: 'all' });
    for (let item of tierSet) {
      let tierJson = {
        text: String(item),
        key: String(item),
        value: String(item)
      };
      tierArray.push(tierJson);
    }
    tierArray.sort((a, b) => a.value - b.value);
    return tierArray;
  }

  async generateCsv () {
    const { keyPagesData, keyPagesDataCable } = this.props;
    const filters = this.getFilters();
    let dates = { Months: [], Weeks: [], Days: [] };

    const csvData = this.getCsvHeaders(keyPagesData[0], filters, dates);
    let csvNetworkData =
      this.state.mode === 'data3g' ? keyPagesData : keyPagesDataCable;
    const { flatData, csvOptions } = this.prepareDataForCsv(
      dates,
      filters,
      csvNetworkData
    );
    let csv = await json2csvAsync(flatData, csvOptions);

    csv = csv
      .replace(/("\[)/gm, '')
      .replace(/(\]")/gm, '')
      .replace(/(null)/gm, '0');

    return csvData.headers + csvData.dateHeaders + '\n' + csv;
  }

  async getCsv () {
    const csv = await this.generateCsv();
    let mode = this.state.mode == "data3g" ? "mobile" : "desktop";

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = `${this.props.prefix}-Lighthouse-${this.state.metric}-${mode}.csv`;
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    link.click();
  }

  handleTierChanged = (e, { value }) => {
    if (value !== 'all') {
      let tierData = [];
      if (Number(value) === 2) {
        tierData = this.props.keyPagesData.filter(item => {
          if (item.Tier !== 1) {
            return item;
          }
        });
      } else {
        tierData = this.props.keyPagesData.filter(item => {
          if (item.Tier === Number(value)) {
            return item;
          }
        });
      }
      if (this.state.cxxData)
        this.setState({ cxxData: tierData.filter(item => item.IsCxx) });
      else this.setState({ data: tierData });
    } else {
      const data = this.props.keyPagesData;
      if (this.state.cxxData)
        this.setState({ cxxData: data.filter(item => item.IsCxx) });
      else this.setState({ data });
    }
  };

  handleMetricChanged = (e, { value }) => {
    this.props.fetchData(this.state.date, value);
    this.props.fetchSummaryData(this.state.date, value)
    this.setState({
      metric: value
    });
    this.setState({
      mode: 'data3g'
    });
  };

  // handlePivotChecked(pivot) {
  //   const oldPivots = this.state.pivots;
  //   const index = oldPivots.indexOf(pivot);

  //   const pivots =
  //     index !== -1
  //       ? [...oldPivots.slice(0, index), ...oldPivots.slice(index + 1)]
  //       : [...oldPivots, pivot];

  //   this.setState({ pivots });
  // }

  render () {
    const allColumns =
      this.state.mode == 'data3g'
        ? this.props.keyPagesData.length <= 0
          ? [...columns]
          : [...this.getColumns()]
        : this.props.keyPagesDataCable.length <= 0
          ? [...columns]
          : [...this.getColumns()];
    // this.state.data.length <= 0 ? [...columns] : [...this.getColumns()];
    let data = !this.state.cxxData
      ? [...this.state.data]
      : [...this.state.cxxData];
    const tierOptions = this.props.loading ? [] : this.getTiers();
    const metricOptions = this.props.loading ? [] : metricVals;
    const anomalyData = this.props.anomalyDataloading
      ? []
      : [...this.props.anomalyData];
    const anomalyData3G = anomalyData.filter(item => item.Mode == '3G');
    const anomalyDataCable = anomalyData.filter(item => item.Mode == 'CABLE');

    const showAnomaly =
      this.getPlatform(this.props.prefix) && anomalyData.length ? true : false;

    return (
      <span>
        <Navigation activeItem={this.props.prefix} items={lighthouseItems} />
        <div style={{ padding: '20px', height: '100%' }}>
          <div className="header-grid">
            <h1>Lighthouse data for Key Pages (Scheduled runs) </h1>
            {this.props.loading ? (
              this.props.loading
            ) : (
              <div
                style={{
                  display: 'inline-flex',
                  'placeSelf': 'center',
                  'justifySelf': 'right',
                  'fontSize': 'initial'
                }}
              >
                <p>Mobile</p>
                <Checkbox toggle onClick={this.toggle} />
                <p>Desktop</p>
              </div>
            )}
          </div>
          {showAnomaly && (
            <div>
              <Header as="h3">Today's Anomaly Data </Header>
              <List
                prefix={this.props.prefix}
                anomalyData={
                  this.state.mode === 'data3g'
                    ? anomalyData3G
                    : anomalyDataCable
                }
              ></List>{' '}
            </div>
          )}
          <LighthouseSummaryTable
            loading={this.props.loading}
            prefix={this.props.prefix}
            mode={this.state.mode}
            metric={this.state.metric}
            nextSummaryClicked={this.nextSummaryClicked.bind(this)}
            prevSummaryClicked={this.prevSummaryClicked.bind(this)}
            summaryData={
              this.state.mode === 'data3g'
                ? this.props.summaryData
                : this.props.summaryDataCable
            }
          />
          <p style={{marginTop: "20px"}}>For Performance, SEO, Best Practices and Accessibility : Green(&gt;= 90), Amber (50 - 89) and Red (&lt; 50).</p>
          <p>For Page Weight (MB) : Green(&lt;= 1.5), Amber (1.5 - 2.5) and Red (&gt; 2.5).</p>
          <p>For JavaScript Execution Time (Sec) : Green(&lt;= 2), Amber (2 - 3.5) and Red (&gt; 3.5). </p>
          <Divider />
          <ReactTable
            loading={this.props.loading}
            data={data}
            columns={allColumns}
            showPagination={false}
            showPageSizeOptions={false}
            pageSize={this.state.data.length}
            minRows={4}
            className="-striped -highlight rum-table"
            filterable
            filtered={this.state.filtered}
            pivotBy={this.state.pivots}
            defaultSorted={[
              { id: 'Brand' },
              { id: 'Locale' },
              { id: 'SuperCategory' }
            ]}
          >
            {(state, makeTable) => {
              return (
                <div>
                  <div className="header-grid">
                    <h3>
                      {state.sortedData.length} sites, Performance Score from
                      Lighthouse across Digital 2.0 sites
                    </h3>
                    <React.Fragment>
                      <span>
                        <Checkbox
                          label="CXX set only"
                          onClick={this.clickGenesisSitesCheckbox}
                        />
                      </span>
                      <span />
                    </React.Fragment>
                    <span>
                      <Button
                        basic
                        disabled={this.props.loading}
                        loading={this.props.loading}
                        icon="download"
                        content="Download CSV"
                        onClick={this.getCsv.bind(this)}
                      />
                    </span>
                  </div>
                  <Filter
                    data={this.getFilters()}
                    value={this.state.filterDisplay}
                    onChange={this.onFilterChange}
                  />
                  <div
                    className="header-cb"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {/* <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Brand')}
                        defaultChecked={this.state.pivots.includes('Brand')}
                      />
                      Brand Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Domain')}
                        defaultChecked={this.state.pivots.includes('Domain')}
                      />
                      Domain Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() => this.handlePivotChecked('Locale')}
                        defaultChecked={this.state.pivots.includes('Locale')}
                      />
                      Market Pivot
                    </label>
                    <label>
                      <input
                        style={{ marginRight: 10 }}
                        type="checkbox"
                        onChange={() =>
                          this.handlePivotChecked('SuperCategory')
                        }
                        defaultChecked={this.state.pivots.includes(
                          'SuperCategory'
                        )}
                      />
                      Category Pivot
                    </label> */}
                    <div>
                      <span>
                        <label>
                          Tier
                          <Dropdown
                            placeholder="All"
                            options={tierOptions}
                            selection
                            compact
                            onChange={this.handleTierChanged}
                          />
                        </label>
                      </span>
                      <span>
                        <label>
                          Metric
                          <Dropdown
                            style={{ marginLeft: 7 }}
                            placeholder={this.state.metric}
                            options={metricOptions}
                            selection
                            compact
                            onChange={this.handleMetricChanged}
                          />
                        </label>
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        {' '}
                        <Button onClick={this.nextItemsClicked}>
                          <Button.Content visible>
                            <Icon name="arrow left" />
                          </Button.Content>
                        </Button>
                      </div>
                      <div>
                        {' '}
                        <Button onClick={this.prevItemsClicked}>
                          <Button.Content visible>
                            <Icon name="arrow right" />
                          </Button.Content>
                        </Button>
                      </div>
                    </div>
                  </div>
                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </span>
    );
  }
}

LighthouseKeyPagesTable.propTypes = {
  fetchSummaryData: PropTypes.func,
  fetchData: PropTypes.func,
  keyPagesData: PropTypes.array,
  summaryData: PropTypes.object,
  keyPagesDataCable: PropTypes.array,
  summaryDataCable: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  prefix: PropTypes.string,
  toggleSpeed: PropTypes.func
};

LighthouseKeyPagesTable.defaultProps = {
  keyPagesData: [],
  summaryData: {}
};

export default LighthouseKeyPagesTable;
