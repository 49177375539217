import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions/ath';
import BrandMetadata from '../templates/BrandMetadata';

class AdobeClassicBrandMetaData extends React.PureComponent {
  render() {
    return <BrandMetadata {...this.props} prefix="ath" />;
  }
}

AdobeClassicBrandMetaData.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func
};

function mapStateToProps(state) {
  return {
    data: state.ath.brandMetaData.data,
    loading: state.ath.brandMetaData.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData() {
      dispatch(Actions.fetchBrandMetaData());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdobeClassicBrandMetaData);
