import ActionNames from './names';

function ActionCreator(actionKey, actionData = {}) {
  return Object.assign({}, actionData, { type: actionKey });
}

export default {
  requestRumData() {
    return ActionCreator(ActionNames.REQUEST_RUM_DATA);
  },
  receiveRumData(data, summaryData) {
    return ActionCreator(ActionNames.RECEIVE_RUM_DATA, {
      data,
      summaryData
    });
  },
  failureRumData(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_DATA, {
      error
    });
  },
  requestRumLeaderboard() {
    return ActionCreator(ActionNames.REQUEST_RUM_LEADERBOARD);
  },
  receiveRumLeaderboard(data, summaryData) {
    return ActionCreator(ActionNames.RECEIVE_RUM_LEADERBOARD, {
      data,
      summaryData
    });
  },
  failureRumLeaderboard(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_LEADERBOARD, {
      error
    });
  },

  requestRumKeyPagesData() {
    return ActionCreator(ActionNames.REQUEST_RUM_KEY_PAGES_DATA);
  },
  receiveRumKeyPagesData(data, summaryData) {
    return ActionCreator(ActionNames.RECEIVE_RUM_KEY_PAGES_DATA, {
      data,
      summaryData
    });
  },
  failureRumKeyPagesData(error) {
    return ActionCreator(ActionNames.FAILURE_RUM_KEY_PAGES_DATA, {
      error
    });
  },

  requestBrandMetaData() {
    return ActionCreator(ActionNames.REQUEST_BRAND_META_DATA);
  },
  receiveBrandMetaData(data) {
    return ActionCreator(ActionNames.RECEIVE_BRAND_META_DATA, {
      data
    });
  },
  failureBrandMetaData(error) {
    return ActionCreator(ActionNames.FAILURE_BRAND_META_DATA, {
      error
    });
  },

  requestMetrics() {
    return ActionCreator(ActionNames.REQUEST_METRICS);
  },
  receiveMetrics(data) {
    return ActionCreator(ActionNames.RECEIVE_METRICS, {
      data
    });
  },
  failureMetrics(error) {
    return ActionCreator(ActionNames.FAILURE_METRICS, {
      error
    });
  }
};
