import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { RED, GREEN, AMBER, TRUEGREEN } from '../../helpers/constants';
import Filter from '../../components/Filter';
const ROWS = ['trueGreen', 'green', 'amber', 'red', 'noData', 'total', 'allAverage', 'acceptable'];
const ROW_TITLES = [
  '<2.5 seconds (True Green)',
  '2.5 - 5 seconds (Green)',
  '5 - 10 seconds (Amber)',
  '> 10 seconds (Red)',
  'no data',
  'Total',
  'Rum Average',
  '% in acceptable threshold'
];
const ROW_COLOURS = [TRUEGREEN, GREEN, AMBER, RED, RED, 'black', 'black', 'black'];

class RumSummaryTable extends React.PureComponent {
  state = {
    display: 'Months'
  };
  getFilters() {
    let filters = [
      {
        value: 'Months',
        text: 'Monthly',
        id: 0
      },
      {
        value: 'Weeks',
        text: 'Weekly',
        id: 1
      }
    ];
    if (this.props.showDaily) {
      filters.push({
        value: 'Days',
        text: 'Daily',
        id: 2
      });
    }
    return filters;
  }
  getColumns(data) {
    const columns = [
      {
        Header: 'Threshold',
        accessor: 'title',
        getProps: (state, rowInfo) => {
          let colour = 'black';
          if (rowInfo) {
            colour = ROW_COLOURS[rowInfo.index];
          }
          return {
            style: {
              color: colour,
              fontWeight: 'bolder'
            }
          };
        }
      }
    ];
    data.forEach(item => {
      const column = {};
      column.id = item.date;
      column.Header = item.date;
      column.accessor = item.date;
      column.minWidth = 50;
      columns.push(column);
    });
    return columns;
  }

  getDynamicData() {
    if (this.props.loading) {
      return [];
    }
    const data = this.props.summaryData[this.state.display];
    return [...data].reverse();
  }

  getTableData(data) {
    const tableData = data.map(i => {
      const dataRow = { ...i };
      dataRow.id = i.date;
      dataRow.totalCount = i.total;
      dataRow.noDataCount = i.noData;
      dataRow.allAverageCount = i.allAverage
      dataRow.acceptableCount =
        _.round(((i.trueGreenCount + i.greenCount) / i.total) * 100, 2) + '%';
      return dataRow;
    });
    const rowData = ROWS.map((item, index) => {
      const row = {};
      row.title = ROW_TITLES[index];

      tableData.forEach(week => {
        row[week.id] = week[`${item}Count`];
      });

      return row;
    });
    return rowData;
  }

  onFilterChange = display => {
    this.setState({ display });
  };

  convertToCSV(data) {
    const thresholds = ['trueGreenCount','greenCount','amberCount','redCount','noData','total', 'allAverage'];
    let weeks = data.Weeks.asMutable().slice().reverse();
    let months = data.Months.asMutable().slice().reverse();
    let daily = data.Days.asMutable().slice().reverse();

    const dailyHeaders = daily.map(daily => daily.id).join(',');
    const weekHeaders = weeks.map(week => week.date).join(',');
    const monthHeaders = months.map(month => month.date).join(',');
    
    const csvRows = [
      `Threshold,${dailyHeaders},${weekHeaders},${monthHeaders},`
    ];

    thresholds.forEach(threshold => {
      const monthlyValues = months.map(month => month[threshold]).join(',');
      const weeklyValues = weeks.map(week => week[threshold]).join(',');
      const dailyValues = daily.map(daily => daily[threshold]).join(',');
      csvRows.push(`${threshold},${dailyValues},${weeklyValues},${monthlyValues}`);
    });

    return csvRows.join('\n');
  }

  downloadCSV() {
    let data = this.props.summaryData;
    let currentPlatform = this.props.platform;
    if(currentPlatform == undefined){
      currentPlatform = "ALL-Platform";
    }
    let filename = `${currentPlatform}-Rum-Summary.csv`;
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  render() {
    const data = this.getDynamicData();
    const columns = this.getColumns(data);
    const tableData = this.getTableData(data);
    const filters = this.getFilters();
    const allFilters = [...filters, ...this.props.keyMarkets];
    return (
      <div>
        <div className="header-grid" >
          <div className="rum-summary-table">
            <h3>{this.state.radioLabel} Summary</h3>
            <Filter
              data={allFilters}
              value={this.state.display}
              onChange={this.onFilterChange}
            />
          </div>
          <span>
            <Button
              basic
              disabled={this.props.loading}
              loading={this.props.loading}
              icon="download"
              content="Download CSV"
              onClick={this.downloadCSV.bind(this)}
            />
          </span>
        </div>
        <ReactTable
          data={tableData}
          columns={columns}
          showPagination={false}
          sortable={false}
          pageSize={8}
          loading={this.props.loading}
        />
      </div>
    );
  }
}

RumSummaryTable.propTypes = {
  summaryData: PropTypes.object,
  keyMarkets: PropTypes.array,
  loading: PropTypes.bool,
  showDaily: PropTypes.bool,
  platform: PropTypes.string
};

RumSummaryTable.defaultProps = {
  summaryData: {
    Weeks: [],
    Months: []
  },
  keyMarkets: []
};

export default RumSummaryTable;
