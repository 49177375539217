import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import OnDemandForm from './OnDemandForm';
import PropTypes from 'prop-types'
import { Actions } from '../../store/actions';
import OnDemandReportTable from './OnDemandReportTable'

class OnDemandFormContainer extends PureComponent {
  constructor () {
    super()
    this.state = {
      action: null
    }
  }

  componentDidMount () {
    this.props.getRecentReports()
  }

  handleAction = (action) => {
    this.setState({
      action
    })
  }

  render () {

    return (
      <div>
        <OnDemandForm loadingEmailReport={this.props.loadingEmailReport} loadingReport={this.props.loadingReport} loadingExec={this.props.loadingExec} prefix="on-demand" execute={this.props.executeJob} fetchData={this.props.fetchData} changeAction={this.handleAction} fetchReportForEmail={this.props.fetchReportForEmail} />
        <OnDemandReportTable executionResponse={this.props.executionResponse} onDemandReport={this.props.onDemandReport} action={this.state.action} recentReportData={this.props.recentReportData} fetchData={this.props.fetchData} emailReport={this.props.emailReport} changeAction={this.handleAction} />
      </div>
    );
  }
}

OnDemandFormContainer.propTypes = {
  executeJob: PropTypes.func,
  fetchData: PropTypes.func,
  fetchReportForEmail: PropTypes.func,
  loadingReport: PropTypes.bool,
  loadingExec: PropTypes.bool,
  executionResponse: PropTypes.object,
  onDemandReport: PropTypes.array,
  getRecentReports: PropTypes.func,
  recentReportData: PropTypes.array,
  emailReport: PropTypes.array,
  loadingEmailReport: PropTypes.bool,
}

function mapStateToProps (state) {
  return {
    onDemandReport: state.lighthouseForPlatform.onDemandReport.onDemandReportData,
    loadingReport: state.lighthouseForPlatform.onDemandReport.loading,
    executionResponse: state.lighthouseForPlatform.onDemand.onDemandExecuteData,
    loadingExec: state.lighthouseForPlatform.onDemand.loading,
    recentReportData: state.lighthouseForPlatform.OnDemandRecentReport.onDemandRecentReportData,
    emailReport: state.lighthouseForPlatform.OnDemandRecentReport.onDemandEmailReportData,
    loadingEmailReport: state.lighthouseForPlatform.OnDemandRecentReport.emailReportLoading,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    fetchData (userId) {
      return dispatch(
        Actions.fetchOnDemandLighthouseReport(userId)
      );
    },
    executeJob (body) {
      return dispatch(
        Actions.executeOnDemandLighthouseReport(body)
      )
    },
    getRecentReports () {
      return dispatch(
        Actions.fetchOnDemandRecentReport()
      )
    },
    fetchReportForEmail (email) {
      return dispatch(
        Actions.fetchOnDemandEmailReport(email)
      )
    }
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandFormContainer);