import moment from 'moment';

function getNextSummaryMonth(date){
  return moment(date).add(8, "months").endOf('month').format("YYYY-MM-DD");
}

function getPrevSummaryMonth(date){
  return moment(date).subtract(8, "months").endOf('month').format("YYYY-MM-DD");
}

function getNextSummaryWeek(date){
  return moment(date).add(55, "days").endOf('week').add(1,"day").format("YYYY-MM-DD");
}

function getPrevSummaryWeek(date){
  return  moment(date).subtract(57, "days").endOf('week').add(1,"day").format("YYYY-MM-DD");
}

function getNextSummaryDay(date){
  return moment(date).add(8, "days").format("YYYY-MM-DD")
}

function getPrevSummaryDay(date){
  return moment(date).subtract(8, "days").format("YYYY-MM-DD");
}

function getNextItemsMonth(date){
  return moment(date).add(4, "months").endOf('month').format("YYYY-MM-DD");
}

function getPrevItemsMonth(date){
  return moment(date).subtract(4, "months").endOf('month').format("YYYY-MM-DD");
}

function getNextItemsWeek(date){
  return moment(date).add(27, "days").endOf('week').add(1,"day").format("YYYY-MM-DD");
}

function getPrevItemsWeek(date){
  return  moment(date).subtract(29, "days").endOf('week').add(1,"day").format("YYYY-MM-DD");
}

function getNextItemsDay(date){
  return moment(date).add(8, "days").format("YYYY-MM-DD");
}
function getPrevItemsDay(date){
  return moment(date).subtract(8, "days").format("YYYY-MM-DD");
}
export { getNextSummaryMonth, getPrevSummaryMonth, getNextItemsMonth, getPrevItemsMonth, getNextSummaryWeek, getPrevSummaryWeek, getNextItemsWeek, getPrevItemsWeek, getNextSummaryDay, getPrevSummaryDay, getNextItemsDay, getPrevItemsDay };