const names = {
  REQUEST_RUM_DATA: 'REQUEST_RUM_DATA_ATH',
  RECEIVE_RUM_DATA: 'RECEIVE_RUM_DATA_ATH',
  FAILURE_RUM_DATA: 'FAILURE_RUM_DATA_ATH',

  REQUEST_RUM_LEADERBOARD: 'REQUEST_RUM_LEADERBOARD_ATH',
  RECEIVE_RUM_LEADERBOARD: 'RECEIVE_RUM_LEADERBOARD_ATH',
  FAILURE_RUM_LEADERBOARD: 'FAILURE_RUM_LEADERBOARD_ATH',

  REQUEST_RUM_KEY_PAGES_DATA: 'REQUEST_RUM_KEY_PAGES_DATA_ATH',
  RECEIVE_RUM_KEY_PAGES_DATA: 'RECEIVE_RUM_KEY_PAGES_DATA_ATH',
  FAILURE_RUM_KEY_PAGES_DATA: 'FAILURE_RUM_KEY_PAGES_DATA_ATH',

  REQUEST_BRAND_META_DATA: 'REQUEST_BRAND_META_DATA_ATH',
  RECEIVE_BRAND_META_DATA: 'RECEIVE_BRAND_META_DATA_ATH',
  FAILURE_BRAND_META_DATA: 'FAILURE_BRAND_META_DATA_ATH',

  REQUEST_METRICS: 'REQUEST_METRICS_ATH',
  RECEIVE_METRICS: 'RECEIVE_METRICS_ATH',
  FAILURE_METRICS: 'FAILURE_METRICS_ATH'
};

export default names;
