import ActionNames from '../../actions/anomalyDetection/names';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loading: true,
  anomalyData: [],
  error: null
});

function anomalyDailyReportReducer(state = initialState, action) {
  switch (action.type) {
    case ActionNames.REQUEST_DAILY_ANOMALY_REPORT:
      return state.merge({ loading: true, anomalyData: [] });
    case ActionNames.RECEIVE_DAILY_ANOMALY_REPORT:
      return state.merge({ loading: false, anomalyData: action.data });
    case ActionNames.FAILURE_DAILY_ANOMALY_REPORT:
      return state.merge({
        loading: false,
        error: action.error,
        anomalyData: []
      });
    default:
      return state;
  }
}

export default anomalyDailyReportReducer;
