import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, MenuHeader } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

const headers = {
  'wp-lite': 'Wordpress Lite',
  'adobe-classic': 'Adobe Classic',
  'adobe-saas': 'Adobe Saas',
  'ath': 'ATH',
  'liberty': 'Liberty',
  'adobe-templated': 'Adobe Templated',
  competitor: 'Competitor Sites',
  'self-service': 'Self Service'
};

function Header({ prefix, appData }) {
  const prefixHeader = headers[prefix];
  const prefixForNav = prefix === '' ? 'adobe-saas' : prefix;
  const { isLoggedIn } = appData

  return (
    <Menu id="menu" icon="labeled" borderless fluid>
      <MenuHeader>
        <span>
          <Link to={isLoggedIn ? "/" : "/login"}>
            <Icon name="home" inverted color="grey" />
          </Link>
        </span>
        <span> Site Speed {prefixHeader} </span>
      </MenuHeader>
      {(prefixForNav !== '' && isLoggedIn) && (
        <Menu.Menu className="options">
          <Menu.Item name="table" as={NavLink} exact to={`/${prefixForNav}`}>
            <Icon name="pie graph" inverted color="grey" />
            Site Speed (Rum)
          </Menu.Item>
          {/* <Menu.Item
            name="synthetic"
            as={NavLink}
            exact
            to={`/${prefixForNav}/synthetic`}
          >
            <Icon name="pie graph" inverted color="grey" />
            Site Speed (Synthetic)
          </Menu.Item> */}
          <Menu.Item
            name="Lighthouse"
            as={NavLink}
            exact
            to={`/${prefixForNav}/lighthouse`}
          >
            <Icon name="pie graph" inverted color="grey" />
            Site Speed (Lighthouse)
          </Menu.Item>
          <Menu.Item
            name="example"
            as={NavLink}
            exact
            to={`/${prefixForNav}/data`}
          >
            <Icon name="database" inverted color="grey" />
            Sites
          </Menu.Item>
          <Menu.Item
            name="logout"
            as={NavLink}
            to={`/login`}
          >
            <Icon name="log out" inverted color="grey" />
            Logout
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
}

Header.propTypes = {
  prefix: PropTypes.string,
  appData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    appData: state.appData
  }
}
function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
