import React from 'react';
import LighthouseKeyPagesTableTemplate from '../templates/LighthouseKeyPagesTemplate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Actions } from '../../store/actions';
import { AnomalyActions } from '../../store/actions/anomalyDetection';

class LighthouseKeyPagesTable extends React.PureComponent {
  render() {
    const {
      loading,
      keyPagesData,
      summaryData,
      anomalyDataloading
    } = this.props;
    let allItemsData = !keyPagesData ? [] : keyPagesData;
    let allSummaryData = !summaryData ? [] : summaryData;
    allItemsData = allItemsData.asMutable();
    let data = allItemsData[0] ? allItemsData[0].items : [];
    let dataCable = allItemsData[1] ? allItemsData[1].items : [];
    let summary = allSummaryData[0] ? allSummaryData[0].summary : {};
    let summaryCable = allSummaryData[1] ? allSummaryData[1].summary : {};
    let anomalyData = [];
    if (this.props.anomalyData) {
      anomalyData = this.props.anomalyData;
    }
    data = data.filter(i => {
      return !i.Brand.includes('Cleanipedia');
    });
    dataCable = dataCable.filter(i => {
      return !i.Brand.includes('Cleanipedia');
    });
    return (
      <LighthouseKeyPagesTableTemplate
        {...this.props}
        keyPagesData={data}
        summaryData={summary}
        keyPagesDataCable={dataCable}
        summaryDataCable={summaryCable}
        loading={loading}
        fetchData={this.props.fetchData}
        fetchSummaryData={this.props.fetchSummaryData}
        prefix="saas"
        anomalyDataloading={anomalyDataloading}
        anomalyData={anomalyData}
        fetchDailyAnomalyReport={this.props.fetchDailyAnomalyReport}
      />
    );
  }
}

LighthouseKeyPagesTable.propTypes = {
  fetchSummaryData: PropTypes.func,
  fetchData: PropTypes.func,
  keyPagesData: PropTypes.array,
  location: PropTypes.object,
  loading: PropTypes.bool,
  summaryData: PropTypes.array,
  anomalyDataloading: PropTypes.bool,
  anomalyData: PropTypes.array,
  fetchDailyAnomalyReport: PropTypes.func
};

function mapStateToProps(state) {
  return {
    keyPagesData: state.lighthouseForPlatform.keyPagesData.saasData,
    summaryData: state.lighthouseForPlatform.summaryData.saasSummaryData,
    loading: state.lighthouseForPlatform.keyPagesData.loading,
    anomalyDataloading: state.anomalyDetection.anomalyDailyReport.loading,
    anomalyData: state.anomalyDetection.anomalyDailyReport.anomalyData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData(date, metric) {
      return dispatch(
        Actions.fetchLighthouseKeyPagesDataForPlatform('saas', date, metric)
      );
    },
    fetchSummaryData(date, metric) {
      return dispatch(
        Actions.fetchLighthouseSummaryDataForPlatform('saas', date, metric)
      );
    },
    fetchDailyAnomalyReport(service, platform, date) {
      return dispatch(
        AnomalyActions.fetchDailyAnomalyReport(service, platform, date)
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LighthouseKeyPagesTable);
