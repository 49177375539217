import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

function SpanningWarningComponent({message}) {
  return (
    <div className="spanning-component dual">
      <h1> 
        {message}
      </h1>
      <Link to={'/'}>
        <Button>
          Go Home
        </Button>
      </Link>
    </div>
  );
}

SpanningWarningComponent.propTypes = {
  message: PropTypes.string,
  history: PropTypes.object,
}

export default SpanningWarningComponent;