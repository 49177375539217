import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './styles/css/index.css';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import App from './components/App';
import { unregister } from './registerServiceWorker';
import configureStore from './store/index';
import 'react-dates/initialize';
import { GoogleOAuthProvider } from '@react-oauth/google';
const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

const store = configureStore();

ReactDOM.render(
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
unregister();
