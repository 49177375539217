import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import CoreActions from '../store/actions/actions';
import Navigation from '../components/Navigation';
import { rumTableNavItems } from '../helpers/platformNavItems';
import RumHomeTable from '../components/RumHomeTable';
// import SynthHomeTable from '../components/SynthHomeTable';

const platforms = [
  // {
  //   name: 'Adobe Classic',
  //   prop: 'adobeClassic',
  //   synthName: 'classic',
  //   rumTable: '/adobe-classic/rum-table',
  //   synthTable: '/adobe-classic/synthetic',
  //   sites: '/adobe-classic/data'
  // },
  // {
  //   name: 'Adobe Templated',
  //   prop: 'adobeTemplated',
  //   synthName: 'templated',
  //   rumTable: '/adobe-templated/rum-table',
  //   synthTable: '/adobe-templated/synthetic',
  //   sites: '/adobe-templated/data'
  // },
  {
    name: 'Wordpress Lite',
    prop: 'wpLite',
    synthName: 'lite',
    rumTable: '/wp-lite/rum-table',
    synthTable: '/wp-lite/synthetic',
    sites: '/wp-lite/data'
  },
];

function getRumDataFromProps (props) {
  const data = [];
  const { total } = props;
  const newPlatforms = [
    {
      name: 'Adobe Saas',
      prop: 'adobeSaas',
      synthName: 'saas',
      rumTable: '/adobe-saas/rum-table',
      synthTable: '/adobe-saas/synthetic',
      sites: '/adobe-saas/data'
    }, {
      name: 'ATH',
      prop: 'ath',
      synthName: 'ath',
      rumTable: '/ath/rum-table',
      synthTable: '/ath/synthetic',
      sites: '/ath/data'
    },
    {
      name: 'Liberty',
      prop: 'liberty',
      synthName: 'liberty',
      rumTable: '/liberty/rum-table',
      synthTable: '/liberty/synthetic',
      sites: '/liberty/data'
    }
  ];
  const rumPlatforms = [...platforms, ...newPlatforms];

  for (let platform of rumPlatforms) {
    const platforMetrics = Object.assign({}, props[platform.prop]);
    const {
      sitesCount,
      greenCount,
      trueGreenCount,
      amberCount,
      redCount,
      acceptable
    } = platforMetrics;
    data.push({
      platform: platform.name,
      sitesCount,
      acceptable,
      greenCount,
      trueGreenCount,
      amberCount,
      redCount,
      link: platform.rumTable,
      sites: platform.sites
    });
  }
  data.push({
    platform: 'All Platforms',
    sitesCount: total.sitesCount,
    acceptable: total.acceptable,
    trueGreenCount: total.trueGreenCount,
    greenCount: total.greenCount,
    amberCount: total.amberCount,
    redCount: total.redCount,
    link: null
  });
  return data;
}

function getSynthDataFromProps (props) {
  const synthData = [];
  const competitor = [
    {
      name: 'Competitors',
      synthName: 'competitor',
      synthTable: '/competitor/synthetic',
      sites: '/competitor/data'
    }
  ];
  const synthPlatforms = [...platforms, ...competitor];
  for (let platform of synthPlatforms) {
    const {
      green,
      amber,
      red,
      total,
      noData,
      acceptable,
      header
    } = props.synthAll[platform.synthName];

    synthData.push({
      platform: platform.name,
      date: header,
      green,
      amber,
      red,
      total,
      noData,
      acceptable,
      link: platform.synthTable,
      sites: platform.sites
    });
  }
  return synthData;
}

class Home extends React.PureComponent {
  constructor () {
    super();
    this.state = { rumData: [], synthAllLoading: true };
  }
  componentDidMount () {
    this.props.fetchRUMMetrics();
    // this.props.fetchSynthMetrics();
  }

  static getDerivedStateFromProps (props) {
    let rumData = [];
    let synthData = [];
    let synthDate;
    if (props.total && props.total.sitesCount) {
      rumData = getRumDataFromProps(props);
    }
    if (props.synthAll && Object.keys(props.synthAll).length > 0) {
      synthData = getSynthDataFromProps(props);
      synthDate = synthData[0].date;
    }
    return { rumData, synthData, synthDate };
  }
  render () {
    //const { loading, synthAllLoading } = this.props;
    const { loading } = this.props;

    return (
      <span>
        <Navigation activeItem={this.state.platform} items={rumTableNavItems} />
        <div style={{ padding: '20px', height: '100%' }}>
          <RumHomeTable data={this.state.rumData} loading={loading} />
          <Divider />
          {/* <SynthHomeTable
            data={this.state.synthData}
            loading={synthAllLoading}
            date={this.state.synthDate}
          /> */}
          <p>&nbsp;</p>
          <p>
            The pages chosen for each site are generally the home page, a product
            landing page, and a product detail page.
          </p>
          <p>
            {' '}
            The list of pages needs to be updated for each site, please contact the
            Data Engineering squad to get them updated.
          </p>
        </div>
      </span>
    );
  }
}

Home.propTypes = {
  fetchRUMMetrics: PropTypes.func,
  fetchSynthMetrics: PropTypes.func,
  loading: PropTypes.bool,
  synthAll: PropTypes.object,
  synthAllLoading: PropTypes.bool,
  adobeTemplated: PropTypes.object,
  adobeSaas: PropTypes.object,
  wpLite: PropTypes.object,
  ath: PropTypes.object,
  liberty: PropTypes.object,
  total: PropTypes.object,
  adobeClassic: PropTypes.object,
};

function mapStateToProps (state) {
  return {
    adobeClassic: state.allMetrics.data.adobeClassic,
    adobeSaas: state.allMetrics.data.adobeSaas,
    adobeTemplated: state.allMetrics.data.adobeTemplated,
    wpLite: state.allMetrics.data.wpLite,
    ath: state.allMetrics.data.ath,
    liberty: state.allMetrics.data.liberty,
    total: state.allMetrics.data.all,
    loading: state.allMetrics.loading,
    synthAll: state.syntheticForPlatform.keyPagesMonthData.data,
    synthAllLoading: state.syntheticForPlatform.keyPagesMonthData.loading,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    fetchRUMMetrics () {
      dispatch(CoreActions.fetchAllMetrics());
    },
    // fetchSynthMetrics () {
    //   dispatch(CoreActions.fetchSyntheticMonthSummary());
    // }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
