const names = {
  REQUEST_RUM_DATA: 'REQUEST_RUM_DATA_LIBERTY',
  RECEIVE_RUM_DATA: 'RECEIVE_RUM_DATA_LIBERTY',
  FAILURE_RUM_DATA: 'FAILURE_RUM_DATA_LIBERTY',

  REQUEST_RUM_LEADERBOARD: 'REQUEST_RUM_LEADERBOARD_LIBERTY',
  RECEIVE_RUM_LEADERBOARD: 'RECEIVE_RUM_LEADERBOARD_LIBERTY',
  FAILURE_RUM_LEADERBOARD: 'FAILURE_RUM_LEADERBOARD_LIBERTY',

  REQUEST_RUM_KEY_PAGES_DATA: 'REQUEST_RUM_KEY_PAGES_DATA_LIBERTY',
  RECEIVE_RUM_KEY_PAGES_DATA: 'RECEIVE_RUM_KEY_PAGES_DATA_LIBERTY',
  FAILURE_RUM_KEY_PAGES_DATA: 'FAILURE_RUM_KEY_PAGES_DATA_LIBERTY',

  REQUEST_BRAND_META_DATA: 'REQUEST_BRAND_META_DATA_LIBERTY',
  RECEIVE_BRAND_META_DATA: 'RECEIVE_BRAND_META_DATA_LIBERTY',
  FAILURE_BRAND_META_DATA: 'FAILURE_BRAND_META_DATA_LIBERTY',

  REQUEST_METRICS: 'REQUEST_METRICS_LIBERTY',
  RECEIVE_METRICS: 'RECEIVE_METRICS_LIBERTY',
  FAILURE_METRICS: 'FAILURE_METRICS_LIBERTY'
};

export default names;
